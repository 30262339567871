import React from 'react';
import { motion } from 'framer-motion';

const SVGPlay = ({ props }) => (
  <motion.svg viewBox="0 0 106 102" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="53.1533" cy="51.0301" rx="52.8467" ry="50.9699" fill="#78BE43" />
    <path
      d="M73.4431 47.9998C75.3538 49.1701 75.3538 51.9459 73.4431 53.1163L45.4876 70.2399C43.4886 71.4644 40.9206 70.0259 40.9206 67.6817L40.9206 33.4344C40.9206 31.0902 43.4886 29.6517 45.4876 30.8761L73.4431 47.9998Z"
      fill="white"
    />
  </motion.svg>
);

export default SVGPlay;
