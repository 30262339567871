import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { LoadingSpinner } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';

const ProtectedRoute = ({ children, eid, eventIsHidden, eventStatus, isAdmin }) => {
  const { firebase, loading, user } = useContext(FirebaseContext);
  const { setShowLoginOrSignUpDialog } = useContext(LocalContext);
  const firebaseHasLoaded = !loading && firebase;
  const userIsInFirebaseAuth = firebase?.auth?.currentUser;

  // We allow an event livestream page to be accessed when 'eventIsHidden' so that MentorsWork can
  // preview/approve the livestream page of a hidden event.
  const openGatedContent =
    eventIsHidden ||
    eventStatus === 'past' ||
    (eid && user && user.eventsUserCanAccess?.includes(eid)) ||
    isAdmin;

  useEffect(() => {
    if (
      !openGatedContent &&
      eventStatus === 'future' &&
      firebaseHasLoaded &&
      !userIsInFirebaseAuth
    ) {
      setShowLoginOrSignUpDialog(true);
    }
  }, [openGatedContent, eventStatus, firebaseHasLoaded, userIsInFirebaseAuth]);
  return (
    <>
      <AnimatePresence>
        {loading && (
          <LoadingSpinnerWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <LoadingSpinner style={{ width: '8rem' }} />
          </LoadingSpinnerWrapper>
        )}
      </AnimatePresence>
      {(openGatedContent || (firebaseHasLoaded && userIsInFirebaseAuth && user)) &&
        (openGatedContent ? children : navigate('/'))}
    </>
  );
};

const LoadingSpinnerWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100vw;
`;

export default ProtectedRoute;
