import * as React from 'react';

function SvgBgMoon1(props) {
  return (
    <svg viewBox="0 0 300 301" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="149.889" cy="150.706" r="148.889" stroke="#002A3A" strokeWidth="2" />
    </svg>
  );
}

export default SvgBgMoon1;
