import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Participant } from 'components';
import { FirebaseContext } from 'context';
import { motion } from 'framer-motion';

const onlyShowTheseRolesAndInThisOrder = ['isOrganiser', 'isModerator', 'isSpeaker'];

const Participants = ({
  colors,
  eid,
  handleParticipantAvatarOrNameClick,
  showOnlinePresenceToUsers
}) => {
  const { firebase, user } = useContext(FirebaseContext);
  const particpantsContainerRef = useRef();
  const [usersCurrentlyWatchingEvent, setUsersCurrentlyWatchingEvent] = useState(0);
  const [lastFetchedCurrentlyParticipatingUserDoc, setLastFetchedCurrentlyParticipatingUserDoc] =
    useState();
  const [
    lastFetchedNotCurrentlyParticipatingUserDoc,
    setLastFetchedNotCurrentlyParticipatingUserDoc
  ] = useState();
  const [participants, setParticpants] = useState([]);

  useEffect(() => {
    if (firebase) {
      firebase.interaction.participants.getEventParticipantsOnlineCount({
        eid,
        callback: (snapshot) => {
          if (!snapshot.empty) {
            const { count } = snapshot.data();
            setUsersCurrentlyWatchingEvent(count);
          }
        }
      });
    }
  }, [firebase]);

  const populatePaginatedParticipantsList = async (snapshots) => {
    const nextParticipants = [];

    const [currentlyParticipatingUsersSnapshot, notCurrentlyParticipatingUsersSnapshot] = snapshots;

    const userHasNotBeenAddedToTheParticipantsListAlready = (uid) =>
      !participants.some((participant) => participant.uid === uid);

    if (!currentlyParticipatingUsersSnapshot?.empty) {
      currentlyParticipatingUsersSnapshot.docs.forEach((doc) => {
        const { uid } = doc.data();
        if (userHasNotBeenAddedToTheParticipantsListAlready(uid)) {
          nextParticipants.push(doc);
        }
      });
    }

    if (
      currentlyParticipatingUsersSnapshot?.empty &&
      !notCurrentlyParticipatingUsersSnapshot?.empty
    ) {
      notCurrentlyParticipatingUsersSnapshot.docs.forEach((doc) => {
        const { uid } = doc.data();
        if (userHasNotBeenAddedToTheParticipantsListAlready(uid)) {
          nextParticipants.push(doc);
        }
      });
    }

    if (nextParticipants.length) {
      setParticpants((currrentParticipants) => [
        ...currrentParticipants,
        ...nextParticipants.map((doc) => {
          const { name, profession, company, socials, avatarUrl, roles, uid, presence } =
            doc.data();
          return {
            name,
            profession,
            company,
            socials,
            avatarUrl,
            roles,
            uid,
            presence
          };
        })
      ]);

      if (!currentlyParticipatingUsersSnapshot?.empty) {
        setLastFetchedCurrentlyParticipatingUserDoc(
          currentlyParticipatingUsersSnapshot.docs[
            currentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }

      if (
        currentlyParticipatingUsersSnapshot?.empty &&
        !notCurrentlyParticipatingUsersSnapshot?.empty
      ) {
        setLastFetchedNotCurrentlyParticipatingUserDoc(
          notCurrentlyParticipatingUsersSnapshot.docs[
            notCurrentlyParticipatingUsersSnapshot.docs.length - 1
          ]
        );
      }
    }
  };

  // This fetches normal participants, i.e. attendees without 'Organiser', 'Moderator', or 'Speaker'
  // roles. There could be hundreds of them, so we paginate the fetch requests to keep database
  // queries to a minimum.
  const fetchPaginatedParticipants = () =>
    firebase.interaction.participants
      .fetchPaginatedParticipants({
        eid,
        lastFetchedCurrentlyParticipatingUserDoc,
        lastFetchedNotCurrentlyParticipatingUserDoc
      })
      .then(populatePaginatedParticipantsList);

  const populateParticipantsWithRolesList = (snapshots) => {
    const participantsWithRoles = [];

    snapshots.forEach((snapshot) => {
      if (!snapshot?.empty) {
        snapshot.docs.forEach((doc) => {
          const { name, profession, company, socials, avatarUrl, roles, uid, presence } =
            doc.data();

          const _participant = {
            name,
            profession,
            company,
            socials,
            avatarUrl,
            roles,
            uid,
            presence
          };

          let rolesString;

          const unsortedRoles = Object.entries(_participant.roles).reduce((acc, entry) => {
            const [eventRole, eventIds] = entry;
            // We're only interested in the 'isOrganiser', 'isModerator', and 'isSpeaker' entries.
            if (onlyShowTheseRolesAndInThisOrder.includes(eventRole) && eventIds.includes(eid)) {
              acc.push(eventRole);
            }
            return acc;
          }, []);

          if (unsortedRoles.length) {
            // If the participant has multiple roles, they'll be sorted in order of importance,
            // as specified in the onlyShowTheseRolesAndInThisOrder array.
            const sortedAndFormattedRoles = unsortedRoles
              .sort(
                (a, b) =>
                  onlyShowTheseRolesAndInThisOrder.indexOf(a) -
                  onlyShowTheseRolesAndInThisOrder.indexOf(b)
              )
              .map((eventRole) => eventRole.slice(2)); // Removes the 'is' part of the role.

            rolesString = sortedAndFormattedRoles.join(', ');
          }

          if (
            rolesString &&
            !participantsWithRoles.some((participant) => participant.uid === uid)
          ) {
            participantsWithRoles.push({
              ..._participant,
              rolesString
            });
          }
        });
      }
    });

    // If there are any participantsWithRoles then as soon as the last one appears in view
    // the useIntersection will call fetchPaginatedParticipants(). If there are no
    // participantsWithRoles then we'll just immediately call fetchPaginatedParticipants().
    if (participantsWithRoles.length) {
      const participantsWithRolesSortedByOnlinePresence = participantsWithRoles.sort(
        (a, b) => (b.presence.selectedEventId === eid) - (a.presence.selectedEventId === eid)
      );
      setParticpants(participantsWithRolesSortedByOnlinePresence);
    } else {
      fetchPaginatedParticipants();
    }
  };

  useEffect(() => {
    firebase.interaction.participants
      .fetchParticipantsWithRoles(eid)
      .then(populateParticipantsWithRolesList);
    return () => {
      setParticpants([]);
      setLastFetchedCurrentlyParticipatingUserDoc(null);
      setLastFetchedNotCurrentlyParticipatingUserDoc(null);
    };
  }, []);

  const duplicateUsers = participants
    .map(({ uid }) => uid)
    .filter((item, index) => index !== participants.map(({ uid }) => uid).indexOf(item));

  if (duplicateUsers.length) {
    console.warn('Duplicate users: ', duplicateUsers);
  }

  return (
    <Wrapper ref={particpantsContainerRef} colors={colors} tabIndex={0}>
      {user?.isAdmin && (
        <Info colors={colors}>
          <p>Online Participants: {usersCurrentlyWatchingEvent}</p>
        </Info>
      )}
      {participants.length > 0 &&
        participants.map((participant, i, arr) => (
          <Participant
            key={participant.uid}
            eid={eid}
            participant={participant}
            fetchPaginatedParticipants={fetchPaginatedParticipants}
            handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
            isLastFetchedParticipant={arr.length === i + 1}
            showOnlinePresenceToUsers={user?.isAdmin || showOnlinePresenceToUsers}
            colors={colors}
            particpantsContainerRef={particpantsContainerRef}
          />
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 1.25rem;
  min-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Info = styled.div`
  padding-bottom: 0.5rem;
  p {
    color: ${({ colors }) => colors.secondary};
    font-size: 0.75rem;
    text-align: right;
    text-transform: uppercase;
  }
`;

export default Participants;
