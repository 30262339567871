const firebaseConfig = {
  apiKey: 'AIzaSyBT2mAiVTptlsYfbs7D_t-sjS-liIB4VW8',
  authDomain: 'mentorswork-live.firebaseapp.com',
  databaseURL: 'https://mentorswork-live-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'mentorswork-live',
  storageBucket: 'mentorswork-live.appspot.com',
  messagingSenderId: '1090840897995',
  appId: '1:1090840897995:web:a5397700090e18afd42e4e',
  measurementId: 'G-NSLW1EH2CL'
};

export default firebaseConfig;
