import { doc, updateDoc } from 'firebase/firestore';

function InteractionFactory(firebase) {
  const forceActiveTab = async ({ eid, tabName }) =>
    updateDoc(doc(firebase.fsdb, 'events', eid), {
      forcedActiveTab: tabName
    });

  return {
    forceActiveTab
  };
}

export default InteractionFactory;
