import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';

function LoginOrSignUp({ colors, isDialog, hideCloseIcon }) {
  const { siteName, setMagicLinkEmailStatus, showSignUpForm, selectedEvent, setShowSignUpForm } =
    useContext(LocalContext);

  const resetForm = () => ({
    name: '',
    email: '',
    // confirmEmail: '',
    company: '',
    companyAddress: '',
    numberOfEmployees: '',
    phoneNumber: '',
    businessType: '',
    termsAndConditions: false,
    eid: selectedEvent ? selectedEvent.eid : '',
    referringUrl:
      typeof window !== 'undefined' &&
      JSON.parse(window.localStorage.getItem(`referringUrl${siteName}`))?.data
  });

  const [formValues, setFormValues] = useState(resetForm());
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(
    () => () => {
      setMagicLinkEmailStatus({
        sending: false,
        sent: false
      });
      setShowSignUpForm(false);
    },
    []
  );

  const handleInputChange = (e) => {
    e.persist();

    if (errorMessage) {
      setErrorMessage('');
    }

    const { name, type, checked, value } = e.target;

    setFormValues((currentState) => ({
      ...currentState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  useEffect(() => {
    setFormValues((currentState) => ({
      ...currentState,
      action: showSignUpForm ? 'registration' : 'login'
    }));
  }, [showSignUpForm]);

  return (
    <Container colors={colors}>
      <SignUpForm
        formValues={formValues}
        setFormValues={setFormValues}
        errorMessage={errorMessage}
        hideCloseIcon={hideCloseIcon}
        setErrorMessage={setErrorMessage}
        handleInputChange={handleInputChange}
        resetForm={resetForm}
        colors={colors}
        isDialog={isDialog}
      />
      <LoginForm
        formValues={formValues}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleInputChange={handleInputChange}
        hideCloseIcon={hideCloseIcon}
        colors={colors}
        isDialog={isDialog}
      />
    </Container>
  );
}

const Container = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  perspective: 1200px;
  transform-style: preserve-3d;
  width: 100%;
`;

export default LoginOrSignUp;
