import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  PollsAnswers as Answers,
  GeneralPollOptions,
  FeedbackPollOptions,
  WordCloudOptions,
  Button,
  LoadingSpinner,
  ErrorMessage
} from 'components';
import { FirebaseContext, LocalContext } from 'context';
import TextInput from 'components/Events/Livestream/Interaction/Polls/Shared/TextInput';
import shortid from 'shortid';
import { ModalCloseIcon } from 'assets/svgs';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';

const initialQuestionState = () => ({
  text: '',
  error: null
});

const initialPollDescriptionState = () => ({
  allowed: false,
  text: '',
  error: null
});

const initialAnswersState = () => [
  {
    id: shortid.generate(),
    isCorrect: false,
    text: '',
    error: null
  },
  {
    id: shortid.generate(),
    isCorrect: false,
    text: '',
    error: null
  }
];

function CreateOrEditPollModal({
  colors,
  pollBeingCurrentlyEditedByModerator,
  setPollBeingCurrentlyEditedByModerator,
  newPollType,
  setIsCreateOrEditPollModalOpen
}) {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);

  // General Poll
  const [question, setQuestion] = useState(
    pollBeingCurrentlyEditedByModerator?.question || initialQuestionState()
  );

  const [answers, setAnswers] = useState(
    pollBeingCurrentlyEditedByModerator?.answers || initialAnswersState()
  );

  const [pollStatus, setPollStatus] = useState('');

  const [isMarkCorrectAnswersEnabled, setIsMarkCorrectAnswersEnabled] = useState(
    pollBeingCurrentlyEditedByModerator?.answers?.some((answer) => answer.isCorrect) || false
  );

  const [allowMultipleAnswers, setAllowMultipleAnswers] = useState(
    pollBeingCurrentlyEditedByModerator?.multipleAnswers?.allowed || false
  );

  const [numberOfMultipleAnswersAllowed, setNumberOfMultipleAnswersAllowed] = useState(
    pollBeingCurrentlyEditedByModerator?.multipleAnswers?.allowed
      ? pollBeingCurrentlyEditedByModerator.multipleAnswers.max
      : 'All'
  );

  const [enableTimer, setEnableTimer] = useState(
    (pollBeingCurrentlyEditedByModerator?.timer &&
      pollBeingCurrentlyEditedByModerator?.timer?.enabled) ||
      false
  );

  const [timerSeconds, setTimerSeconds] = useState(
    pollBeingCurrentlyEditedByModerator?.timer?.enabled
      ? pollBeingCurrentlyEditedByModerator.timer.seconds
      : 20
  );

  // Feedback Poll
  const [allowPersonalFeedbackMessage, setAllowPersonalFeedbackMessage] = useState(
    pollBeingCurrentlyEditedByModerator?.personalFeedbackMessage || false
  );

  const [allowContactField, setAllowContactField] = useState(
    pollBeingCurrentlyEditedByModerator?.contactField || false
  );

  const [ratingOption, setRatingOption] = useState({
    stars: pollBeingCurrentlyEditedByModerator?.ratingOption?.ratingType === 'stars' || false,
    emojis: pollBeingCurrentlyEditedByModerator?.ratingOption?.ratingType === 'emojis' || false,
    numbers: pollBeingCurrentlyEditedByModerator?.ratingOption?.ratingType === 'numbers' || false
  });

  const [selectedRatingType, setSelectedRatingType] = useState(
    pollBeingCurrentlyEditedByModerator?.ratingOption?.ratingType || 'stars'
  );

  const [ratingAmount, setRatingAmount] = useState(
    pollBeingCurrentlyEditedByModerator?.ratingOption?.amount || '5'
  );

  // Word Cloud
  const [allowMultipleInputs, setAllowMultipleInputs] = useState(
    pollBeingCurrentlyEditedByModerator?.multipleInputs?.allowed || false
  );

  const [characterLimit, setCharacterLimit] = useState(
    pollBeingCurrentlyEditedByModerator?.characterLimit?.allowed || false
  );

  const [numberOfCharactersAllowed, setNumberOfCharactersAllowed] = useState(
    pollBeingCurrentlyEditedByModerator?.characterLimit?.limit || 12
  );

  // Shared
  const [pollDescription, setPollDescription] = useState(
    pollBeingCurrentlyEditedByModerator?.description || initialPollDescriptionState()
  );

  useEffect(
    () => () => pollBeingCurrentlyEditedByModerator && setPollBeingCurrentlyEditedByModerator(null),
    [pollBeingCurrentlyEditedByModerator]
  );

  useEffect(() => {
    if (question.error?.code === 'missing-question' && question !== '') {
      setQuestion((currentState) => ({
        ...currentState,
        error: null
      }));
    }
  }, [question.text]);

  useEffect(() => {
    answers.forEach((answer, i) => {
      if (answer.error?.code === 'missing-answer' && answer.text) {
        setAnswers((currentState) =>
          currentState.map((_answer, j) =>
            i === j
              ? {
                  ..._answer,
                  error: null
                }
              : _answer
          )
        );
      }
    });
  }, [answers]);

  useEffect(() => {
    if (!pollBeingCurrentlyEditedByModerator?.ratingOption?.ratingType) {
      setRatingOption({
        stars: true,
        emojis: false,
        numbers: false
      });
      setSelectedRatingType('stars');
    }
  }, [pollBeingCurrentlyEditedByModerator]);

  const handleQuestionTextChange = ({ target }) =>
    setQuestion((currentState) => ({
      ...currentState,
      text: target.value
    }));

  const handleDescriptionTextChange = ({ target }) =>
    setPollDescription((currentState) => ({
      ...currentState,
      text: target.value
    }));

  const handleAnswerTextChange = (e, answerId) => {
    const { value } = e.target;

    const allAnswerIds = answers.map((answer) => answer.id);

    const lastFieldIsFocused = answerId === allAnswerIds[allAnswerIds.length - 1];

    // Adds an extra answer field when user starts typing in the last answer field.
    if (lastFieldIsFocused && value !== '') {
      setAnswers((currentState) => [
        ...currentState,
        {
          id: shortid.generate(),
          isCorrect: false,
          text: '',
          error: null
        }
      ]);
    }

    setAnswers((currentState) =>
      currentState.map((answer) => {
        if (answer.id === answerId) {
          answer.text = value;
        }
        return answer;
      })
    );
  };

  const handleMarkCorrectAnswersOption = () => {
    setIsMarkCorrectAnswersEnabled((currentState) => !currentState);
    if (answers.filter((answer) => answer.isCorrect).length > 0) {
      setAnswers((currentState) =>
        currentState.map((answer) => ({
          ...answer,
          isCorrect: false
        }))
      );
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!question.text) {
      setQuestion((currentState) => ({
        ...currentState,
        error: {
          code: 'missing-question',
          message: 'Text is required'
        }
      }));
    }

    if (newPollType === 'word cloud' && pollDescription.allowed && !pollDescription.text) {
      setPollDescription((currentState) => ({
        ...currentState,
        error: {
          code: 'missing-description',
          message: 'Desription is required'
        }
      }));
    }

    if (newPollType === 'general poll') {
      const emptyAnswerFields = [];

      answers
        .map(({ text }) => text)
        .forEach((answerText, i, arr) => {
          if (answerText === '' && (arr.length <= 2 || (arr.length > 2 && i !== arr.length - 1))) {
            setAnswers((currentState) =>
              currentState.map((answer, j) =>
                i === j
                  ? {
                      ...answer,
                      error: {
                        code: 'missing-answer',
                        message: 'Text is required'
                      }
                    }
                  : answer
              )
            );
            emptyAnswerFields.push(answerText);
          }
        });

      if (
        question.text &&
        (!pollDescription.allowed || (pollDescription.allowed && pollDescription.text)) &&
        !emptyAnswerFields.length &&
        (!isMarkCorrectAnswersEnabled ||
          (isMarkCorrectAnswersEnabled && answers.filter((answer) => answer.isCorrect).length > 0))
      ) {
        try {
          setPollStatus('saving');
          const poll = {
            type: newPollType,
            question,
            answers: answers.filter((answer) => answer.text),
            multipleAnswers: {
              allowed: allowMultipleAnswers,
              max:
                allowMultipleAnswers && numberOfMultipleAnswersAllowed === 'All'
                  ? answers.filter((answer) => answer.text).length
                  : allowMultipleAnswers && numberOfMultipleAnswersAllowed !== 'All'
                  ? numberOfMultipleAnswersAllowed
                  : null
            },
            timer: {
              enabled: enableTimer,
              seconds: enableTimer ? timerSeconds : null
            },
            isQueued: pollBeingCurrentlyEditedByModerator
              ? pollBeingCurrentlyEditedByModerator.isQueued
              : true,
            isOpen: pollBeingCurrentlyEditedByModerator
              ? pollBeingCurrentlyEditedByModerator.isOpen
              : false,
            shareResults: false
          };

          if (pollBeingCurrentlyEditedByModerator) {
            await firebase.interaction.polls.editPoll({
              eid: selectedEvent.eid,
              poll,
              pid: pollBeingCurrentlyEditedByModerator.pid
            });
          } else {
            await firebase.interaction.polls.saveNewPoll({ eid: selectedEvent.eid, poll });
          }
          setIsCreateOrEditPollModalOpen(false);
        } catch (error) {
          console.error(error);
          setPollStatus('error');
        }
      }
    } else if (newPollType === 'feedback poll') {
      if (
        question.text &&
        (!pollDescription.allowed || (pollDescription.allowed && pollDescription.text))
      ) {
        const ratingOptions = [];
        for (let i = 0; i < ratingAmount; i++) {
          ratingOptions.push({
            rid: shortid.generate(),
            option: i
          });
        }
        try {
          setPollStatus('saving');
          const poll = {
            type: newPollType,
            question,
            ratingOption: {
              ratingType: selectedRatingType,
              amount: ratingAmount,
              ratingOptions: ratingOptions.filter((option) => option)
            },
            personalFeedbackMessage: allowPersonalFeedbackMessage,
            contactField: allowContactField,
            isQueued: pollBeingCurrentlyEditedByModerator
              ? pollBeingCurrentlyEditedByModerator.isQueued
              : true,
            isOpen: pollBeingCurrentlyEditedByModerator
              ? pollBeingCurrentlyEditedByModerator.isOpen
              : false,
            shareResults: false
          };

          if (pollBeingCurrentlyEditedByModerator) {
            await firebase.interaction.polls.editPoll({
              eid: selectedEvent.eid,
              poll,
              pid: pollBeingCurrentlyEditedByModerator.pid
            });
          } else {
            await firebase.interaction.polls.saveNewPoll({ eid: selectedEvent.eid, poll });
          }

          setIsCreateOrEditPollModalOpen(false);
        } catch (error) {
          console.error(error);
          setPollStatus('error');
        }
      }
    } else if (newPollType === 'word cloud') {
      if (
        question.text &&
        (!pollDescription.allowed || (pollDescription.allowed && pollDescription.text))
      ) {
        try {
          setPollStatus('saving');

          const poll = {
            type: newPollType,
            question,
            description: {
              allowed: pollDescription.allowed,
              text: pollDescription.allowed && pollDescription.text ? pollDescription.text : null
            },
            multipleInputs: {
              allowed: allowMultipleInputs
            },
            characterLimit: {
              allowed: characterLimit,
              max: characterLimit ? numberOfCharactersAllowed : null
            },
            words: [],
            isQueued: pollBeingCurrentlyEditedByModerator
              ? pollBeingCurrentlyEditedByModerator.isQueued
              : true,
            isOpen: pollBeingCurrentlyEditedByModerator
              ? pollBeingCurrentlyEditedByModerator.isOpen
              : false,
            shareResults: false
          };

          if (pollBeingCurrentlyEditedByModerator) {
            await firebase.interaction.polls.editPoll({
              eid: selectedEvent.eid,
              poll,
              pid: pollBeingCurrentlyEditedByModerator.pid
            });
          } else {
            await firebase.interaction.polls.saveNewPoll({ eid: selectedEvent.eid, poll });
          }

          setIsCreateOrEditPollModalOpen(false);
        } catch (error) {
          console.error(error);
          setPollStatus('error');
        }
      }
    }
  };

  return (
    <Underlay
      colors={colors}
      data-underlay
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true') {
          setIsCreateOrEditPollModalOpen(false);
        }
      }}>
      <Modal colors={colors}>
        <div onClick={() => setIsCreateOrEditPollModalOpen(false)} role="button" tabIndex={0}>
          <ModalCloseIcon width="12" height="12" style={{ top: '1.25rem', right: '1.25rem' }} />
        </div>
        <form onSubmit={handleSave}>
          <div style={{ position: 'relative' }}>
            <QuestionTextInput
              error={question.error?.code === 'missing-question'}
              name="question"
              onChange={handleQuestionTextChange}
              placeholder="What would you like to ask?"
              selectedEvent={selectedEvent}
              type="text"
              value={question.text}
            />
            <ErrorMessage
              errorMessage={question.error?.code === 'missing-question' && question.error.message}
              style={{
                color: '#F55151',
                fontSize: '0.75rem',
                position: 'absolute',
                top: '2.2rem'
              }}
              variants={fadeInAndOutAndAnimateHeightVariants()}
            />
          </div>
          <div style={{ position: 'relative' }}>
            {pollDescription.allowed && (
              <>
                <DescriptionTextInput
                  error={pollDescription.error?.code === 'missing-description'}
                  name="description"
                  onChange={handleDescriptionTextChange}
                  placeholder="A short description or instruction for participants"
                  selectedEvent={selectedEvent}
                  type="text"
                  value={pollDescription.text}
                />
                <ErrorMessage
                  errorMessage={
                    pollDescription.error?.code === 'missing-description' &&
                    pollDescription.error.message
                  }
                  style={{
                    color: '#F55151',
                    fontSize: '0.75rem',
                    position: 'absolute',
                    top: '2.2rem'
                  }}
                  variants={fadeInAndOutAndAnimateHeightVariants()}
                />
              </>
            )}
          </div>
          {(newPollType === 'general poll' ||
            pollBeingCurrentlyEditedByModerator?.type === 'general poll') && (
            <>
              <Answers
                answers={answers}
                handleAnswerTextChange={handleAnswerTextChange}
                isMarkCorrectAnswersEnabled={isMarkCorrectAnswersEnabled}
                selectedEvent={selectedEvent}
                setAnswers={setAnswers}
              />
              <GeneralPollOptions
                answers={answers}
                isMarkCorrectAnswersEnabled={isMarkCorrectAnswersEnabled}
                handleMarkCorrectAnswersOption={handleMarkCorrectAnswersOption}
                allowMultipleAnswers={allowMultipleAnswers}
                setAllowMultipleAnswers={setAllowMultipleAnswers}
                numberOfMultipleAnswersAllowed={numberOfMultipleAnswersAllowed}
                setNumberOfMultipleAnswersAllowed={setNumberOfMultipleAnswersAllowed}
                enableTimer={enableTimer}
                setEnableTimer={setEnableTimer}
                timerSeconds={timerSeconds}
                setTimerSeconds={setTimerSeconds}
              />
            </>
          )}
          {(newPollType === 'feedback poll' ||
            pollBeingCurrentlyEditedByModerator?.type === 'feedback poll') && (
            <FeedbackPollOptions
              allowPersonalFeedbackMessage={allowPersonalFeedbackMessage}
              setAllowPersonalFeedbackMessage={setAllowPersonalFeedbackMessage}
              allowContactField={allowContactField}
              setAllowContactField={setAllowContactField}
              ratingOption={ratingOption}
              setRatingOption={setRatingOption}
              setSelectedRatingType={setSelectedRatingType}
              ratingAmount={ratingAmount}
              setRatingAmount={setRatingAmount}
            />
          )}
          {(newPollType === 'word cloud' ||
            pollBeingCurrentlyEditedByModerator?.type === 'word cloud') && (
            <WordCloudOptions
              allowMultipleInputs={allowMultipleInputs}
              setAllowMultipleInputs={setAllowMultipleInputs}
              pollDescription={pollDescription}
              setPollDescription={setPollDescription}
              characterLimit={characterLimit}
              setCharacterLimit={setCharacterLimit}
              numberOfCharactersAllowed={numberOfCharactersAllowed}
              setNumberOfCharactersAllowed={setNumberOfCharactersAllowed}
            />
          )}
          <Button
            colors={selectedEvent.colors}
            type="submit"
            whileHover={{
              backgroundColor: selectedEvent.colors.tertiary
            }}
            whileTap={{
              scale: 0.95
            }}
            style={{
              alignSelf: 'flex-end',
              backgroundColor: selectedEvent.colors.tertiary,
              borderRadius: '0.125rem',
              height: '2.5rem',
              marginTop: '0.45rem',
              width: '5rem'
            }}>
            {pollStatus === 'saving' ? (
              <LoadingSpinner style={{ width: '1.625rem', color: '#fff' }} />
            ) : (
              'Save'
            )}
          </Button>
        </form>
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 3.95rem auto 0;
  max-width: 23.75rem;
  padding: 1.25rem 1.875rem;
  position: relative;

  /* Close Modal Icon */
  > div {
    align-items: center;
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -2.8rem;
    width: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
  }
`;

const QuestionTextInput = styled(TextInput)`
  height: 1.938rem;
  margin-bottom: 1.75rem;
`;

const DescriptionTextInput = styled(TextInput)`
  font-size: 0.75rem;
  height: 2.583em;
  letter-spacing: 0.025em;
  margin-bottom: 2.333em;
`;

export default CreateOrEditPollModal;
