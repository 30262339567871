import * as React from 'react';

function SvgVisa(props) {
  return (
    <svg viewBox="0 0 260 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={260} height={112} rx={11} fill="#fff" />
      <path
        d="M104.939 23.206L77.373 88.971H59.388L45.823 36.484c-.824-3.233-1.535-4.417-4.044-5.779-4.09-2.218-10.843-4.3-16.779-5.593l.398-1.906H54.35c3.69 0 7.008 2.457 7.846 6.706l7.164 38.053 17.705-44.759h17.875zm70.467 44.297c.073-17.359-24.003-18.314-23.837-26.07.051-2.36 2.303-4.869 7.217-5.51 2.438-.318 9.155-.562 16.778 2.944l2.989-13.947c-4.1-1.494-9.367-2.92-15.92-2.92-16.817 0-28.652 8.94-28.752 21.74-.109 9.47 8.444 14.753 14.894 17.899 6.631 3.222 8.856 5.292 8.831 8.174-.047 4.412-5.289 6.36-10.188 6.436-8.554.132-13.514-2.314-17.469-4.153l-3.084 14.409c3.975 1.824 11.313 3.415 18.92 3.495 17.875 0 29.567-8.829 29.621-22.502m44.408 21.473h15.734l-13.734-65.765H207.29a7.743 7.743 0 00-7.238 4.826l-25.533 60.94h17.866l3.546-9.824h21.823l2.06 9.823zM200.83 65.668l8.955-24.696 5.154 24.694-14.109.002zm-71.582-42.462l-14.069 65.765H98.166l14.074-65.765h17.008z"
        fill="#1434CB"
      />
    </svg>
  );
}
export default SvgVisa;
