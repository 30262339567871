import * as React from 'react';

function SvgArrow(props) {
  return (
    <svg viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 1l6 6 6-6" strokeWidth={2} />
    </svg>
  );
}

export default SvgArrow;
