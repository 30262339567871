import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Vimeo from '@vimeo/player';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'components';
import { LocalContext } from 'context';
import { Play, FullyFunded } from 'assets/svgs';
import { breakpoints, H3, P, H4 } from 'styles';
import Thumbnail from '../../../assets/images/about-video-thumbnail.png';

const About = ({ invert }) => {
  const { theme } = useContext(LocalContext);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [vimeoPlayer, setVimeoPlayer] = useState(null);

  useEffect(() => {
    const iframe = document.getElementById('about-video');
    const _vimeoPlayer = new Vimeo(iframe);
    setVimeoPlayer(_vimeoPlayer);
  }, []);

  useEffect(() => {
    if (videoPlaying && vimeoPlayer) {
      vimeoPlayer.play();
    }
  }, [videoPlaying]);

  return (
    <Container id="about" theme={theme} invert={invert}>
      <InnerContainer invert={invert}>
        <H3>About MentorsWork</H3>
        <Content>
          <P>
            <H4>
              Your pathway to <br />
              business success <br />
              begins here.
            </H4>
            <br />
            <br />
            The MentorsWork programme is designed to provide you with the skills and supports to
            help your business thrive, by addressing the specific needs of your business. You choose
            the priority areas for your business and we tailor the programme to suit your needs.
            <br />
            <br />
            MentorsWork is a structured 12-week business-support programme, comprising an integrated
            approach of mentoring and targeted learning opportunities. The programme includes:
            <br />
            <br />
            <ul>
              <li>In-depth Online Competency Assessment</li>
              <li>One-to-One Mentoring Sessions</li>
              <li>Unlimited access to Peer-Focused Workshops</li>
              <li>Unlimited access to Expert-Led Masterclasses</li>
              <li>Multiple Licenses to a Curated Online Learning Platform</li>
            </ul>
            <br />
            During the application process, you will select the areas you want to tackle from a
            range of options. Based on your selections, we will assign you an experienced mentor who
            will work with you on a one-to-one basis throughout the 12 weeks.
          </P>
          <RightContent>
            <VideoWrapper theme={theme} videoPlaying={videoPlaying}>
              <iframe
                title="About MentorsWork"
                src="https://player.vimeo.com/video/715986677"
                id="about-video"
                allow="autoplay;"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              />
              <AnimatePresence>
                {!videoPlaying && (
                  <>
                    <img src={Thumbnail} alt="Video Thumbnail" />
                    <Overlay type="button" onClick={() => setVideoPlaying(true)}>
                      <Play />
                    </Overlay>
                  </>
                )}
              </AnimatePresence>
            </VideoWrapper>
            <FlexGrid>
              <a href="https://register.mentorswork.ie/" target="_blank" rel="noreferrer">
                <Button
                  colors={theme}
                  type="submit"
                  whileHover={{
                    scale: 1.0125
                  }}
                  whileTap={{
                    scale: 0.95
                  }}
                  style={{
                    maxWidth: '300px',
                    padding: '1rem 2rem',
                    backgroundColor: `${theme.tertiary}`,
                    color: '#FFFFFF',
                    fontFamily: '"droid-sans", sans-serif',
                    fontWeight: '700'
                  }}>
                  Sign-up to the Programme
                </Button>
              </a>
              <FullyFunded />
            </FlexGrid>
          </RightContent>
        </Content>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ invert }) => (invert ? '#FFFFFF' : ' #ededed')};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  padding: 3rem 1rem;
  ${breakpoints.sm} {
    grid-column: 2/6;
    padding: 6rem 2rem;
  }
  ${breakpoints.lg} {
    grid-gap: 1.25rem;
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 6rem 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
  ${H3} {
    color: ${({ theme }) => theme.tertiary};
    font-family: 'droid-sans', sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
  ${P} {
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
    a {
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
      text-underline-position: under;
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  background-color: ${({ invert }) => (invert ? '#FFFFFF' : ' #ededed')};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  grid-column-gap: 1rem;
  justify-content: center;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1.25rem;
  ${P} {
    font-weight: 500;
  }
  ${H4} {
    font-size: 2.375rem;
    line-height: 2.625rem;
    margin-bottom: 0.125em;
  }
  ul {
    margin-left: 1rem;
  }
  @media only screen and (min-width: 1150px) {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 50% 50%;
  }
`;

const RightContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
`;

const VideoWrapper = styled.div`
  height: 0;
  padding-bottom: 56%; /* 16:9 */
  position: relative;
  width: 100%;
  iframe {
    border-width: 0px;
    z-index: 0;
  }
  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`;

const FlexGrid = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  svg {
    max-width: 168px;
  }
`;

const Overlay = styled(motion.button)`
  background: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  svg {
    height: 100px;
    width: 100px;
  }
`;

export default About;
