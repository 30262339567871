import * as React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Markup } from 'interweave';

const Answer = ({ answer }) => (
  <Container
    variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
    transition={{ duration: 0.375, delay: 0.25 }}
    className="content-placeholder">
    <p>
      <Markup content={answer} />
    </p>
  </Container>
);

const Container = styled(motion.div)`
  font-size: 1rem;
  padding: 1.25rem 3rem;
  text-align: left;
  width: 100%;
  p {
    color: #3c3c3c !important;
    margin: 0 !important;
    text-align: left !important;
  }
  a {
    border-bottom: 1px solid #3c3c3c;
    color: #3c3c3c !important;
  }
`;

export default Answer;
