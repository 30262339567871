import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { LoginOrSignUp, Button, LoadingSpinner, Modal, RenderWhenReady } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';
import ICalendarLink from 'react-icalendar-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useWindowSize } from 'hooks';
import { MentorsWorkLiveLogo, Add } from 'assets/svgs';
import { H2, H4, H3, breakpoints } from 'styles';

const EventHeader = ({ event }) => {
  const eventDetails = {
    title: `${event.name}`,
    description: `Visit https://mentorsworklive.ie/events/${event.slug} to start watching the event.`,
    location: 'Virtual Conference',
    startTime: `${event.start[0]}`,
    endTime: `${event.end[0]}`
  };
  const bannerImage = getImage(event.banner);
  const mobileBannerImage = getImage(event.mobileBanner);
  const {
    theme,
    headerRef,
    isMobile,
    setNavOpen,
    setRegisteredRecentlyWithOneStep,
    showLoginOrSignUpDialog,
    showSignUpForm,
    setShowLoginOrSignUpDialog
  } = useContext(LocalContext);
  const { loading, user, firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;
  const [isRegistering, setIsRegistering] = useState(false);
  const { windowWidth } = useWindowSize();
  const showComponent = !loading && firebase && !user;

  const colors = {
    primary: '#002a3a',
    secondary: '#FFFFFF',
    tertiary: '#64a70b'
  };

  const handleRegisterForEventIfUserAlreadyHasAnAccount = async () => {
    setIsRegistering(true);
    const { name, slug, start, end, location } = event;

    firebase.registration
      .registerForEventIfAccountAlreadyExists({
        eid: event.eid,
        uid: user.uid
      })
      .then(({ data: _user }) => {
        const { eventsUserCanAccess } = _user;
        setIsRegistering(false);
        setRegisteredRecentlyWithOneStep(true);
      })
      .catch((error) => {
        console.error(error);
        setIsRegistering(false);
      });
  };

  return (
    <Header
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Banner
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 1.5 }}>
        <RenderWhenReady>
          {isMobile ? (
            <BannerImage image={mobileBannerImage} alt="Hub Placeholder" />
          ) : (
            <BannerImage image={bannerImage} alt="Hub Placeholder" />
          )}
        </RenderWhenReady>
      </Banner>
      <Container event={event.eid}>
        <Content>
          <MentorsWorkLiveLogo />
          <Text
            $theme={theme}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            <Date
              dangerouslySetInnerHTML={{
                __html: event.date
              }}
            />
            {isMobile && showComponent && (
              <RegisterButton $theme={theme}>
                <Button
                  width="210px"
                  type="button"
                  shine
                  style={{
                    fontWeight: 'bold'
                  }}
                  onClick={() => setShowLoginOrSignUpDialog(true)}>
                  <p>Log In / Sign Up</p>
                </Button>
              </RegisterButton>
            )}
            {event.eid === '2' && <SubTitle>E-Commerce Mastery 2024</SubTitle>}
            <Title
              dangerouslySetInnerHTML={{
                __html: event.title
              }}
            />
            <Description dangerouslySetInnerHTML={{ __html: event.description }} />
            {event?.status === 'future' && (
              <StyledDateButton width="250px" theme={theme}>
                <AddIcon theme={theme} />
                <ICalendarLink
                  style={{
                    height: '3rem',
                    background: 'transparent',
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '-30px',
                    position: 'relative'
                  }}
                  event={eventDetails}>
                  Add Date To Calendar
                </ICalendarLink>
              </StyledDateButton>
            )}
          </Text>
        </Content>
        {windowWidth >= 1150 && showComponent && !showLoginOrSignUpDialog && (
          <FormContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 1 }}
            id="login-or-signup"
            showLoginOrSignUpDialog={showLoginOrSignUpDialog}
            showSignUpForm={showSignUpForm}>
            <LoginOrSignUp colors={colors} hideCloseIcon event={event} />
          </FormContainer>
        )}
      </Container>
    </Header>
  );
};

const Content = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 3;
  svg {
    max-width: 385px;
  }
  ${breakpoints.sm} {
    padding: 3rem 2rem 3rem 2rem;
  }
  ${breakpoints.lg} {
    padding: 2em 1em 1em 1rem;
  }
`;

const Text = styled(motion.div)`
  align-items: flex-start;
  color: ${({ $theme }) => $theme.primary};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  span {
    color: ${({ $theme }) => $theme.tertiary};
  }
`;

const Date = styled(H4)`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;
`;

const Title = styled(H2)`
  font-size: 2.8rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
  span {
    font-weight: 700;
  }
  @media screen and (min-width: 700px) {
    text-align: left;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2.25rem;
    line-height: 1.15em;
  }
  @media screen and (min-width: 1150px) {
    font-size: 2.125rem;
  }
  @media screen and (min-width: 1200px) {
    font-size: 2.25rem;
  }
  @media screen and (min-width: 1440px) {
    font-size: 2.8rem;
  }
`;

const SubTitle = styled(H3)`
  font-size: 1.313rem;
  font-weight: 700;
  margin-bottom: -1rem;
  text-transform: uppercase;
`;

const Description = styled(motion.p)`
  font-family: 'museo-slab', serif;
  font-size: 1.125rem;
  font-weight: 500;
  span {
    font-weight: 700;
  }
`;

const FormContainer = styled(motion.div)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  perspective: 1200px;
  position: relative;
  z-index: 2;
  > :last-child {
    margin: 0 auto;
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
  }
`;

const Container = styled.div`
  align-items: center;
  grid-column: 1 / 7;
  justify-content: center;
  padding: 6rem 1rem 3rem 1rem;
  width: 100%;
  z-index: 4;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 6rem 0 1rem 0;
  }
  ${breakpoints.lg} {
    display: grid;
    gap: 3rem;
    grid-column: 2 / 12;
    grid-template-columns: 55% 45%;
  }
`;

const StyledDateButton = styled(motion.div)`
  align-items: center;
  background: transparent;
  border-radius: 30px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  left: 1rem;
  padding: 1rem 1rem 1rem 0;
  position: relative;
  width: 280px;
  z-index: 2;
  a {
    align-items: center;
    background: white;
    background-color: transparent;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
    display: flex;
    font-family: 'museo-slab', serif;
    font-size: 1rem;
    font-weight: 700;
    justify-content: flex-start;
    list-style: none;
    margin: 0.5rem 0;
    text-transform: uppercase;
    transition: 0.5s;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
  svg {
    circle {
      fill: ${({ theme }) => theme.tertiary};
    }
    path {
      stroke: #fff;
    }
  }
  @media (min-width: 1024px) {
    left: -0.5rem;
  }

  @keyframes slideDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

const AddIcon = styled(Add)`
  height: 3rem;
  margin-right: 0.5rem;
  width: 1.5rem;
`;

const BannerImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  width: 100%;
  z-index: 0;
  @media (min-width: 1024px) {
    opacity: 1;
  }
`;

const Banner = styled(motion.div)`
  grid-column: 1/7;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  @media (min-width: 1024px) {
    grid-column: 1/13;
  }
`;

const Header = styled.header`
  background: white;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  position: relative;
  width: 100%;
  z-index: 3;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const RegisterButton = styled(motion.div)`
  color: white;
  margin-bottom: 1rem;
  button {
    align-items: center;
    background-color: ${({ $theme }) => $theme.fg};
    display: flex;
    justify-content: center;
    padding: 1.625rem 1rem;
    p {
      color: white;
    }
  }
`;

export default EventHeader;
