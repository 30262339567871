/* eslint-disable react/no-danger */
import React, { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Arrow } from 'assets/svgs';
import { Link } from 'gatsby';
import { hexToRGB } from 'utils';
import { useEventData } from 'hooks';
import { LocalContext, FirebaseContext } from 'context';
import { Markup } from 'interweave';

const NavbarDropdown = ({ toggleNav }) => {
  const { edges: events } = useEventData();
  const { mobile, theme } = useContext(LocalContext);
  const { user, firebase } = useContext(FirebaseContext);
  const [expanded, setExpanded] = useState(false);
  const dropdownToggle = () => setExpanded((currentValue) => !currentValue);

  const futureEvents = events
    .filter(({ node }) => node.frontmatter.status === 'future')
    .sort((a, b) => b.node.frontmatter.eid - a.node.frontmatter.eid);
  const pastEvents = events
    .filter(({ node }) => node.frontmatter.status === 'past')
    .sort((a, b) => b.node.frontmatter.eid - a.node.frontmatter.eid);

  return (
    <Dropdown expanded={expanded} theme={theme}>
      <Heading
        onClick={() => {
          if (mobile) {
            dropdownToggle();
          }
        }}
        onMouseEnter={() => {
          if (!mobile) {
            dropdownToggle();
          }
        }}
        onMouseLeave={() => setExpanded(false)}
        expanded={expanded}
        theme={theme}>
        <DownArrow theme={theme} expanded={expanded} />
        <span>Event Selection</span>
        <ContentContainer expanded={expanded} theme={theme}>
          <AnimatePresence initial={false}>
            {expanded && (
              <Content
                onClick={() => {
                  setExpanded(false);
                  toggleNav();
                }}
                initial="exit"
                animate={expanded ? 'enter' : 'exit'}
                exit="exit"
                variants={{
                  enter: {
                    opacity: 1,
                    rotateX: 0,
                    transition: {
                      duration: 0.5
                    },
                    display: 'block'
                  },
                  exit: {
                    opacity: 0,
                    rotateX: -15,
                    transition: {
                      duration: 0.5,
                      delay: 0.3
                    },
                    transitionEnd: {
                      display: 'none'
                    }
                  }
                }}>
                {futureEvents.length > 0 && (
                  <>
                    <H4>Future Events</H4>
                    {futureEvents.map((event, i) => (
                      <StyledLink
                        to={
                          firebase?.auth?.currentUser && user
                            ? `/events/${event.node.frontmatter.slug}/livestream`
                            : `/events/${event.node.frontmatter.slug}`
                        }
                        key={i}
                        onClick={() => {
                          setExpanded(false);
                          toggleNav();
                        }}
                        theme={theme}>
                        {`${event.node.frontmatter.name}:`}
                        <br />
                        <span>
                          <Markup content={event.node.frontmatter.date} noHtml />
                        </span>
                      </StyledLink>
                    ))}
                  </>
                )}
                {pastEvents.length === 0 && <br />}
                {pastEvents.length > 0 && (
                  <>
                    {futureEvents.length > 0 && <br />}
                    <H4>Past Events</H4>
                    {pastEvents.map((event, i) => (
                      <StyledLink
                        to={
                          firebase?.auth?.currentUser && user
                            ? `/events/${event.node.frontmatter.slug}/livestream`
                            : `/events/${event.node.frontmatter.slug}`
                        }
                        key={i}
                        onClick={() => {
                          setExpanded(false);
                          toggleNav();
                        }}
                        theme={theme}>
                        {`${event.node.frontmatter.name}:`}
                        <br />
                        <span>
                          <Markup content={event.node.frontmatter.date} noHtml />
                        </span>
                      </StyledLink>
                    ))}
                    <br />
                  </>
                )}
              </Content>
            )}
          </AnimatePresence>
        </ContentContainer>
      </Heading>
    </Dropdown>
  );
};

const DownArrow = styled(Arrow)`
  height: auto;
  margin-left: 0;
  margin-right: 0.5rem;
  min-width: 1rem;
  transition-duration: 0.5s;
  width: 1rem;
  path {
    stroke: ${({ expanded, theme }) => (expanded ? theme.tertiary : theme.secondary)};
    transition: 0.25s;
  }
  @media (min-width: 1150px) {
    height: auto;
    min-width: 0.75rem;
    width: 0.75rem;
  }
`;

const Content = styled(motion.section)`
  bottom: 15px;
  position: relative;
  @media screen and (min-width: 768px) {
    bottom: 0;
  }

  @media (min-width: 1150px) {
    width: 100%;
    a {
      text-align: left;
    }

    padding-top: 1.25rem;
  }
`;

const ContentContainer = styled.div`
  height: auto;
  text-align: center;
  width: 100%;

  @media (min-width: 1150px) {
    border: 1px solid ${({ theme }) => theme.tertiary};
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
    display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
    height: auto;
    left: 0.6rem;
    position: absolute;
    text-align: left;
    top: 3.5rem;
    width: 412px;
  }
`;

const Heading = styled(motion.div)`
  background: white;
  border: none;
  color: ${({ theme }) => theme.secondary};
  font-family: 'Uni Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 3rem;
  outline: none;
  text-align: center;
  transition: 0.5s;
  width: 100%;
  > span {
    cursor: pointer;
  }

  @media (min-width: 1150px) {
    align-items: center;
    background: transparent;
    color: ${({ expanded, theme }) => (expanded ? theme.tertiary : theme.primary)};
    display: flex;
    font-size: 0.875rem;
    height: 100%;
    line-height: 1.5rem;
    padding-left: 1em;
    text-align: left;
    width: 180px;
    &:hover {
      color: ${({ theme }) => theme.tertiary};
    }
    &:hover ${DownArrow} {
      path {
        stroke: ${({ theme }) => theme.tertiary};
      }
    }
  }
`;

const Dropdown = styled.div`
  height: auto;
  position: relative;
  width: 100%;
  h4 {
    color: ${({ theme }) => theme.primary};
  }
  @media (min-width: 1150px) {
    align-items: center;
    display: flex;
    grid-column: 3/6;
    height: 100%;
    margin-left: 1rem;
    overflow: visible;
    position: relative;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.tertiary};
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 500;
  height: auto;
  justify-content: center;
  line-height: 1.325em;
  margin-bottom: 0.25rem;
  padding: 0.5rem 1.25rem;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  span {
    font-size: 0.75rem;
    padding: 0;
  }
  &:hover {
    background-color: ${({ theme }) => hexToRGB({ color: theme.tertiary, alpha: 0.5 })};
    color: #fff;
  }

  @media (min-width: 1150px) {
    align-items: flex-start;
    justify-content: flex-start;
    span {
      font-size: 0.875rem;
    }
  }
`;

const H4 = styled.h4`
  font-size: 0.75rem;
  height: 2.4325rem;
  text-transform: uppercase;
  @media (min-width: 1150px) {
    font-size: 0.875rem;
    padding: 0rem 1.25rem;
    height: unset;
  }
`;

export default NavbarDropdown;
