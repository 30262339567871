import React, { useContext } from 'react';
import styled from 'styled-components';
import { breakpoints, H3, P, H4 } from 'styles';
import { SbciLogo, MfLogo, SfaLogo, IbecLogo, SkillnetLogo } from 'assets/svgs';
import { LocalContext } from 'context';

const Partnerships = ({ noMargin }) => {
  const { theme } = useContext(LocalContext);
  return (
    <Container theme={theme} noMargin>
      <InnerContainer theme={theme}>
        <H3>
          MentorsWork is brought to you by SFA in partnership with Skillnet Ireland, Ibec, SBCI and
          Microfinance Ireland
        </H3>
        <Logos>
          <SfaLogo />
          <SkillnetLogo />
          <IbecLogo />
          <SbciLogo />
          <MfLogo />
        </Logos>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  background: transparent;
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  margin-top: ${({ noMargin }) => (noMargin ? '2rem' : '-3rem')};
  padding: 0 1rem 3rem 1rem;
  ${breakpoints.sm} {
    grid-column: 2/6;
  }
  ${breakpoints.lg} {
    grid-gap: 1.25rem;
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 0 3rem 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
  ${H3} {
    color: ${({ theme }) => theme.primary};
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
  }
  ${P} {
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
    a {
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
      text-underline-position: under;
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  border-top: ${({ theme }) => `2px solid ${theme.tertiary}`};
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  grid-column-gap: 1rem;
  justify-content: center;
  padding-top: 3rem;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Logos = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 3rem;
  justify-content: center;
  margin: 0 auto;
  margin-top: 3rem;
  svg {
    height: auto;
    width: 140px;
  }
  ${breakpoints.lg} {
    margin-top: 0;
    svg {
      height: 152px;
    }
  }
`;

export default Partnerships;
