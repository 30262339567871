import * as React from 'react';

function SvgMentorsWorkLiveLogo(props) {
  return (
    <svg viewBox="0 0 385 232" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M235.724 3.75752C233.557 3.88559 226.958 5.18055 222.461 6.26205C220.948 6.62492 220.294 6.34743 220.998 5.90629C222.71 4.83191 224.422 3.75752 226.155 2.66179C226.858 1.85067 225.558 1.50202 224.137 1.87913C221.985 2.22065 219.804 2.43411 217.673 2.91082C215.876 3.30927 216.87 2.43411 217.098 2.12816C217.417 1.70125 217.616 1.27434 217.311 0.683784C216.558 -0.753474 211.216 0.790511 209.553 1.43799C195.907 6.73876 183.54 14.2168 172.515 24.1282C157.128 37.96 143.176 53.1579 131.462 70.3908C118.32 89.7368 108.979 111.153 100.305 132.89C98.0602 138.518 96.4192 144.139 94.1176 150.151C93.5991 149.177 93.2012 148.536 92.9029 147.846C87.504 135.494 82.1691 123.114 76.6921 110.798C75.1648 107.361 74.078 103.661 71.4993 100.787C71.0376 100.274 70.4764 99.8545 69.8867 99.4632C67.6136 97.9833 67.8054 99.2782 67.6136 100.061C67.2513 101.555 64.3671 98.2679 64.0475 98.1256C63.5076 97.8837 62.2218 97.8338 62.3284 99.5699C62.371 100.31 61.8524 99.4917 59.3732 99.8332C55.5656 100.353 56.5672 103.263 54.8836 102.822C53.5339 102.466 53.2853 103.085 53.6547 104.358C53.9317 105.319 54.2372 106.294 54.3438 107.347C54.4006 107.93 54.9547 110.214 52.717 107.717C52.1984 107.133 51.8077 106.443 51.4525 105.76C50.3088 103.54 48.9733 104.921 49.3001 105.959C49.8116 107.574 49.9039 109.681 51.0121 112.797C56.3044 127.696 62.989 141.983 69.0343 156.548C73.3037 166.822 77.6369 177.082 79.2779 188.253C79.6189 190.587 80.7129 191.953 82.6167 192.963C88.868 196.307 98.0389 194.963 103.452 190.146C106.634 187.314 107.75 183.45 109.199 179.701C116.85 159.906 121.716 138.397 130.702 119.172C141.358 96.3824 154.173 74.8875 168.906 54.6734C185.827 31.471 209.49 15.654 236.278 5.97745C238.615 5.14497 237.294 3.66503 235.709 3.75752H235.724Z"
        fill="#76BC21"
      />
      <path
        d="M227.661 4.20578C229.749 3.3733 231.994 3.15274 234.14 2.63333C234.488 2.54795 235.148 1.59452 233.351 1.37395C232.2 1.23165 228.258 2.06412 226.482 3.44446C225.999 3.82156 225.693 4.98844 227.661 4.20578Z"
        fill="#76BC21"
      />
      <path
        d="M218.952 1.79374C219.989 1.29569 220.998 1.26011 222.006 0.989734C222.17 0.947044 222.617 0.292451 221.886 0.0363062C221.417 -0.127342 219.577 0.263991 218.575 1.17473C218.298 1.42376 217.971 2.25623 218.952 1.78663V1.79374Z"
        fill="#76BC21"
      />
      <path
        d="M178.581 101.583C204.524 101.583 225.317 120.723 225.317 147.618C225.317 174.514 204.524 193.653 178.744 193.653C152.965 193.653 132.009 174.684 132.009 147.618C132.009 120.552 152.802 101.583 178.581 101.583ZM178.744 175.033C192.547 175.033 203.856 163.997 203.856 147.618C203.856 131.239 192.547 120.204 178.744 120.204C164.942 120.204 153.462 131.41 153.462 147.618C153.462 163.827 164.935 175.033 178.744 175.033Z"
        fill="#122C3A"
      />
      <path
        d="M230.14 175.204H242.451V123.825C242.451 121.243 241.286 120.033 238.792 120.033H229.309V103.654H251.43C258.917 103.654 262.739 107.276 262.739 114.341V119.514C262.739 121.065 262.739 122.274 262.576 123.477C262.412 123.996 262.412 124.508 262.412 125.028H262.746C266.738 112.441 276.549 102.444 289.691 102.444C290.856 102.444 291.687 102.444 292.355 102.615C292.852 102.786 293.186 102.786 293.52 102.786V124.338C293.022 124.338 292.525 124.338 292.021 124.167C290.856 123.996 289.861 123.996 288.362 123.996C271.228 123.996 263.577 139.514 263.577 156.064V175.204H275.718V191.583H230.147V175.204H230.14Z"
        fill="#122C3A"
      />
      <path
        d="M292.838 175.204H305.148V85.5459H292.007V69.1669H326.268V136.924H337.414L351.216 120.026H341.406V103.647H382.153V120.026H371.178L352.715 142.609V142.958C352.878 142.958 355.876 144.338 359.201 150.372L371.178 172.094C372.677 174.684 374.168 175.197 378.161 175.197H382.984V191.576H367.185C360.032 191.576 357.205 190.366 353.88 183.991L338.579 156.235C336.746 152.962 334.423 152.962 331.262 152.962H326.275V175.204H335.425V191.583H292.845V175.204H292.838Z"
        fill="#122C3A"
      />
      <path
        d="M47.5454 116.917L37.1526 154.94C36.4848 157.501 35.2062 163.884 35.2062 163.884C35.2062 163.884 33.8067 157.494 33.3095 154.94L21.2757 102.345H0L21.7232 192.088H46.288C46.288 192.088 53.3704 166.658 59.3802 145.825C55.2955 136.248 51.1896 126.679 47.5383 116.909L47.5454 116.917Z"
        fill="#122C3A"
      />
      <path
        d="M0.951902 93.6501C0.525677 93.6501 0.291254 93.4224 0.291254 92.9599V61.4327C0.291254 60.9703 0.525677 60.7426 0.951902 60.7426H6.0737C6.40047 60.7426 6.68462 60.8778 6.8267 61.2477L12.5665 71.6928C13.8381 73.9909 15.8129 78.1391 16.5659 79.7969H16.7506C17.5036 78.1391 19.4785 73.9981 20.75 71.6928L26.4401 61.2477C26.6248 60.8778 26.909 60.7426 27.2429 60.7426H32.322C32.7909 60.7426 33.0253 60.9703 33.0253 61.4327V92.9599C33.0253 93.4224 32.7909 93.6501 32.322 93.6501H28.0456C27.6194 93.6501 27.3849 93.4224 27.3849 92.9599V83.7102C27.3849 79.3842 27.5696 73.7704 27.7614 69.2594L27.6194 69.2096C26.4899 71.6003 24.9412 74.6882 23.3855 77.7264L19.2511 85.5957C19.1091 85.9159 18.8249 86.1009 18.4484 86.1009H14.8752C14.4987 86.1009 14.2146 85.9159 14.0725 85.5957L9.93814 77.7264C8.38952 74.6882 6.8338 71.6074 5.70431 69.2096L5.56223 69.2594C5.74693 73.7704 5.93873 79.3842 5.93873 83.7102V92.9599C5.93873 93.4224 5.70431 93.6501 5.23546 93.6501H0.951902Z"
        fill="#122C3A"
      />
      <path
        d="M46.4159 88.5912H61.5113C61.9802 88.5912 62.2146 88.8189 62.2146 89.2387V92.967C62.2146 93.4295 61.9802 93.6572 61.5113 93.6572H41.3864C40.9602 93.6572 40.7258 93.4295 40.7258 92.967V61.4399C40.7258 60.9774 40.9602 60.7497 41.3864 60.7497H60.8507C61.3195 60.7497 61.5539 60.9774 61.5539 61.4399V65.1682C61.5539 65.5809 61.3195 65.8157 60.8507 65.8157H46.4159V74.425H59.6288C60.0977 74.425 60.3321 74.6527 60.3321 75.1151V78.8008C60.3321 79.2633 60.0977 79.491 59.6288 79.491H46.4159V88.6054V88.5912Z"
        fill="#122C3A"
      />
      <path
        d="M68.9347 93.6501C68.5085 93.6501 68.2741 93.4224 68.2741 92.9599V61.4327C68.2741 60.9703 68.5085 60.7426 68.9347 60.7426H73.6871C74.0636 60.7426 74.3478 60.9276 74.5325 61.2051L83.7034 76.8512C85.3018 79.6119 87.0919 82.6999 88.4061 85.1831L88.5482 85.1333C88.264 81.2199 88.1219 76.5239 88.1219 72.6604V61.4327C88.1219 60.9703 88.3564 60.7426 88.8252 60.7426H93.059C93.5279 60.7426 93.7623 60.9703 93.7623 61.4327V92.9599C93.7623 93.4224 93.5279 93.6501 93.059 93.6501H88.3066C87.9799 93.6501 87.6957 93.5149 87.5039 93.1876L78.6171 78.7794C76.8767 76.0188 74.9019 72.4256 73.5877 69.7148L73.4456 69.7574C73.68 73.7135 73.8718 78.5019 73.8718 82.4153V92.9599C73.8718 93.4224 73.6374 93.6501 73.2112 93.6501H68.9347Z"
        fill="#122C3A"
      />
      <path
        d="M123.477 60.7426C123.904 60.7426 124.181 60.9703 124.181 61.4327V65.2038C124.181 65.6164 123.896 65.8512 123.477 65.8512H114.029V92.9599C114.029 93.4224 113.795 93.6501 113.369 93.6501H109.042C108.616 93.6501 108.382 93.4224 108.382 92.9599V65.8512H98.8841C98.4579 65.8512 98.1809 65.6235 98.1809 65.2038V61.4327C98.1809 60.9703 98.465 60.7426 98.8841 60.7426H123.477Z"
        fill="#122C3A"
      />
      <path
        d="M140.121 94.2051C131.796 94.2051 126.908 89.4664 126.908 81.412V72.945C126.908 64.8907 131.796 60.1947 140.121 60.1947C148.447 60.1947 153.334 64.8907 153.334 72.945V81.412C153.334 89.4664 148.447 94.2051 140.121 94.2051ZM140.121 88.9541C145.009 88.9541 147.552 86.3286 147.552 81.4049V72.9806C147.552 68.0071 145.016 65.3888 140.121 65.3888C135.227 65.3888 132.691 68.0142 132.691 72.9806V81.4049C132.691 86.3286 135.227 88.9541 140.121 88.9541Z"
        fill="#122C3A"
      />
      <path
        d="M178.766 93.6501C178.439 93.6501 178.155 93.5149 177.963 93.1876L169.737 81.6326H165.553V92.9599C165.553 93.4224 165.318 93.6501 164.892 93.6501H160.566C160.14 93.6501 159.905 93.4224 159.905 92.9599V61.4327C159.905 60.9703 160.14 60.7426 160.566 60.7426H171.477C179.185 60.7426 183.703 64.6559 183.703 71.2374C183.703 76.3034 180.833 79.7542 175.896 81.042L184.498 92.8247C184.825 93.3299 184.591 93.6501 184.072 93.6501H178.759H178.766ZM165.553 65.8512V76.6662H171.477C175.853 76.6662 177.92 74.9657 177.92 71.2801C177.92 67.5944 175.853 65.8512 171.477 65.8512H165.553Z"
        fill="#122C3A"
      />
      <path
        d="M200.347 94.2051C195.644 94.2051 192.163 92.7322 188.356 89.7866C188.029 89.4664 187.979 89.1889 188.214 88.8189L190.565 85.5957C190.799 85.2756 191.126 85.2257 191.503 85.4605C194.557 87.6236 197.378 88.8687 200.574 88.9612C204.005 88.9612 207.394 87.5311 207.394 84.4075C207.394 81.0919 203.87 80.2665 199.779 79.434C194.842 78.4237 188.356 76.2109 188.356 69.3519C188.356 62.9554 194.138 60.1947 200.908 60.1947C204.95 60.1947 208.147 61.2975 211.962 63.8305C212.338 64.0582 212.388 64.4282 212.146 64.7484L209.887 67.9217C209.653 68.2917 209.276 68.2917 208.9 68.0569C206.122 66.3066 203.352 65.4812 200.482 65.3888C196.44 65.3888 194.089 66.9541 194.089 69.4373C194.089 72.3829 197.1 73.1656 201.235 74.0834C206.733 75.2788 213.226 77.0291 213.226 84.3008C213.226 91.5725 207.209 94.1979 200.34 94.1979L200.347 94.2051Z"
        fill="#122C3A"
      />
      <path
        d="M290.387 226.07H293.385L296.688 209.164H293.655L294.678 203.849H307.535L306.512 209.164H303.444L300.261 225.686H306.1L306.896 221.694H312.586L310.768 231.001H289.449L290.401 226.063L290.387 226.07Z"
        fill="#76BC21"
      />
      <path
        d="M317.395 226.07L320.734 208.787H317.736L318.681 203.849H331.539L330.594 208.787H327.525L324.187 226.07H327.22L326.268 231.008H313.446L314.39 226.07H317.395Z"
        fill="#76BC21"
      />
      <path
        d="M332.91 208.787L333.862 203.849H345.654L344.709 208.787H342.052L343.303 220.712C343.452 222.043 343.267 223.672 343.267 223.672H343.416C343.416 223.672 343.835 222.036 344.517 220.712L350.399 208.787H347.743L348.694 203.849H360.87L359.925 208.787H357.652L345.59 231.008H338.614L335.204 208.787H332.91Z"
        fill="#76BC21"
      />
      <path
        d="M358.817 226.07H361.815L365.189 208.787H362.156L363.108 203.849H384.007L382.451 211.868H376.761L377.294 209.171H371.909L370.851 214.679H378.779L377.713 219.994H369.785L368.684 225.693H374.524L375.056 222.996H380.747L379.191 231.015H357.872L358.824 226.077L358.817 226.07Z"
        fill="#76BC21"
      />
    </svg>
  );
}

export default SvgMentorsWorkLiveLogo;
