import * as React from 'react';

function SvgBgMoon2(props) {
  return (
    <svg viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx="150.111"
        cy="149.889"
        r="148.889"
        fill="#64A70B"
        stroke="#64A70B"
        strokeWidth="2"
      />
    </svg>
  );
}

export default SvgBgMoon2;
