import React, { useState, useEffect, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Question, Button, Dialog, ConfirmDelete } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { hexToRGB } from 'utils';

const QAndA = ({ eid, submittedQuestions, questionCurrentlyBeingAnsweredLive }) => {
  const {
    selectedEvent,
    theme,
    confirmDeletePollOrQuestionDialogRef,
    showConfirmDeleteDialog,
    setShowConfirmDeleteDialog,
    handleDialog
  } = useContext(LocalContext);
  const { colors } = selectedEvent;
  const { firebase, user } = useContext(FirebaseContext);
  const [textArea, setTextArea] = useState('');
  const [submitAnonymousQuestion, setSubmitAnonymousQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionToBeDeleted, setQuestionToBeDeleted] = useState(null);

  const isModerator = user?.isModerator?.includes(eid);

  const submittedQuestionsRef = useRef();

  const handleDeleteQuestion = (e) => {
    e.stopPropagation();
    const { qid, uid } = questionToBeDeleted;
    if (firebase) {
      firebase.interaction.qAndA.deleteUserQuestion({ uid, qid }).then(() =>
        handleDialog({
          dialogRef: confirmDeletePollOrQuestionDialogRef,
          animation: 'close',
          stateHandler: () => setShowConfirmDeleteDialog(false),
          callback: () => setQuestionToBeDeleted(null)
        })
      );
    }
  };

  const handleDismissDeleteQuestion = (e) => {
    e.stopPropagation();
    handleDialog({
      dialogRef: confirmDeletePollOrQuestionDialogRef,
      animation: 'dismiss',
      stateHandler: () => setShowConfirmDeleteDialog(false),
      callback: () => setQuestionToBeDeleted(null)
    });
  };

  const handleTextAreaChange = (e) => {
    e.persist();
    if (e.target.value !== ' ') {
      setTextArea(e.target.value);
    }
  };

  const handleSubmitNewQuestion = (e) => {
    e.preventDefault();
    if (textArea !== '' && textArea !== ' ') {
      firebase.interaction.qAndA
        .submitNewQuestion({
          uid: user.uid,
          eid,
          text: textArea,
          name: submitAnonymousQuestion ? '' : user.name
        })
        .then(() => {
          setTextArea('');
          setSubmitAnonymousQuestion(false);
        });
    }
  };

  const handleAnswerQuestionLive = () => {
    if (questionCurrentlyBeingAnsweredLive === selectedQuestion?.text) {
      firebase.interaction.qAndA.stopShowingAnswerLiveOverlay({ eid });
      setSelectedQuestion(null);
    } else {
      firebase.interaction.qAndA.answerThisQuestionLive({
        eid,
        text: selectedQuestion.text
      });
    }
  };

  const handleSendAnswer = () => {
    if (textArea !== '' || textArea !== ' ') {
      firebase.interaction.qAndA
        .submitAnswer({
          text: textArea,
          eid,
          qid: selectedQuestion.qid,
          uid: selectedQuestion.uid
        })
        .then(() => {
          setTextArea('');
          setSelectedQuestion(null);
        });
    }
  };

  return (
    <>
      <Wrapper colors={colors} isModerator={isModerator}>
        {isModerator ? (
          <p>Moderator&apos;s Control Panel</p>
        ) : (
          <p style={{ color: user ? 'black' : '#bdbdbd' }}>
            Here you can submit questions,
            <br />
            which will be discussed during the livestream.
          </p>
        )}
        <div>
          <SubmittedQuestions colors={colors} tabIndex={0}>
            <AnimatePresence initial={false}>
              {submittedQuestions?.map((submittedQuestion, index, arr) => (
                <Question
                  colors={colors}
                  key={submittedQuestion.qid}
                  eid={eid}
                  submittedQuestion={submittedQuestion}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                  questionToBeDeleted={questionToBeDeleted}
                  setQuestionToBeDeleted={setQuestionToBeDeleted}
                  index={index}
                  arr={arr}
                  submittedQuestionsRef={submittedQuestionsRef}
                />
              ))}
            </AnimatePresence>
          </SubmittedQuestions>
          {isModerator ? (
            <AnswerQuestion colors={colors}>
              <TextArea
                $theme={theme}
                id="answer"
                name="answer"
                colors={colors}
                value={textArea}
                disabled={!selectedQuestion}
                onChange={handleTextAreaChange}
                placeholder="Select a question then type your answer here to send an answer directly to the user, or click 'Answer Live' to answer it on air during the livestream."
              />
              <div>
                <AnswerLiveButton
                  onClick={handleAnswerQuestionLive}
                  disabled={!selectedQuestion}
                  colors={colors}
                  thisQuestionIsCurrentlyBeingAnsweredLive={
                    questionCurrentlyBeingAnsweredLive === selectedQuestion?.text
                  }
                />
                <SendAnswerButton
                  onClick={handleSendAnswer}
                  disabled={!textArea || !selectedQuestion}
                  colors={colors}
                />
              </div>
            </AnswerQuestion>
          ) : (
            <SubmitNewQuestion onSubmit={handleSubmitNewQuestion} colors={colors}>
              <TextArea
                $theme={theme}
                id="question"
                name="question"
                colors={colors}
                value={textArea}
                onChange={handleTextAreaChange}
                placeholder={
                  user ? 'Type your question...' : 'Q&A only available for registered users'
                }
                disabled={!user}
              />
              <div>
                <label htmlFor="anonymousCheckbox">
                  <AnonymousCheckbox
                    checked={submitAnonymousQuestion}
                    id="anonymousCheckbox"
                    name="anonymousCheckbox"
                    onChange={(e) => setSubmitAnonymousQuestion(e.target.checked)}
                    colors={colors}
                    type="checkbox"
                    disabled={!user}
                    style={{ cursor: user ? 'pointer' : 'default' }}
                  />
                  Submit Anonymously
                </label>
                <Button
                  type="submit"
                  style={{ color: '#FFFFFF' }}
                  disabled={textArea.length < 1}
                  whileTap={{ scale: textArea ? 0.9 : 1 }}
                  whileHover={{
                    backgroundColor: colors.secondary
                  }}>
                  Submit Question
                </Button>
              </div>
            </SubmitNewQuestion>
          )}
        </div>
      </Wrapper>
      <Dialog
        ref={confirmDeletePollOrQuestionDialogRef}
        isVisible={showConfirmDeleteDialog}
        onDismiss={handleDismissDeleteQuestion}
        contentStyle={{
          maxWidth: '23.75rem'
        }}>
        <ConfirmDelete
          handleDelete={handleDeleteQuestion}
          handleDismiss={handleDismissDeleteQuestion}
          type="question"
        />
      </Dialog>
    </>
  );
};

const AnswerLiveButton = styled(Button).attrs(
  ({ thisQuestionIsCurrentlyBeingAnsweredLive, disabled }) => ({
    children: thisQuestionIsCurrentlyBeingAnsweredLive ? 'Stop Showing' : 'Answer Live',
    whileTap: {
      scale: !disabled && 0.95
    }
  })
)`
  border-style: solid;
  border-width: 0.063rem;
  margin-right: 0.625rem;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  transition-property: background-color, border, color;

  ${({ thisQuestionIsCurrentlyBeingAnsweredLive, colors }) =>
    thisQuestionIsCurrentlyBeingAnsweredLive
      ? css`
          background-color: #ff7aa1;
          border-color: #ff7aa1;
          color: #fff;
        `
      : css`
          background-color: #fff;
          border-color: ${colors.secondary};
          color: ${colors.secondary};

          &:not([disabled]):hover {
            background-color: ${colors.tertiary};
            border-color: ${colors.tertiary};
            color: #fff;
          }
        `}
`;

const SendAnswerButton = styled(Button).attrs(({ disabled }) => ({
  children: 'Send Answer',
  whileTap: {
    scale: !disabled && 0.95
  }
}))`
  color: #fff;
  background-color: ${({ colors }) => colors.tertiary};
  margin-left: 0.625rem;
  transition: background-color 300ms ease-in-out;

  &:not([disabled]):hover {
    background-color: ${({ colors }) => colors.tertiary};
  }
`;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  background-color: #fff;
  padding: 0.75rem;

  @media screen and (min-width: 410px) {
    padding: 1.275rem 1.0125rem;
  }

  @media screen and (min-width: 1150px) and (max-width: 1365px) {
    padding: 0.75rem;
  }

  @media screen and (min-width: 1366px) {
    padding: 1.275rem 1.0125rem;
  }

  > p {
    color: #000;
    font-size: 0.875rem;
    font-style: ${({ isModerator }) => (isModerator ? 'normal' : 'italic')};
    font-weight: ${({ isModerator }) => (isModerator ? 600 : 300)};
    line-height: 1.25em;
    margin: 0.5em 0 1em;
    text-align: center;
    ${({ isModerator }) => isModerator && `transform: scale3d(1.125, 1.125, 1.125)`}

    @media screen and (min-width: 410px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      font-size: 0.875rem;
      margin: 0.5em 0 1em;
    }

    @media screen and (min-width: 1366px) {
      font-size: 1rem;
      margin: 0.063em 0 1.25em;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.6rem);
    justify-content: space-between;

    @media screen and (min-width: 410px) {
      height: calc(100% - 2.15rem);
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      height: calc(100% - 2.65rem);
    }

    @media screen and (min-width: 1366px) {
      height: calc(100% - 2.2rem);
    }
  }
`;

const SubmittedQuestions = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const SubmittedQuestion = styled(motion.div)`
  background-color: ${({ isSelected, colors }) =>
    isSelected ? hexToRGB({ color: colors.tertiary, alpha: 0.2 }) : 'transparent'};
  color: ${({ isSelected, colors }) => (isSelected ? colors.tertiary : '#000')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  font-size: 1rem;
  margin-bottom: 0.688em;
  padding: 0.125em 0.25em 0.25em;
  position: relative;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  p {
    padding-left: ${({ isModerator }) => (isModerator ? '1.375rem' : 0)};
  }

  p:first-of-type {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.313em;

    span:first-of-type {
      font-weight: 700;
    }

    span:last-of-type {
      color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
      transition: color 200ms ease-in-out;
      font-size: 0.75em;
    }
  }

  small {
    color: ${({ isStarred, colors }) => (isStarred ? colors.tertiary : '#000')};
    display: inline-block;
    font-size: 0.65rem;
    left: 0.95em;
    position: relative;
  }
`;

const ModeratorsAnswer = styled(motion.div)`
  color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  margin-top: 0.625rem;
  padding: 0.125rem 0 0.25rem 1rem;
  transition: color 200ms ease-in-out;
`;

const SelectQuestionCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  height: 1rem;
  margin-top: 2px;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.tertiary};
  }

  &:checked {
    border: 1px solid ${({ colors }) => colors.tertiary};
  }

  &:checked:before {
    color: ${({ colors }) => colors.tertiary};
    content: '✓';
    font-size: 0.8rem;
    height: 0.8rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37%, -60%);
    width: 0.8rem;
  }
`;

const TextArea = styled.textarea`
  border: 0.063em solid ${({ colors }) => colors.tertiary};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  height: 6.25em;
  margin-top: 0.5em;
  outline-color: ${({ colors }) => colors.tertiary};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.625em;
  resize: none;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }

  &::placeholder {
    color: #c4c4c4;
    font-size: 1rem;
  }
`;

const AnswerQuestion = styled.div`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 9px;

    button {
      border-radius: 0.125rem;
      max-width: 11.875rem;
    }
  }

  /* button:first-of-type {
      background-color: #fff;
      border: 1px solid ${({ colors }) => colors.secondary};
      color: ${({ colors }) => colors.secondary};

      @media screen and (max-width: 1149px) {
        margin-right: 30px;
      }
    } */

  /* button:last-of-type {
      background-color: ${({ colors }) => colors.secondary};
    } */
`;

const AnonymousCheckbox = styled(SelectQuestionCheckbox)`
  border: 1px solid #bdbdbd;
  height: 1rem;
  margin-right: 0.375rem;
  margin-top: unset;
  position: relative;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.tertiary};
    margin-right: 0.375rem;
  }

  &:checked {
    background-color: ${({ colors }) => colors.tertiary};
    border: 2px solid ${({ colors }) => colors.tertiary};
    margin-right: 0.375rem;
  }

  &:checked:before {
    color: ${({ contrast }) => (contrast ? `#000` : `#fff`)};
    font-size: 0.8rem;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const SubmitNewQuestion = styled.form`
  border-top: 0.063rem solid #bdbdbd;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0.438rem 0 1.4rem;

    @media screen and (min-width: 1150px) {
      margin: 0.438rem 0 1rem !important;
    }

    @media screen and (min-width: 1354px) {
      margin: 0.438rem 0 1.4rem !important;
    }

    button {
      border-radius: 0.125rem;
      background-color: ${({ colors }) => colors.tertiary};
      max-width: 11.875rem;
    }

    label {
      align-items: center;
      color: ${({ colors }) => colors.tertiary};
      display: flex;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 500px) and (max-width: 1149px) {
      /* justify-content: flex-end; */

      label {
        white-space: nowrap;
      }
    }

    @media screen and (min-width: 500px) and (max-width: 1300px) {
      label {
        margin-right: 1em;
      }
    }
  }
`;

export default QAndA;
