import React from 'react';
import styled from 'styled-components';
import { Visa } from 'assets/svgs';

const VisaContainer = () => (
  <Container>
    <h3>This event is in partnership with Visa.</h3>{' '}
    <p>
      At Visa, our mission is to connect the world through the most innovative, convenient, reliable
      and secure payments network, enabling individuals, businesses and economies to thrive. As a
      global leader in digital payments, we believe in the importance of supporting small
      independent businesses and provide opportunities for them to grow, as they are at the heart of
      our community and the backbone of our economy. This is why we created initiatives like
      Visa&apos;s She&apos;s Next grant programme and Where You Shop Matters.
    </p>
    <StyledVisa />
  </Container>
);

const Container = styled.div`
  background-color: #64a70b;
  color: #fff;
  display: grid;
  font-family: 'museo-slab', serif;
  padding: 2rem 1rem;
  text-align: center;

  h3 {
    font-size: 2.313rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 920px) {
    padding: 2rem 0 2.75rem;
    text-align: left;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    gap: 0.55rem 3rem;

    h3 {
      grid-column: 2/12;
      position: relative;
      left: -1.4rem;
    }

    p {
      grid-column: 2/10;
      grid-row: 2;
      line-height: normal;
      position: relative;
      left: -1.4rem;
    }
  }
`;

const StyledVisa = styled(Visa)`
  margin: 2rem auto 0;
  width: 260px;

  @media (min-width: 920px) {
    margin: unset;
    grid-column: 10/12;
    grid-row: 2;
  }
`;

export default VisaContainer;
