import * as React from 'react';

function SvgSkillnetLogo(props) {
  return (
    <svg viewBox="0 0 217 106" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#skillnet-logo_svg__clip0_1_2)">
        <path
          d="M2.738 82.22H.268v16.3h2.47v-16.3zM15.998 98.52l-3.43-6.87h-3.09v6.87h-2.47V82.21h6.32c3.09 0 5.06 1.99 5.06 4.76 0 2.34-1.42 3.82-3.25 4.33l3.76 7.21h-2.89l-.01.01zm-2.86-14.08h-3.66v5.11h3.66c1.65 0 2.77-.94 2.77-2.54s-1.12-2.57-2.77-2.57zM21.997 98.52v-16.3h10.42v2.22h-7.94v4.76h6.77v2.2h-6.77v4.9h7.94v2.22h-10.42zM35.808 98.52v-16.3h2.48V96.3h7.85v2.22h-10.33zM58.327 98.52l-1.12-3.28h-6.43l-1.12 3.28h-2.63l5.98-16.31h1.99l5.98 16.31h-2.65zm-4.28-12.62l-2.54 7.24h5.02l-2.47-7.24h-.01zM73.278 98.52l-7.53-11.47v11.47h-2.48v-16.3h2.27l7.54 11.45V82.22h2.47v16.3h-2.27zM89.747 96.99c-1.03 1.03-2.52 1.53-4.21 1.53h-5.72V82.21h5.72c1.69 0 3.18.5 4.21 1.53 1.76 1.76 1.65 4.15 1.65 6.5 0 2.35.12 4.97-1.65 6.73m-1.7-11.45c-.69-.76-1.6-1.1-2.77-1.1h-3v11.86h3c1.17 0 2.08-.34 2.77-1.1.85-.94.87-2.7.87-4.95s-.02-3.78-.87-4.72"
          fill="#002A3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M202.966 85.375a9.252 9.252 0 005.482 1.135c5.1-.52 8.81-5.08 8.29-10.18a9.273 9.273 0 00-5.173-7.403 52.843 52.843 0 01-8.599 16.448z"
          fill="#002A3A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.578 52.8c0 5.595-.871 10.987-2.483 16.047l.019-.075.014-.052c-1.98-.91-4.9-1-7.22 0-4.77 2.05-6.91 7.43-5.15 11.9.729 1.849 2.128 3.548 3.776 4.498-9.658 12.46-24.771 20.482-41.756 20.482-29.16 0-52.8-23.64-52.8-52.8 0-29.16 23.64-52.8 52.8-52.8 29.16 0 52.8 23.64 52.8 52.8zm-96.86 20.8c0 .6.3.9.9.9l-.02-.01h5.16c.6 0 .9-.3.9-.9V49.6c1.38-.36 3.6-.66 5.64-.66 4.38 0 6.96 1.68 6.96 7.32v17.33c0 .6.36.9.9.9h5.22c.54 0 .84-.3.84-.9V56.14c0-9.59-5.63-13.43-13.91-13.43-4.25 0-8.45.84-11.93 2.34-.48.24-.66.6-.66 1.08V73.6zm57.059-13.38c0 .54-.3.84-.9.84h-18.23v1.68c0 3.66 2.52 5.52 6.36 5.52 3.84 0 6.24-1.26 8.46-2.76.42-.3.9-.24 1.2.18l2.46 3.3c.36.42.3.9-.18 1.26-3.18 2.52-7.08 4.14-12.17 4.14-6.78 0-13.13-3.72-13.13-11.7v-8.52c0-8.82 6.6-12.29 13.19-12.29s12.95 3.48 12.95 12.29v6.06h-.01zm-12.955-12.23c-3.537.002-6.175 1.682-6.175 6.12v1.56h12.35v-1.56c0-4.378-2.817-6.118-6.175-6.12zm0 0h.005-.01.005zm31.066 19.7c-.96.06-1.92.12-2.46.12v.01c-3.06 0-4.74-1.92-4.74-5.22V48.87h6.36c.54 0 .9-.3.9-.84v-4.38c0-.54-.36-.84-.9-.84h-6.42l-.54-7.2c-.06-.48-.42-.78-.9-.78h-4.56c-.6 0-.9.3-.9.84v27.41c0 7.91 4.86 11.09 11.1 11.09.9 0 2.16-.06 3.18-.12.6-.06.84-.48.84-.96v-4.56c0-.54-.36-.84-.96-.84z"
          fill="#64A70B"
        />
        <path
          d="M15.658 74.59c-6 0-10.44-1.92-15.29-5.76-.42-.42-.48-.78-.18-1.26l3-4.2c.3-.42.72-.48 1.2-.18 3.9 2.82 7.5 4.44 11.57 4.56 4.38 0 8.7-1.86 8.7-5.94 0-4.32-4.5-5.4-9.71-6.48-6.3-1.32-14.58-4.2-14.58-13.13 0-8.34 7.38-11.94 16.01-11.94 5.16 0 9.24 1.44 14.09 4.74.48.3.54.78.24 1.2l-2.88 4.14c-.3.48-.78.48-1.26.18-3.54-2.28-7.08-3.36-10.74-3.48-5.16 0-8.16 2.04-8.16 5.28 0 3.84 3.84 4.86 9.12 6.06 7.02 1.56 15.29 3.84 15.29 13.31s-7.68 12.89-16.43 12.89M55.288 73.87c-.48 0-.9-.12-1.14-.54l-9.23-12.11a.25.25 0 00-.39-.02l-1.81 1.87c-.05.05-.07.11-.07.18v9.47c0 .64-.52 1.16-1.16 1.16h-4.64c-.64 0-1.16-.52-1.16-1.16V32.15c0-.64.52-1.16 1.16-1.16h4.64c.64 0 1.16.52 1.16 1.16v21.86c0 .24.29.35.45.18l10.1-10.96c.3-.36.6-.42 1.08-.42h6.18c.78 0 .96.48.48 1.02l-11.42 12.13c-.09.09-.1.23-.02.34l12.7 16.5c.42.6.24 1.08-.54 1.08h-6.36l-.01-.01zM68.417 39.86c-2.21 0-3.96-1.75-3.96-3.96 0-2.02 1.37-3.53 3.24-3.84 2.61-.43 4.99 1.95 4.56 4.56-.31 1.86-1.81 3.24-3.84 3.24zM65.778 73.87c-.54 0-.9-.3-.9-.9V43.64c0-.54.36-.84.9-.84h5.22c.54 0 .84.3.84.84v29.33c0 .6-.3.9-.84.9h-5.22zM87.097 74.59c-6.48 0-10.02-4.32-10.02-11.63V31.89c0-.6.3-.9.9-.9h5.16c.6 0 .9.3.9.9v30.83c0 3.96 1.26 5.52 3.96 5.52.72 0 1.5-.06 2.28-.12.6 0 .9.3.9.9v4.44c0 .48-.18.9-.66.96-1.02.12-2.22.18-3.42.18M103.608 74.59c-6.48 0-10.01-4.32-10.01-11.63V31.89c0-.6.3-.9.9-.9h5.16c.6 0 .9.3.9.9v30.83c0 3.96 1.26 5.52 3.96 5.52.72 0 1.5-.06 2.28-.12.6 0 .9.3.9.9v4.44c0 .48-.18.9-.66.96-1.02.12-2.22.18-3.42.18"
          fill="#002A3A"
        />
      </g>
      <defs>
        <clipPath id="skillnet-logo_svg__clip0_1_2">
          <path fill="#fff" d="M0 0h217v106H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSkillnetLogo;
