import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { H5, P, breakpoints } from 'styles';
import { motion } from 'framer-motion';
import { GreenCheck } from 'assets/svgs';
import { RenderWhenReady } from 'components';
import { hexToRGB } from 'utils';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Markup } from 'interweave';

const Agendas = ({ agendas, colors, eid, breakouts }) => {
  const { agendaRef, theme } = useContext(LocalContext);
  const [selected, setSelected] = useState(agendas[0]);
  const { slots } = selected;

  return (
    <AgendaList id="agenda">
      <div ref={agendaRef} style={{ position: 'absolute', top: '-10rem' }} />
      <RenderWhenReady>
        <Container>
          <GridTitle $colors={colors} $theme={theme}>
            Agenda
          </GridTitle>
          <Agenda $colors={colors} $theme={theme}>
            <SectionTitle colors={colors}>Live Stream:</SectionTitle>
            {slots.map((slot) => (
              <Grid key={slot.orderNumber} $colors={colors}>
                <Time $colors={colors}>
                  <GreenCheck />
                  <Markup content={slot.time} />
                </Time>
                <Name $colors={colors} $wide={slot.multipleSpeakers}>
                  <Markup content={slot.title} />
                  {slot.speaker ? (
                    <Markup content={slot.speaker} />
                  ) : (
                    slot.multipleSpeakers.length > 1 &&
                    slot.multipleSpeakers.map((multiple, index) => (
                      <Segment key={index}>
                        <Markup content={multiple.speaker} />
                        <BreakoutWrapper>
                          <ExternalLink
                            colors={colors}
                            disabled={!multiple.link}
                            whileHover={{ scale: 1.0125 }}
                            whileTap={{
                              scale: 0.95
                            }}
                            href={multiple.link}
                            target="_blank"
                            rel="noreferrer">
                            {`Breakout ${index + 1}`}
                          </ExternalLink>
                          <ImgBorder colors={colors}>
                            <ImgContainer>
                              <SpeakerImg
                                image={getImage(multiple.image)}
                                alt={`Speaker ${index + 1}`}
                              />
                            </ImgContainer>
                          </ImgBorder>
                        </BreakoutWrapper>
                      </Segment>
                    ))
                  )}
                </Name>
                {slot.speakerImages && (
                  <SpeakerImages large={slot.speakerImages.length > 1}>
                    {slot.speakerImages.length > 1 ? (
                      slot.speakerImages.map((speakerImage, index) => (
                        <ImgBorder colors={colors}>
                          <ImgContainer key={index}>
                            <SpeakerImg image={getImage(speakerImage.image)} alt="speaker-1" />
                          </ImgContainer>
                        </ImgBorder>
                      ))
                    ) : (
                      <ImgBorder colors={colors}>
                        <ImgContainer>
                          <SpeakerImg
                            image={getImage(slot.speakerImages[0].image)}
                            alt="speaker-1"
                          />
                        </ImgContainer>
                      </ImgBorder>
                    )}
                  </SpeakerImages>
                )}
              </Grid>
            ))}
          </Agenda>
        </Container>
      </RenderWhenReady>
    </AgendaList>
  );
};

const BreakoutWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 1.5rem;
  justify-content: space-between;
  margin: 0 auto 2rem;
  white-space: nowrap;

  @media (min-width: 900px) {
    flex-direction: row;
    gap: 3rem;
    margin: 0;
  }
`;

const GridTitle = styled(H5)`
  color: ${({ $colors }) => $colors.tertiary};
  grid-column: 2/6;
  ${breakpoints.sm} {
    margin-left: 1.5rem;
    grid-column: 2/12;
  }
  ${breakpoints.lg} {
    font-size: 38px;
    margin-bottom: 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const SectionTitle = styled(P)`
  color: ${({ colors }) => colors.tertiary};
  font-size: 1.125rem;
  font-weight: 700;
  grid-column: 1/7;
  ${breakpoints.sm} {
    grid-column: 2/12;
    padding: 0.5rem 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const Time = styled.div`
  color: ${({ $colors }) => $colors.tertiary};
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  grid-column: 1/2;
  line-height: 1rem;
  svg {
    height: 1rem;
    min-height: 1rem;
    width: auto;
  }
  ${breakpoints.sm} {
    font-size: 1.5rem;
    gap: 0.5rem;
    line-height: 2rem;
    svg {
      height: 1.75rem;
      min-height: 1.75rem;
    }
  }
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: ${({ $wide }) => ($wide ? '1/7' : '1/5')};
  position: relative;
  h5 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    position: relative;
  }
  span {
    font-weight: 700;
  }
  p,
  ul {
    font-size: 1rem;
    font-weight: 350;
    line-height: 1.25rem;
    list-style-type: none;
    li {
      position: relative;
    }
    strong {
      color: ${({ $colors }) => $colors.tertiary};
    }
  }
  ${breakpoints.sm} {
    grid-column: ${({ $wide }) => ($wide ? '2/11' : '2/8')};
    h5 {
      font-size: 1.75rem;
    }
    p,
    ul {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  ${breakpoints.lg} {
    grid-column: ${({ $wide }) => ($wide ? '2/13' : '2/8')};
    svg {
      top: 0.25rem;
      height: 1.75rem;
      min-height: 1.75rem;
    }
    h5 {
      font-size: 2.375rem;
    }
  }
`;

const SpeakerImages = styled.div`
  align-self: start;
  display: flex;
  gap: 1rem;
  grid-column: ${({ large }) => (large ? '1 / 7 ' : '5 / 7')};
  height: 100%;
  justify-self: center;
  margin-top: auto;
  position: relative;
  ${breakpoints.sm} {
    grid-column: ${({ large }) => (large ? '1 / 11 ' : '8/11')};
    justify-self: ${({ large }) => (large ? 'center' : 'end')};
    margin-top: 0;
    min-height: 80px;
  }
  ${breakpoints.lg} {
    gap: 2rem;
    grid-column: 8/13;
    justify-self: end;
  }
`;

const ImgBorder = styled.div`
  height: 100px;
  position: relative;
  width: 100px;
  &:before {
    background: ${({ colors }) => colors.tertiary};
    border-radius: 50%;
    content: '';
    height: 100%;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 100%;
    z-index: 1;
  }
  &:after {
    background: transparent;
    border: 2px solid ${({ colors }) => colors.primary};
    border-radius: 50%;
    bottom: -5px;
    content: '';
    height: 100%;
    left: -5px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  ${breakpoints.sm} {
    height: 145px;
    width: 145px;
  }
`;

const ImgContainer = styled.div`
  background: #fff;
  border-radius: 50%;
  filter: none;
  height: 100px;
  overflow: hidden;
  position: relative;
  width: 100px;
  z-index: 2;
  ${breakpoints.sm} {
    height: 145px;
    width: 145px;
  }
`;

const SpeakerImg = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`;

const Segment = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (min-width) {
    gap: 3rem;
  }

  @media (min-width: 900px) {
    align-items: center;
    flex-direction: row;
  }
`;

const ExternalLink = styled(motion.a)`
  align-items: center;
  border: 2px solid
    ${({ colors, disabled }) =>
      disabled ? hexToRGB({ color: colors.tertiary, alpha: 0.25 }) : colors.tertiary};
  border-radius: 20px;
  color: ${({ colors, disabled }) =>
    disabled ? hexToRGB({ color: colors.tertiary, alpha: 0.25 }) : colors.tertiary};
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 2rem;
  /* max-height: 50px;
  max-width: 160px; */
  padding: 0.125rem 2rem;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  text-align: center;
  width: 100%;
  ${breakpoints.lg} {
    border-radius: 40px;
    font-size: 1.625rem;
    /* max-width: 232px; */
    padding: 0.35rem 2.5rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 1.25rem 0;
  position: relative;
  &:not(:last-of-type) {
    &:before {
      background-color: ${({ $colors }) => $colors.tertiary};
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  ${breakpoints.sm} {
    grid-column: 2/12;
    grid-gap: 2rem;
    grid-template-columns: repeat(10, 1fr);
    padding: 2rem 0;
  }
  ${breakpoints.lg} {
    grid-template-columns: repeat(12, 1fr);
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
`;

const Agenda = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${Name} {
    color: #3c3c3c;
  }
  ${breakpoints.sm} {
    margin: 0 1.5rem;
    width: auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: auto;
  justify-content: flex-start;
  row-gap: 2rem;
  width: 100%;
  ${breakpoints.lg} {
    grid-column: 2/12;
  }
`;

const AgendaList = styled.div`
  background-color: #ffffff;
  display: grid;
  font-family: 'museo-slab', serif;
  grid-column: 1/13;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  padding: 6rem 1rem 3rem 1rem;
  position: relative;
  width: 100%;
  z-index: 0;
  ${breakpoints.lg} {
    grid-column-gap: 3rem;
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
  }
`;

export default Agendas;
