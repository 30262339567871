import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

function SvgBin({ deleteDialogOpen }) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (deleteDialogOpen) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [deleteDialogOpen]);
  return (
    <StyledSVG
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => !deleteDialogOpen && setActive(false)}
      viewBox="0 0 448 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M32 128H416V448C416 483.3 387.3 512 352 512H96C60.7 512 32 483.3 32 448V128ZM128 192C119.2 192 112 199.2 112 208V432C112 440.8 119.2 448 128 448C136.8 448 144 440.8 144 432V208C144 199.2 136.8 192 128 192ZM224 192C215.2 192 208 199.2 208 208V432C208 440.8 215.2 448 224 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 224 192ZM320 192C311.2 192 304 199.2 304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192Z" />
      <motion.path
        style={{ originX: 0 }}
        animate={{
          rotate: active ? -45 : 0
        }}
        d="M163.8 0C151.7 0 140.6 6.8 135.2 17.7L128 32H32C14.3 32 0 46.3 0 64C0 81.7 14.3 96 32 96H224H416C433.7 96 448 81.7 448 64C448 46.3 433.7 32 416 32H320L312.8 17.7C307.4 6.8 296.3 0 284.2 0H163.8Z"
      />
    </StyledSVG>
  );
}

const StyledSVG = styled(motion.svg)`
  overflow: visible;
`;

export default SvgBin;
