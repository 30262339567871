import * as React from 'react';
import { motion } from 'framer-motion';

function SvgTick(props) {
  return (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.1,
        type: 'tween',
        ease: 'easeInOut'
      }}
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M1 4.5L3.5 7l6-6" stroke={props.color} strokeWidth={2} />
    </motion.svg>
  );
}

export default SvgTick;
