import * as React from 'react';

function SvgCheck(props) {
  return (
    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.55971 18.9098L0.434705 10.7848C-0.0534302 10.2967 -0.0534302 9.50524 0.434705 9.01705L2.20243 7.24928C2.69056 6.7611 3.48207 6.7611 3.9702 7.24928L9.44359 12.7226L21.167 0.99928C21.6551 0.511145 22.4466 0.511145 22.9348 0.99928L24.7025 2.76705C25.1906 3.25519 25.1906 4.04664 24.7025 4.53483L10.3275 18.9099C9.8393 19.398 9.04784 19.398 8.55971 18.9098V18.9098Z"
        fill="#71db80"
      />
    </svg>
  );
}

export default SvgCheck;
