import React from 'react';

const SVGEuropeanFlag = () => (
  <svg viewBox="0 0 810 540" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M810 0H0V540H810V0ZM405 60L398.265 80.7295H376.468L394.102 93.5408L387.366 114.271L405 101.459L422.634 114.271L415.898 93.5409L433.532 80.7295H411.735L405 60ZM405 420L398.265 440.729H376.468L394.102 453.541L387.366 474.271L405 461.459L422.634 474.271L415.898 453.541L433.532 440.729H411.735L405 420ZM225 240L218.265 260.729H196.468L214.102 273.541L207.366 294.271L225 281.459L242.634 294.271L235.898 273.541L253.532 260.729H231.735L225 240ZM297.366 138.386L315 125.574L332.634 138.386L325.898 117.656L343.532 104.845H321.735L315 84.1154L308.265 104.845H286.468L304.102 117.656L297.366 138.386ZM266.749 204.271L260.014 183.541L277.647 170.729H255.851L249.115 150L242.38 170.729H220.583L238.217 183.541L231.482 204.271L249.115 191.459L266.749 204.271ZM249.115 330L255.851 350.729H277.647L260.014 363.541L266.749 384.271L249.115 371.459L231.482 384.271L238.217 363.541L220.583 350.729H242.38L249.115 330ZM343.532 416.614H321.735L315 395.885L308.264 416.614H286.468L304.102 429.425L297.366 450.155L315 437.343L332.634 450.155L325.898 429.425L343.532 416.614ZM585 240L591.735 260.729H613.532L595.898 273.541L602.634 294.271L585 281.459L567.366 294.271L574.102 273.541L556.468 260.729H578.265L585 240ZM512.634 138.386L495 125.574L477.366 138.386L484.102 117.656L466.468 104.845H488.265L495 84.1154L501.735 104.845H523.532L505.898 117.656L512.634 138.386ZM543.251 204.271L549.986 183.541L532.353 170.729H554.149L560.885 150L567.62 170.729H589.416L571.783 183.541L578.518 204.271L560.885 191.459L543.251 204.271ZM560.885 330L554.149 350.729H532.353L549.986 363.541L543.251 384.271L560.885 371.459L578.518 384.271L571.783 363.541L589.416 350.729H567.62L560.885 330ZM495 395.885L488.265 416.614H466.468L484.102 429.425L477.366 450.155L495 437.343L512.634 450.155L505.898 429.425L523.532 416.614H501.736L495 395.885Z"
      fill="white"
    />
  </svg>
);

export default SVGEuropeanFlag;
