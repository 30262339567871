import React, { useContext } from 'react';
import styled from 'styled-components';
import { Livestream } from 'components';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LocalContext, FirebaseContext } from 'context';
import { Logo, LogoContrast } from 'assets/svgs';
import { H1, H5, breakpoints } from 'styles';

const EventStreamHeader = (props) => {
  const { theme } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  return (
    <Header
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
      $theme={theme}>
      <Container
        $theme={theme}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}>
        <Livestream {...props} />
      </Container>
    </Header>
  );
};

const LogoContainer = styled.div`
  height: auto;
  margin-bottom: 3rem;
  max-width: 80%;
  width: 750px;
  ${breakpoints.sm} {
    margin: 4rem 0;
    max-width: 100%;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  position: relative;
  width: 100%;
  z-index: 3;
  p {
    font-weight: normal;
    color: ${({ $theme }) => $theme.secondary};
  }
  ${breakpoints.sm} {
    grid-column: 1/7;
    padding: 40px;
  }
  ${breakpoints.lg} {
    grid-column: 2/9;
  }
  ${breakpoints.xl} {
    grid-column: 2/8;
  }
`;

const SlideDate = styled(H5)`
  :first-child {
    color: ${({ $theme }) => $theme.tertiary};
  }
`;

const SlideTitle = styled(H1)`
  color: ${({ $theme }) => $theme.primary};
`;

const SlideCaptionTitle = styled.h2`
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem;
  margin-bottom: 1.25rem;
  ${breakpoints.lg} {
    font-size: 4rem;
    line-height: 1.25em;
    margin-bottom: 1.5rem;
  }
  ${breakpoints.xl} {
    margin-bottom: 3rem;
  }
`;

const Container = styled.header`
  align-items: center;
  background: 'white';
  color: ${({ $theme }) => $theme.primary};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  justify-content: center;
  overflow: visible;
  padding-top: 3rem;
  position: relative;
  width: 100vw;
  z-index: 2;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 4rem 0;
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 7rem;
  }
`;

const Header = styled.header`
  background-color: rgba(196, 196, 196, 0.2);
  height: 100%;
  height: auto;
  overflow: visible;
  position: relative;
  width: 100%;
`;

export default EventStreamHeader;
