import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { IdiroAnalytics } from 'assets/svgs';
import { breakpoints, H3, P, H4 } from 'styles';

const Sponsor = () => {
  const { theme } = useContext(LocalContext);
  return (
    <Container theme={theme}>
      <InnerContainer>
        <H3>Sponsor</H3>
        <Content>
          <a href="https://idiro.com/" target="_blank" rel="noreferrer">
            <IdiroAnalytics />
          </a>
          <P>
            Idiro Analytics is a pioneer in AI and data analytics with nearly two decades of
            experience. Throughout this time, we have analysed the data of more than 20% of the
            world&apos;s population, which gave us unique insights into human behaviour and paved
            the way for our expertise in customer analytics.
            <br />
            <br />
            Our data-driven insights have been invaluable to many businesses in their
            decision-making. From telecoms to major national banks - we helped clients to make sense
            of their data.
            <br />
            <br />
            Our commitment to responsible innovation makes us a trustworthy guide for any business
            navigating the increasingly complex landscape of AI and data analytics.
          </P>
        </Content>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #eae9e8;
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  padding: 3rem 1rem;
  ${breakpoints.sm} {
    grid-column: 2/6;
    padding: 6rem 2rem;
  }
  ${breakpoints.lg} {
    grid-gap: 1.25rem;
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    padding: 6rem 0;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
  }
  ${H3} {
    color: ${({ theme }) => theme.tertiary};
    font-family: 'droid-sans', sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
  ${P} {
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1/7;
  grid-column-gap: 1rem;
  justify-content: center;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;
  svg {
    width: 90%;
    max-width: 325px;
  }
  ${P} {
    font-weight: 500;
  }
  ${H4} {
    font-size: 2.375rem;
    line-height: 2.625rem;
    margin-bottom: 0.125em;
  }
  ul {
    margin-left: 1rem;
  }
  @media only screen and (min-width: 1150px) {
    display: grid;
    grid-gap: 6rem;
    grid-template-columns: 30% 60%;
    svg {
      width: 100%;
    }
  }
`;

export default Sponsor;
