import * as React from 'react';

function SvgGreenCheck(props) {
  return (
    <svg viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30.7.602c-.357.021-1.444.233-2.184.41-.248.061-.356.014-.24-.058l.849-.533c.116-.134-.098-.19-.332-.128-.355.058-.713.092-1.064.17-.295.067-.132-.078-.095-.127.053-.071.086-.14.035-.238-.124-.237-1.003.016-1.277.123-2.246.87-4.282 2.1-6.099 3.73-2.533 2.273-4.83 4.77-6.758 7.603-2.163 3.179-3.702 6.7-5.129 10.27-.37.924-.64 1.848-1.018 2.836-.086-.16-.15-.267-.2-.38-.888-2.03-1.767-4.064-2.67-6.088-.25-.565-.43-1.173-.854-1.645a1.29 1.29 0 00-.267-.217c-.375-.244-.343-.03-.375.098-.06.245-.534-.295-.587-.318-.089-.039-.3-.048-.283.238.008.122-.078-.012-.486.042-.626.084-.462.564-.74.49-.222-.058-.263.043-.203.254.046.158.097.317.115.49.01.097.1.472-.268.06a1.55 1.55 0 01-.208-.322c-.188-.365-.408-.136-.354.034.085.266.1.61.282 1.124.872 2.449 1.97 4.797 2.967 7.19.702 1.69 1.417 3.375 1.686 5.211.056.383.237.608.55.776 1.03.549 2.538.328 3.43-.464.523-.465.707-1.1.946-1.716 1.26-3.253 2.061-6.787 3.541-9.946a63.88 63.88 0 016.29-10.6c2.785-3.813 6.682-6.412 11.091-8.003.386-.137.167-.38-.093-.364l.001-.002z"
        fill="#64A70B"
      />
    </svg>
  );
}

export default SvgGreenCheck;
