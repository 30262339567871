import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Markup } from 'interweave';
import { breakpoints } from 'styles';
import { hexToRGB } from 'utils';

const EventBreakout = ({ breakouts, colors }) => (
  <Container>
    {breakouts.length > 1 &&
      breakouts.map((breakout) => (
        <Breakout
          key={breakout.url}
          disabled={breakout.url.length < 1}
          whileHover={{ scale: 1.0125 }}
          whileTap={{
            scale: 0.95
          }}
          colors={colors}
          href={breakout.url}
          target="_blank"
          rel="noreferrer">
          <span>{breakout.title}</span>
          <span>
            <Markup content={breakout.text} noWrap />
          </span>
        </Breakout>
      ))}
  </Container>
);

const Container = styled.div`
  display: grid;
  gap: 1rem;
  grid-column: 1/7;
  grid-template-columns: repeat(1, 1fr);
  padding-top: 1rem;
  position: relative;
  width: 100%;
  z-index: 2;
  ${breakpoints.sm} {
    grid-column: 1/12;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 2rem 0 2rem;
  }
  ${breakpoints.lg} {
    grid-column: 2/12;
    padding-top: 2rem;
  }
`;

const Breakout = styled(motion.a)`
  align-items: center;
  border: 2px solid
    ${({ colors, disabled }) =>
      disabled ? hexToRGB({ color: colors.tertiary, alpha: 0.25 }) : colors.tertiary};
  border-radius: 40px;
  color: ${({ colors, disabled }) =>
    disabled ? hexToRGB({ color: colors.tertiary, alpha: 0.25 }) : colors.tertiary};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: 'museo-slab', serif;
  font-size: 1.625rem;
  font-weight: 700;
  justify-content: center;
  line-height: 2rem;
  padding: 1rem;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
  text-align: center;

  > span:first-of-type {
    color: ${({ colors, disabled }) =>
      disabled ? hexToRGB({ color: colors.primary, alpha: 0.25 }) : colors.primary};
  }
`;

export default EventBreakout;
