import React from 'react';
import { default as SadEmoji } from 'assets/svgs/SadEmoji';
import { default as UnhappyEmoji } from 'assets/svgs/UnhappyEmoji';
import { default as NeutralEmoji } from 'assets/svgs/NeutralEmoji';
import { default as HappyEmoji } from 'assets/svgs/HappyEmoji';
import { default as VeryHappyEmoji } from 'assets/svgs/VeryHappyEmoji';

const Emojis = ({
  poll,
  rating,
  handleRating,
  hoveredRating,
  setHoveredRating,
  setErrorMessage,
  errorMessage,
  selectedEvent
}) =>
  (poll.ratingOption.amount === '5' &&
    poll.ratingOption.ratingOptions.map(
      (option, index) =>
        (option.option === 0 && (
          <SadEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        )) ||
        (option.option === 1 && (
          <UnhappyEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        )) ||
        (option.option === 2 && (
          <NeutralEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        )) ||
        (option.option === 3 && (
          <HappyEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        )) ||
        (option.option === 4 && (
          <VeryHappyEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        ))
    )) ||
  (poll.ratingOption.amount === '3' &&
    poll.ratingOption.ratingOptions.map(
      (option, index) =>
        (option.option === 0 && (
          <SadEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        )) ||
        (option.option === 1 && (
          <NeutralEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        )) ||
        (option.option === 2 && (
          <VeryHappyEmoji
            key={option.rid}
            height="24px"
            width="24px"
            stroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            innerStroke={
              index === rating.position
                ? '#4F4F4F'
                : index === hoveredRating.position
                ? selectedEvent?.colors.secondary
                : errorMessage === 'missing-rating'
                ? '#F55151'
                : '#C4C4C4'
            }
            fill={index === rating.position ? '#FED132' : 'none'}
            onClick={(e) => handleRating(e, option, index)}
            onMouseOver={() => {
              setHoveredRating({ position: index, id: option.rid });
              setErrorMessage('');
            }}
            onMouseOut={() => setHoveredRating({ position: -1, id: '' })}
          />
        ))
    ));

export default Emojis;
