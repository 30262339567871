import * as React from 'react';

function SvgThumbsUp(props) {
  return (
    <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.826 16.885c.544-.26 1.372-.643 1.595-1.273.222-.618-.161-1.249-.26-1.854.593-.161 1.273-.569 1.384-1.459.124-.964-.58-1.397-1.31-1.817.16-.136.272-.247.396-.334.593-.445.902-1.026.63-1.755-.26-.692-.84-1.014-1.595-.989-2.076.05-4.165 0-6.242 0 .371-2.386 1.076-4.895-.902-6.836C10.929 0 9.84-.383 9.668.63c-.21 1.323.593 2.905-.013 4.215-.63 1.385-1.384 2.398-2.15 3.709-.384.655-.903 1.236-1.323 1.866a1.278 1.278 0 00-.223.692c-.012 2.25-.012 4.487 0 6.737 0 .21.062.494.21.606.718.544 1.472.989 2.46.976 2.41-.037 4.809.013 7.22 0 1.483.013 3.052-.853 1.977-2.546zM2.435 20A2.432 2.432 0 010 17.565v-5.587a2.432 2.432 0 012.435-2.435 2.432 2.432 0 012.435 2.435v5.587A2.432 2.432 0 012.435 20z"
        fill={props.colors.secondary}
      />
    </svg>
  );
}

export default SvgThumbsUp;
