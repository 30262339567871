import { useStaticQuery, graphql } from 'gatsby';

const useEventData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(src/data/events)/" } }) {
        edges {
          node {
            id
            frontmatter {
              name
              slug
              status
              eid
              colors {
                primary
                secondary
                tertiary
              }
              title
              subtitle
              banner {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
              mobileBanner {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
                }
              }
              breakouts {
                id
                title
                url
                text
              }
              speakers {
                id
                name
                position
                fbLink
                twLink
                linkedinLink
                emailLink
                shortSummary
                summary
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
              }
              agendas {
                day
                title
                slots {
                  orderNumber
                  title
                  speaker
                  speakerImages {
                    imageOrder
                    image {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
              description
              date
              start
              end
              location
              startTime
              endTime
            }
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark;
};

export default useEventData;
