import React from 'react';
import styled from 'styled-components';

const ModalTrigger = ({ buttonRef, trigger, showModal }) => (
  <ModalButton ref={buttonRef} onClick={showModal}>
    {trigger}
  </ModalButton>
);

const ModalButton = styled.div`
  background: transparent;
  cursor: pointer;
`;

export default ModalTrigger;
