import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LocalContext, FirebaseContext } from 'context';
import Autocomplete from 'react-google-autocomplete';
import BaseSelect from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, ErrorMessage, LoadingSpinner, DialogCloseIcon } from 'components';
import {
  capitaliseFirstLetterOfText,
  checkIfNameIncludesOnlyStandardChars,
  constructQueryString,
  includesSurname
} from 'utils';
import {
  fadeInAndOutVariants,
  fadeInAndOutAndAnimateHeightVariants,
  customDropdownMenuStyles
} from 'styles';
import {
  FormContainer,
  FormInput,
  FormTerms,
  FormLabel,
  FormChecks,
  FormCheckbox
} from '../../FormComponents';
import FixRequiredSelect from '../../FixRequiredSelect';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

const numberRangeOptions = [
  {
    value: '0-9',
    label: '0-9'
  },
  {
    value: '10-49',
    label: '10-49'
  },
  {
    value: '50-250',
    label: '50-250'
  },
  {
    value: '250+',
    label: '250+'
  }
];

const businessTypeOptions = [
  {
    value: 'Sole Trader',
    label: 'Sole Trader'
  },
  {
    value: 'Limited/Limited Liability',
    label: 'Limited/Limited Liability'
  },
  {
    value: 'Partnership',
    label: 'Partnership'
  },
  {
    value: 'Public Limited',
    label: 'Public Limited'
  },
  {
    value: 'Not for Profit',
    label: 'Not for Profit'
  }
];

function SignUpForm({
  event,
  formValues,
  setFormValues,
  errorMessage,
  setErrorMessage,
  hideCloseIcon,
  handleInputChange,
  resetForm,
  colors,
  isDialog
}) {
  const {
    theme,
    siteName,
    handleDialog,
    loginOrSignUpDialogRef,
    setShowLoginOrSignUpDialog,
    registrationFormSubmissionStatus,
    setRegistrationFormSubmissionStatus,
    showSignUpForm,
    newUserEmail,
    setNewUserEmail
  } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [nameCheckError, setNameCheckError] = useState(false);
  let isMounted = true;
  let debounce;
  const debounceTimeout = 1200;

  const fieldsRef = useRef(null);
  const nameInputRef = useRef();

  useEffect(() => {
    isMounted = false;
  }, []);

  useEffect(() => {
    if (registrationFormSubmissionStatus.submitted) {
      const newUserEmailFromLocalStorage = JSON.parse(
        window.localStorage.getItem(`newUserEmail-${siteName}`)
      );
      if (newUserEmailFromLocalStorage) {
        setNewUserEmail(newUserEmailFromLocalStorage);
      }
    }
  }, [registrationFormSubmissionStatus]);

  useEffect(() => {
    if (showSignUpForm) {
      nameInputRef?.current?.focus();
    }
  }, [showSignUpForm]);

  useEffect(() => {
    setFormValues((currentStates) => ({
      ...currentStates,
      action: 'registration'
    }));
  }, []);

  useEffect(() => {
    const { name } = formValues;
    if (name) {
      debounce = setTimeout(() => {
        if (!checkIfNameIncludesOnlyStandardChars(name)) {
          setNameCheckError(true);
          setDisableSubmitButton(true);
          setErrorMessage('Please include only standard characters in your name');
        } else if (!includesSurname(name)) {
          setNameCheckError(true);
          setDisableSubmitButton(true);
          setErrorMessage('Please include a surname');
        } else {
          setNameCheckError(false);
          setDisableSubmitButton(false);
        }
        setDisableSubmitButton(false);
      }, debounceTimeout);
    }
    return () => clearTimeout(debounce);
  }, [formValues.name]);

  async function handleSubmit(e) {
    e.preventDefault();

    const {
      name,
      email,
      company,
      companyAddress,
      phoneNumber,
      numberOfEmployees,
      businessType,
      termsAndConditions
    } = formValues;

    if (!name) {
      setErrorMessage('Please include your name');
      return;
    }

    if (!includesSurname(name)) {
      setErrorMessage('Please include a surname');
      return;
    }

    if (!checkIfNameIncludesOnlyStandardChars(name)) {
      setErrorMessage('Please include only standard characters in your name');
      return;
    }

    if (!email) {
      setErrorMessage('Please include your email address');
      return;
    }

    if (!phoneNumber) {
      setErrorMessage('Please include a phone number');
      return;
    }

    if (!company) {
      setErrorMessage('Please include your company name');
      return;
    }

    if (!companyAddress) {
      setErrorMessage('Please include your company address');
      return;
    }

    if (!numberOfEmployees) {
      setErrorMessage('Please include the average number of employees at your company.');
      return;
    }

    if (!businessType) {
      setErrorMessage('Please include your company business type');
      return;
    }

    if (!termsAndConditions) {
      setErrorMessage('You must agree to our terms & conditions to register');
      return;
    }

    try {
      setRegistrationFormSubmissionStatus({
        submitting: true,
        submitted: false
      });

      const userDetailsQueryString = constructQueryString({
        formValues,
        exclude: ['email', 'termsAndConditions']
      });

      // Just a note, we can't use window.location.href anymore as we're now adding
      // /?${userDetailsQueryString} to the url. If we do so and a user visits the site through a
      // utm link, utm parameters like /?utm_source will be included in the url before the
      // userDetailsQueryString parameters and they will be lost within the url meaning parseUrl
      // will no longer work as it's intended to in auth.jsx

      const continueUrl = `${window.location.origin}?${userDetailsQueryString}`;

      const actionCodeSettings = {
        url: continueUrl,
        handleCodeInApp: false
      };

      const firstName = name.split(' ')[0];

      const { data } = await firebase.emails.sendVerificationEmail({
        firstName: capitaliseFirstLetterOfText(firstName),
        email,
        colors,
        actionCodeSettings,
        origin: window.location.origin,
        pathname: window.location.pathname
      });

      if (data.Message === 'OK') {
        setRegistrationFormSubmissionStatus({
          submitting: false,
          submitted: true
        });
        window.localStorage.setItem(`newUserEmail-${siteName}`, JSON.stringify(email.trim()));
      } else {
        setRegistrationFormSubmissionStatus({
          submitting: false,
          submitted: false
        });
        setErrorMessage('Error sending email. Please try again');
      }

      setFormValues(resetForm({ siteName }));
    } catch (error) {
      console.error(error);

      if (isMounted) {
        setErrorMessage(error.message);
      }

      setRegistrationFormSubmissionStatus({
        submitting: false,
        submitted: false
      });
    }
  }

  const handleWorkplaceAutocompleteSelection = (res) => {
    const { name } = res;
    const address = name.split(',');
    address.shift();

    if (errorMessage) {
      setErrorMessage('');
    }

    setFormValues((currentStates) => ({
      ...currentStates,
      company: name.split(',')[0],
      companyAddress: address.join(',').trim()
    }));
  };

  const handleWorkplaceAddressAutocompleteSelection = (res) => {
    const { name } = res;
    if (errorMessage) {
      setErrorMessage('');
    }
    setFormValues((currentStates) => ({
      ...currentStates,
      companyAddress: name
    }));
  };

  return (
    <FormContainer
      $theme={theme}
      onSubmit={(e) => handleSubmit(e)}
      colors={colors}
      initial={false}
      animate={{ rotateY: showSignUpForm ? 0 : 180 }}
      transition={{
        rotateY: {
          type: 'spring',
          duration: 2,
          stiffness: 60,
          damping: 8,
          mass: 1
        }
      }}
      style={{
        backfaceVisibility: 'hidden',
        display: showSignUpForm ? 'block' : 'none', // When <LoginForm /> is open and displayed in a dialog, this css rule allows a user to close the dialog by clicking above/below <LoginForm />. Without it their click would land on the backface of <SignUpForm />, preventing the dialog from closing.
        position: (isDialog && showSignUpForm) || !isDialog ? 'absolute' : 'relative'
      }}>
      {!hideCloseIcon && (
        <DialogCloseIcon
          onClick={() =>
            handleDialog({
              dialogRef: loginOrSignUpDialogRef,
              animation: 'dismiss',
              stateHandler: () => setShowLoginOrSignUpDialog(false)
            })
          }
          width="0.875em"
        />
      )}
      <FormLabel>Sign Up</FormLabel>
      <Fields
        ref={fieldsRef}
        variants={fadeInAndOutVariants()}
        initial="initial"
        animate="animate"
        exit="exit">
        <FormInput
          theme={theme}
          id="name"
          name="name"
          onChange={handleInputChange}
          placeholder="Full Name*"
          type="text"
          value={formValues.name}
          required
          style={{ marginBottom: 0 }}
          ref={nameInputRef}
          error={nameCheckError === true}
        />
        <FormWorkplaceAutoComplete
          id="company"
          name="company"
          theme={theme}
          apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
          options={{
            types: ['establishment'],
            componentRestrictions: { country: 'ie' },
            fields: ['name']
          }}
          onChange={handleInputChange}
          onPlaceSelected={handleWorkplaceAutocompleteSelection}
          placeholder="Company Name*"
          type="select"
          required
          value={formValues.company}
        />
        <FormWorkplaceAutoComplete
          id="companyAddress"
          name="companyAddress"
          theme={theme}
          apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
          options={{
            types: ['address'],
            componentRestrictions: { country: 'ie' },
            fields: ['name']
          }}
          onChange={handleInputChange}
          onPlaceSelected={handleWorkplaceAddressAutocompleteSelection}
          placeholder="Company Address*"
          type="select"
          required
          value={formValues.companyAddress}
        />
        <FormSelect
          id="businessType"
          name="businessType"
          required
          defaultValue={formValues.businessType}
          value={businessTypeOptions.filter(({ value }) => value === formValues.businessType)}
          controlShouldRenderValue
          onChange={({ value }) => {
            setFormValues((currentStates) => ({
              ...currentStates,
              businessType: value
            }));
          }}
          placeholder="Business Type*"
          styles={customDropdownMenuStyles({ colors, theme })}
          options={businessTypeOptions}
        />
        <FormInput
          id="phoneNumber"
          name="phoneNumber"
          theme={theme}
          onChange={handleInputChange}
          placeholder="Phone Number*"
          type="tel"
          value={formValues.phoneNumber}
          required
          style={{ marginBottom: 0 }}
        />
        <FormSelect
          id="numberOfEmployees"
          name="numberOfEmployees"
          required
          defaultValue={formValues.numberOfEmployees}
          value={numberRangeOptions.filter(({ value }) => value === formValues.numberOfEmployees)}
          controlShouldRenderValue
          onChange={({ value }) => {
            setFormValues((currentStates) => ({
              ...currentStates,
              numberOfEmployees: value
            }));
          }}
          placeholder="Average Number of Employees*"
          styles={customDropdownMenuStyles({ colors, theme })}
          options={numberRangeOptions}
        />
      </Fields>
      <FormChecks>
        <FormLabel hide htmlFor="termsAndConditions" style={{ display: 'inline-block' }}>
          Terms And Conditions
        </FormLabel>
        <FormCheckbox
          checked={formValues.termsAndConditions}
          id="termsAndConditions"
          name="termsAndConditions"
          onChange={handleInputChange}
          required
          $theme={theme}
          type="checkbox"
        />
        <FormTerms theme={theme}>
          {event?.termsAndConditions || (
            <p>
              This event is reserved exclusively for MentorsWork.
              <br />
              By ticking this box, I confirm my registration for this event.
            </p>
          )}
        </FormTerms>
      </FormChecks>
      <ErrorMessage
        errorMessage={errorMessage}
        style={{ color: '#ff2c2c', marginTop: '1rem' }}
        variants={fadeInAndOutAndAnimateHeightVariants()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1.25rem'
        }}>
        {registrationFormSubmissionStatus.submitted && newUserEmail ? (
          <div
            style={{
              textAlign: 'center',
              marginBottom: '0.5rem',
              color: theme.className === 'contrast' ? theme.primary : theme.tertiary,
              width: '90%'
            }}>
            Check your inbox to verify your <b>{newUserEmail}</b> email address and complete your
            registration. Can&apos;t find our email? Be sure to check your junk!
          </div>
        ) : (
          <Button
            type="submit"
            width="16rem"
            whileTap={{ scale: 0.95 }}
            disabled={disableSubmitButton || errorMessage}
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              cursor: disableSubmitButton ? 'progress' : errorMessage ? 'not-allowed' : 'pointer',
              display: 'flex',
              justifyContent: 'center',
              position: 'relative'
            }}>
            <AnimatePresence>
              {registrationFormSubmissionStatus.submitting && (
                <LoadingSpinner style={{ width: '2rem', color: '#FFFFFF', position: 'absolute' }} />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {!registrationFormSubmissionStatus.submitting && (
                <motion.span
                  variants={fadeInAndOutVariants()}
                  initial="initial"
                  animate="animate"
                  exit="exit">
                  Create an account
                </motion.span>
              )}
            </AnimatePresence>
          </Button>
        )}
      </div>
    </FormContainer>
  );
}

const Fields = styled.div`
  display: grid;
  grid-row-gap: 1.625rem;
  grid-template-columns: 1fr;
  margin-bottom: 1.625rem;
`;

const FormWorkplaceAutoComplete = styled(Autocomplete)`
  align-items: center;
  background: transparent;
  border: 0.063rem solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  display: flex;
  font-family: 'droid-sans', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 3.75rem;
  letter-spacing: 0.031rem;
  outline: none;
  padding: 0 1.25rem;
  ::placeholder {
    color: ${({ theme }) => theme.secondary};
    font-family: 'droid-sans', sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
  }
`;

export default SignUpForm;
