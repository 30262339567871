import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { breakpoints, H3, P, H4 } from 'styles';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { LoadingSpinner } from 'components';
import {
  Cross,
  Check,
  Settings,
  Essentials,
  Analytics,
  Block,
  Plus
} from '../../../../assets/svgs';

const ChangeSettingsBanner = ({ setShowChangeSettingsModal, setShowCookieBarModal }) => {
  const { theme } = useContext(LocalContext);
  const location = useLocation();
  initializeAndTrack(location);
  const [blockAllCookies, setBlockAllCookies] = useState(false);
  const [selectedCookies, setSelectedCookies] = useState('essentials');
  const [submitting, setSubmitting] = useState(false);

  const essentialsWillList = [
    'Essential: Remember your cookie permission setting',
    'Essential: Allow session cookies',
    'Essential: Gather information you input into a contact forms, newsletter and other forms across all pages',
    'Essential: Authenticate that you are logged into your user account',
    'Essential: Remember the language version you selected'
  ];

  const functionalityWillList = [
    'Essential: Remember your cookie permission setting',
    'Essential: Allow session cookies',
    'Essential: Gather information you input into a contact forms, newsletter and other forms across all pages',
    'Essential: Authenticate that you are logged into your user account',
    'Essential: Remember the language version you selected',
    'Functionality: Remember social media settings',
    'Functionality: Remember selected region and country'
  ];

  const analyticsWillList = [
    'Essential: Remember your cookie permission setting',
    'Essential: Allow session cookies',
    'Essential: Gather information you input into a contact forms, newsletter and other forms across all pages',
    'Essential: Authenticate that you are logged into your user account',
    'Essential: Remember the language version you selected',
    'Functionality: Remember social media settings',
    'Functionality: Remember selected region and country',
    'Analytics: Keep track of your visited pages and interaction taken',
    'Analytics: Keep track about your location and region based on your IP number',
    'Analytics: Keep track of the time spent on each page',
    'Analytics: Increase the data quality of the statistics functions'
  ];

  const essentialsWontList = [
    'Remember your login details',
    'Functionality: Remember social media settings',
    'Functionality: Remember selected region and country',
    'Analytics: Keep track of your visited pages and interaction taken',
    'Analytics: Keep track about your location and region based on your IP number',
    'Analytics: Keep track of the time spent on each page',
    'Analytics: Increase the data quality of the statistics functions',
    'Advertising: Tailor information and advertising to your interests based on e.g. the content you have visited before. (Currently we do not use targeting or targeting cookies.',
    'Advertising: Gather personally identifiable information such as name and location'
  ];

  const functionalityWontList = [
    'Remember your login details',
    'Analytics: Keep track of your visited pages and interaction taken',
    'Analytics: Keep track about your location and region based on your IP number',
    'Analytics: Keep track of the time spent on each page',
    'Analytics: Increase the data quality of the statistics functions',
    'Advertising: Tailor information and advertising to your interests based on e.g. the content you have visited before. (Currently we do not use targeting or targeting cookies.',
    'Advertising: Gather personally identifiable information such as name and location'
  ];

  const analyticsWontList = [
    'Remember your login details',
    'Advertising: Use information for tailored advertising with third parties',
    'Advertising: Allow you to connect to social sites',
    'Advertising: Identify device you are using',
    'Advertising: Gather personally identifiable information such as name and location'
  ];

  const handleSaveSettings = (event) => {
    event.preventDefault();
    setSubmitting(true);
    if (selectedCookies !== 'analytics') {
      document.cookie = 'gatsby-gdpr-google-analytics=false';
      window.localStorage.setItem('googleAnalyticsAccepted', false);
    }
    if (selectedCookies === 'analytics') {
      document.cookie = 'gatsby-gdpr-google-analytics=true';
      window.localStorage.setItem('googleAnalyticsAccepted', true);
    }
    setSubmitting(false);
    setShowChangeSettingsModal(false);
    setShowCookieBarModal(false);
  };

  const handleRadioButtonChange = (event) => {
    if (event.target.value === 'block all') {
      setBlockAllCookies(true);
      setSelectedCookies(event.target.value);
    }
    if (event.target.value === 'essentials') {
      setBlockAllCookies(false);
      setSelectedCookies(event.target.value);
    }
    if (event.target.value === 'functionality') {
      setBlockAllCookies(false);
      setSelectedCookies(event.target.value);
    }
    if (event.target.value === 'analytics') {
      setBlockAllCookies(false);
      setSelectedCookies(event.target.value);
    }
  };
  return (
    <Container $theme={theme}>
      <ExitButton type="button" onClick={() => setShowChangeSettingsModal(false)}>
        <CloseIcon $theme={theme} viewBox="0 0 40 40">
          <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
        </CloseIcon>
      </ExitButton>
      <H3>Privacy settings</H3>
      <P>
        Decide which cookies you want to allow. You can change these settings at any time.
        <br />
        However, this can result in some functions no longer being available. For information on
        deleting the cookies, please consult your browser’s help function.
        <br />
        Learn more about the cookies we use.
      </P>
      <H4>With the slider, you can enable or disable different types of cookies:</H4>
      <form style={{ position: 'relative', width: '100%' }}>
        <Slider $blockAll={blockAllCookies} $selectedCookies={selectedCookies} />
        <Options>
          <Option>
            <label htmlFor="cookie0">
              <div>
                <Block fill={selectedCookies === 'block all' ? '#82aa3b' : ''} />
              </div>
              <span>Block all</span>
            </label>
            <input
              type="radio"
              value="block all"
              id="cookie0"
              checked={selectedCookies === 'block all'}
              onChange={handleRadioButtonChange}
            />
          </Option>
          <Option>
            <label htmlFor="cookie1">
              <div>
                <Essentials
                  fill={
                    selectedCookies === 'essentials' ||
                    selectedCookies === 'functionality' ||
                    selectedCookies === 'analytics'
                      ? '#82aa3b'
                      : ''
                  }
                />
              </div>
              <span>Essentials</span>
            </label>
            <input
              type="radio"
              value="essentials"
              id="cookie1"
              onChange={handleRadioButtonChange}
              checked={selectedCookies === 'essentials'}
            />
          </Option>
          <Option>
            {(selectedCookies === 'functionality' || selectedCookies === 'analytics') && <Plus />}
            <label htmlFor="cookie2">
              <div>
                <Settings
                  fill={
                    selectedCookies === 'functionality' || selectedCookies === 'analytics'
                      ? '#82aa3b'
                      : ''
                  }
                />
              </div>
              <span>Functionality</span>
            </label>
            <input
              type="radio"
              value="functionality"
              id="cookie2"
              checked={selectedCookies === 'functionality'}
              onChange={handleRadioButtonChange}
            />
          </Option>
          <Option>
            {selectedCookies === 'analytics' && <Plus />}
            <label htmlFor="cookie3">
              <div>
                <Analytics fill={selectedCookies === 'analytics' ? '#82aa3b' : ''} />
              </div>
              <span>Analytics</span>
            </label>
            <input
              type="radio"
              value="analytics"
              id="cookie3"
              checked={selectedCookies === 'analytics'}
              onChange={handleRadioButtonChange}
            />
          </Option>
        </Options>
      </form>
      {!blockAllCookies && (
        <CookieSelectionInfo>
          <CookieSelectionList>
            <H4>MentorsWork Live website will:</H4>
            {selectedCookies === 'essentials' &&
              essentialsWillList.map((line) => (
                <P>
                  <Check fill="#71db80" />
                  {line}
                </P>
              ))}
            {selectedCookies === 'functionality' &&
              functionalityWillList.map((line) => (
                <P>
                  <Check fill="#71db80" />
                  {line}
                </P>
              ))}
            {selectedCookies === 'analytics' &&
              analyticsWillList.map((line) => (
                <P>
                  <Check fill="#71db80" />
                  {line}
                </P>
              ))}
          </CookieSelectionList>
          <CookieSelectionList>
            <H4>MentorsWork Live website won&apos;t:</H4>
            {selectedCookies === 'essentials' &&
              essentialsWontList.map((line) => (
                <P>
                  <Cross fill="#ffc18b" />
                  {line}
                </P>
              ))}
            {selectedCookies === 'functionality' &&
              functionalityWontList.map((line) => (
                <P>
                  <Cross fill="#ffc18b" />
                  {line}
                </P>
              ))}
            {selectedCookies === 'analytics' &&
              analyticsWontList.map((line) => (
                <P>
                  <Cross fill="#ffc18b" />
                  {line}
                </P>
              ))}
          </CookieSelectionList>
        </CookieSelectionInfo>
      )}
      <SubmitButton type="button" onClick={handleSaveSettings}>
        {submitting ? (
          <LoadingSpinner style={{ color: '#fff', width: '0.5rem' }} />
        ) : (
          'Save & Close'
        )}
      </SubmitButton>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 970px;
  padding: 3rem 1rem 1rem 1rem;
  position: relative;
  width: 100%;
  ${H3} {
    color: #595959;
    font-size: 1.5rem;
  }
  ${P} {
    color: #797979;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin: 0 auto;
    max-width: 620px;
    text-align: center;
    text-transform: none;
  }
  ${H4} {
    color: #595959;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 2.5rem;
    margin-top: 1.25rem;
    text-align: left;
  }
  ${breakpoints.lg} {
    min-width: 970px;
    padding: 3rem;
    ${H3} {
      font-size: 1.875rem;
    }
    ${P} {
      font-size: ;
    }
    ${H4} {
      font-size: 1.25rem;
      text-align: center;
    }
  }
`;

const Slider = styled.div`
  background-color: transparent;
  border: ${({ $blockAll }) => (!$blockAll ? '3px solid #82aa3b' : 'none')};
  border-radius: 70px;
  display: block;
  height: 60px;
  margin-left: ${({ $blockAll }) => ($blockAll ? '0%' : '25%')};
  position: absolute;
  top: -10px;
  transition: all 0.3s;
  width: ${({ $selectedCookies }) =>
    $selectedCookies === 'essentials'
      ? '25%'
      : $selectedCookies === 'functionality'
      ? '50%'
      : $selectedCookies === 'analytics'
      ? '75%'
      : '25%'};
  ${breakpoints.lg} {
    height: 100px;
  }
`;

const Options = styled.div`
  display: flex;
  width: 100%;
`;

const Option = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  width: 100%;
  label {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  span {
    color: #595959;
    font-size: 13px;
    line-height: 20px;
    width: 100%;
    margin: 20px auto 5px auto;
    text-align: center;
  }
  svg {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 15px;
    path {
      stroke: #797979;
      stroke-width: 1px;
      opacity: 0.2;
    }
  }
  div {
    background: #fafafa;
    border-radius: 100%;
    height: 40px;
    margin: 0 0;
    padding-top: 5px;
    position: relative;
    width: 40px;
    svg {
      height: 20px;
      left: 25%;
      position: absolute;
      top: 25%;
      width: 20px;
      path {
        stroke: none;
        opacity: 1;
      }
    }
  }

  input[type='radio'] {
    visibility: hidden;
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: 'white';
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  input[type='radio']:checked:before {
    background-color: white;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s ease;
  }
  input[type='radio']:checked:after {
    width: 6px;
    height: 6px;
    border-radius: 15px;
    top: -22px;
    left: 3.5px;
    position: relative;
    background-color: #82aa3b;
    content: '';
    display: inline-block;
    visibility: visible;
    border: none;
    transition: background-color 0.2s ease;
  }
  ${breakpoints.sm} {
    svg {
      width: 15px;
      height: 15px;
    }
  }
  ${breakpoints.lg} {
    svg {
      width: 20px;
      height: 20px;
      top: 30px;
    }
    div {
      height: 80px;
      width: 80px;
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
`;

const CookieSelectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  div {
    flex-grow: 1;
  }
  ${breakpoints.lg} {
    flex-direction: row;
  }
`;

const CookieSelectionList = styled.div`
  display: flex;
  flex-direction: column;
  ${H4} {
    color: #595959;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  ${P} {
    align-items: center;
    display: flex;
    font-size: 13px;
    line-height: 30px;
    margin: 0;
    text-align: left;
    svg {
      height: 10px;
      margin-right: 1rem;
      min-height: 10px;
      min-width: 13px;
      width: 13px;
      path {
        stroke: none;
      }
    }
  }
`;

const SubmitButton = styled.button`
  background: #3170ce;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 14px 21px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background: transparent;
    border: 1px solid #3170ce;
    color: #3170ce;
    transition: all 200ms ease-in-out;
  }
`;

const ExitButton = styled.button`
  background: transparent;
  position: absolute;
  right: 25px;
  top: 25px;
`;

const CloseIcon = styled.svg`
  height: 1rem;
  stroke-linecap: round;
  stroke-width: 3;
  width: 1rem;
  path {
    stroke: #595959;
  }
  ${breakpoints.lg} {
    height: 2rem;
    width: 2rem;
  }
`;

const PlusWrapper = styled.div`
  left: 5px;
  position: absolute;
  svg {
    height: 20px;
    width: 20px;
    path {
      stroke: black;
    }
  }
`;

export default ChangeSettingsBanner;
