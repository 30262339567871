import React from 'react';

function SVGFileDownload(props) {
  return (
    <svg viewBox="0 0 270 376" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147 0H48C21.4903 0 0 21.4903 0 48V328C0 354.51 21.4903 376 48 376H221C247.51 376 269 354.51 269 328V106.153H195C168.49 106.153 147 84.6627 147 58.153V0Z"
        fill={props.fill}
        fillOpacity="0.6"
      />
      <path
        d="M193.824 196.95L200.788 203.936C203.737 206.894 203.737 211.678 200.788 214.604L139.833 275.781C136.884 278.74 132.116 278.74 129.198 275.781L68.2117 214.604C65.2628 211.646 65.2628 206.863 68.2117 203.936L75.1762 196.95C78.1565 193.96 83.0191 194.023 85.9367 197.076L121.951 234.997V144.553C121.951 140.367 125.308 137 129.481 137H139.519C143.692 137 147.049 140.367 147.049 144.553V234.997L183.063 197.076C185.981 193.992 190.843 193.929 193.824 196.95Z"
        fill={props.fill}
      />
      <path
        d="M147 0.435184L269.374 104.978L269.378 106.064L195.378 106.328C168.869 106.422 147.302 85.0088 147.207 58.4993L147 0.435184Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SVGFileDownload;