import styled from 'styled-components';
import { hexToRGB } from 'utils';

export const breakpoints = {
  xs: '@media (min-width: 480px)',
  sm: '@media (min-width: 520px)',
  md: '@media (min-width: 992px)',
  lg: '@media (min-width: 1150px)',
  xl: '@media (min-width: 1441px)'
};

export const defaultColors = {
  primary: '#002a3a',
  secondary: '#002a3a',
  tertiary: '#64a70b'
};

export const Grid = styled.div`
  align-items: start;
  display: grid;
  grid-column: 1/7;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  ${breakpoints.sm} {
    grid-column: 1/12;
    grid-template-columns: repeat(10, 1fr);
  }
  ${breakpoints.lg} {
    grid-column: 1/13;
    grid-column-gap: 0rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const H1 = styled.h1`
  color: inherit;
  font-family: 'museo-slab', serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-left: -3px;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 3rem;
    margin-bottom: 1em;
  }
`;

export const H2 = styled.h2`
  color: inherit;
  font-family: 'museo-slab', serif;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 2.75rem;
    margin-bottom: 1em;
  }
`;

export const H3 = styled.h3`
  color: inherit;
  font-family: 'museo-slab', serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 2.25rem;
    margin-bottom: 1em;
  }
`;

export const H4 = styled.h4`
  color: inherit;
  font-family: 'museo-slab', serif;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 2rem;
    margin-bottom: 1em;
  }
`;

export const H5 = styled.h5`
  color: inherit;
  font-family: 'museo-slab', serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
`;

export const H6 = styled.h6`
  color: inherit;
  font-family: 'museo-slab', serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    font-size: 1.25rem;
    margin-bottom: 1em;
  }
`;

export const P = styled.p`
  color: inherit;
  font-family: 'museo-slab', serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  ${breakpoints.lg} {
    margin-bottom: 1em;
  }
`;

export const CardDeck = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-row-gap: 1.5rem;
  grid-template-columns: 1fr;
  position: relative;
  width: 90vw;
  ${breakpoints.sm} {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-row-gap: 3rem;
    grid-template-columns: repeat(8, 1fr);
    justify-content: center;
    width: 95vw;
    padding: 0 1rem;
    div {
      grid-column: span 4;
      /* &:last-child {
        grid-column: span 8;
      } */
    }
  }
  ${breakpoints.lg} {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    padding: 0 3rem;
    width: 100vw;
    div {
      grid-column: span 3;
      /* &:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }

      &:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      &:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      } */
      &:nth-child(n + 4) {
        grid-column: span 3;
      }
    }
  }
`;

export const container = () =>
  ` padding: 0px 1.25rem;
    grid-column: 1/7;
   ${breakpoints.sm}{
      grid-column: 2/6;
    }
    ${breakpoints.lg}{
        grid-column: 2/12;
    }
    ${breakpoints.xl}{
      grid-column: 3/11;
    }
    `;

export const gridContainer = () =>
  `
    padding: 0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
   ${breakpoints.sm}{
      grid-column: 2/6;
    }
    ${breakpoints.lg}{
        grid-column: 2/12;
        grid-template-columns: repeat(10, 1fr);
        grid-gap: 1.25rem;
    }
    ${breakpoints.xl}{
      grid-column: 3/11;
    }
    `;

export const fullGridContainer = () =>
  `
    padding:0px 1.25rem;
    grid-column: 1/7;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap:.625rem;
    ${breakpoints.lg}{
        grid-column: 1/13;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 1.25rem;
    }
    `;

export const flexContainer = () =>
  `
    grid-column: 1/7;
    display: flex;
   ${breakpoints.sm}{
      grid-column: 2/6;
    }
    ${breakpoints.lg}{
        grid-column: 2/12;
    }
    ${breakpoints.xl}{
      grid-column: 3/11;
    }
    `;

export const noPadding = () => `
    padding:0px;`;

export const fullWidth = () => `
grid-column: 1/7;
@media (min-width: 768px){
  grid-column: 2/6;
}
${breakpoints.lg}{
  grid-column: 2/12;
}
${breakpoints.xl}{
  grid-column: 3/11;
}
`;

export const fadeInAndOutVariants = ({ animateDelay = 0, exitDelay = 0 } = {}) => ({
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: animateDelay
    }
  },
  exit: {
    opacity: 0,
    transition: {
      delay: exitDelay
    }
  }
});

export const fadeInAndOutAndAnimateHeightVariants = () => ({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      opacity: {
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      height: {
        delay: 0.1
      }
    }
  }
});

export const customDropdownMenuStyles = () => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused ? '0.188em solid #002A3A' : '0.063em solid #002A3A',
    display: 'flex',
    fontFamily: "'droid-sans', sans-serif",
    fontSize: '1rem',
    fontWeight: 'bold',
    height: '3.75rem',
    letterSpacing: '0.031rem',
    padding: '0'
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: '0 0 0 1.25rem',
    cursor: 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#002A3A',
    '&:hover': {
      color: '#002A3A'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: '#002A3A'
  }),
  menu: (provided) => ({
    ...provided,
    background: '#002A3A',
    border: '0.214em solid #002A3A',
    borderRadius: '0',
    fontFamily: "'droid-sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.214em',
    letterSpacing: '0.071em',
    margin: '0',
    width: 'calc(100% + 0.429em)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: '#002A3A'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? hexToRGB({ color: '#64a70b', alpha: 1 }) : 'transparent',
    paddingLeft: '1.25rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#64a70b'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#002A3A'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#002A3A'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0'
  })
});

export default {
  container,
  gridContainer,
  fullGridContainer,
  flexContainer,
  noPadding,
  fullWidth,
  breakpoints
};
