import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  CreateOrEditPollModal,
  QueuedAndClosedPolls,
  GeneralAnalyticsModal,
  FeedbackAnalyticsModal,
  WordCloudAnalyticsModal,
  ConfirmDelete,
  Dialog
} from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { hexToRGB } from 'utils';
import { Plus } from 'assets/svgs';
import { fadeInAndOutVariants } from 'styles';

const ControlPanel = () => {
  const {
    selectedEvent,
    confirmDeletePollOrQuestionDialogRef,
    showConfirmDeleteDialog,
    setShowConfirmDeleteDialog,
    handleDialog
  } = useContext(LocalContext);
  const { eid, colors } = selectedEvent;
  const { firebase } = useContext(FirebaseContext);
  const [pollBeingCurrentlyEditedByModerator, setPollBeingCurrentlyEditedByModerator] =
    useState(null);
  const [isCreateOrEditPollModalOpen, setIsCreateOrEditPollModalOpen] = useState(false);
  const [queuedPolls, setPendingPolls] = useState([]);
  const [closedPolls, setClosedPolls] = useState([]);
  const [allPolls, setAllPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [currentlyOpenPoll, setCurrentlyOpenPoll] = useState(null);
  const [pollToBeDeleted, setPollToBeDeleted] = useState(null);
  const [isAnalyticsModalOpen, setIsAnalyticsModalOpen] = useState(false);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [newPollType, setNewPollType] = useState(null);
  const [
    pollAnalyticsBeingCurrentlyViewedByModerator,
    setPollAnalyticsBeingCurrentlyViewedByModerator
  ] = useState(null);
  const [
    pollAnalyticsBeingCurrentlySharedWithUsers,
    setPollAnalyticsBeingCurrentlySharedWithUsers
  ] = useState(null);

  useEffect(() => {
    let unsubscribeFromPolls;

    if (firebase) {
      unsubscribeFromPolls = firebase.interaction.polls.subscribeToPolls({
        eid,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const _queuedPolls = [];
            const _closedPolls = [];
            const _allPolls = [];

            snapshot.forEach((child) => {
              _allPolls.push({
                pid: child.key,
                ...child.val()
              });
            });

            const _currentlyOpenPoll = _allPolls.find((poll) => poll.isOpen);

            const _pollAnalyticsBeingCurrentlySharedWithUsers = _allPolls.find(
              (poll) => poll.shareResults
            );

            if (_currentlyOpenPoll) {
              setCurrentlyOpenPoll(_currentlyOpenPoll);
            }

            if (_pollAnalyticsBeingCurrentlySharedWithUsers) {
              setPollAnalyticsBeingCurrentlySharedWithUsers(
                _pollAnalyticsBeingCurrentlySharedWithUsers
              );
            } else {
              setPollAnalyticsBeingCurrentlySharedWithUsers(null);
            }

            _allPolls.forEach((poll) => {
              if (poll.isQueued) {
                _queuedPolls.push(poll);
              } else {
                _closedPolls.push(poll);
              }
            });

            setPendingPolls(_queuedPolls);
            setClosedPolls(_closedPolls);
            setAllPolls(_allPolls);
          } else if (!snapshot.exists()) {
            setPendingPolls([]);
            setClosedPolls([]);
            setAllPolls([]);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPolls) {
        unsubscribeFromPolls();
      }
    };
  }, [firebase]);

  useEffect(() => {
    if (allPolls.length) {
      allPolls.forEach((poll) => {
        if (selectedPoll?.pid === poll.pid) {
          // Whenever the 'subscribeToPolls' snapshot is fired we loop through all the polls and
          // rehydrate the currently selected poll (if there is one) with any new properties
          // e.g. if it's been changed from a queued to a closed poll.
          setSelectedPoll(poll);
        }
      });
    }
  }, [allPolls]);

  const handlePoll = (action) => {
    switch (action) {
      case 'openPoll':
        firebase.interaction.polls.openPoll({ eid, poll: selectedPoll, currentlyOpenPoll });
        firebase.interaction.forceActiveTab({ eid, tabName: 'Polls' });
        break;
      case 'closePoll':
        firebase.interaction.polls.closePoll({ eid, poll: selectedPoll });
        firebase.interaction.forceActiveTab({ eid, tabName: null });
        setCurrentlyOpenPoll(null);
        break;
      case 'sharePollAnalytics':
        firebase.interaction.polls.sharePollAnalytics({ eid, poll: selectedPoll });
        firebase.interaction.forceActiveTab({ eid, tabName: 'Polls' });
        break;
      case 'stopSharingPollAnalytics':
        firebase.interaction.polls.stopSharingPollAnalytics({ eid, poll: selectedPoll });
        firebase.interaction.forceActiveTab({ eid, tabName: null });
        break;
      default:
        break;
    }
  };

  const handleDeletePoll = (e) => {
    e.stopPropagation();
    if (firebase) {
      if (pollToBeDeleted?.pollId === selectedPoll?.pollId) {
        setSelectedPoll(null);
      }
      if (pollToBeDeleted?.pollId === currentlyOpenPoll?.pollId) {
        firebase.interaction.forceActiveTab({ eid: selectedEvent.eid, tabName: null });
        setCurrentlyOpenPoll(null);
      }
      firebase.interaction.polls
        .deletePoll({ eid: selectedEvent.eid, poll: pollToBeDeleted })
        .then(() =>
          handleDialog({
            dialogRef: confirmDeletePollOrQuestionDialogRef,
            animation: 'close',
            stateHandler: () => setShowConfirmDeleteDialog(false),
            callback: () => setPollToBeDeleted(null)
          })
        );
    }
  };

  const handleDismissDeletePoll = (e) => {
    e.stopPropagation();
    handleDialog({
      dialogRef: confirmDeletePollOrQuestionDialogRef,
      animation: 'dismiss',
      stateHandler: () => setShowConfirmDeleteDialog(false),
      callback: () => setPollToBeDeleted(null)
    });
  };

  return (
    <>
      <p>Moderator&apos;s Control Panel</p>
      <motion.div
        style={{
          alignSelf: 'center',
          height: '2.688rem'
        }}
        onClick={() => setIsDropdownMenuOpen(true)}
        onMouseLeave={() => setIsDropdownMenuOpen(false)}
        onTap={() => isMobile && setIsDropdownMenuOpen((currentValue) => !currentValue)}>
        <CreatePoll
          animate={{ backgroundColor: isDropdownMenuOpen ? colors.tertiary : colors.tertiary }}
          transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}>
          <Plus width="0.625rem" />
          &nbsp;&nbsp;Create Poll
          <AnimatePresence>
            {isDropdownMenuOpen && (
              <PollDropdown
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  collapsed: { opacity: 0, rotateX: -90 },
                  open: { opacity: 1, rotateX: 0 }
                }}
                transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}>
                <ul>
                  <li>
                    <motion.button
                      whileHover={{
                        color: `${colors.tertiary}`
                      }}
                      whileTap={{
                        scale: 0.95
                      }}
                      type="button"
                      onClick={() => {
                        setNewPollType('general poll');
                        setIsCreateOrEditPollModalOpen(true);
                      }}>
                      General Poll
                    </motion.button>
                  </li>
                  <li>
                    <motion.button
                      whileHover={{
                        color: `${colors.tertiary}`
                      }}
                      whileTap={{
                        scale: 0.95
                      }}
                      type="button"
                      onClick={() => {
                        setNewPollType('feedback poll');
                        setIsCreateOrEditPollModalOpen(true);
                      }}>
                      Feedback Poll
                    </motion.button>
                  </li>
                  {/* <li>
                    <motion.button
                      whileHover={{
                        color: `${colors.tertiary}`
                      }}
                      whileTap={{
                        scale: 0.95
                      }}
                      type="button"
                      onClick={() => {
                        setNewPollType('word cloud');
                        setIsCreateOrEditPollModalOpen(true);
                      }}>
                      Word Cloud
                    </motion.button>
                  </li> */}
                </ul>
              </PollDropdown>
            )}
          </AnimatePresence>
        </CreatePoll>
      </motion.div>
      <QueuedAndClosedPolls
        eid={eid}
        colors={colors}
        queuedPolls={queuedPolls}
        closedPolls={closedPolls}
        selectedPoll={selectedPoll}
        setSelectedPoll={setSelectedPoll}
        currentlyOpenPoll={currentlyOpenPoll}
        setCurrentlyOpenPoll={setCurrentlyOpenPoll}
        setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
        setPollBeingCurrentlyEditedByModerator={setPollBeingCurrentlyEditedByModerator}
        setPollAnalyticsBeingCurrentlyViewedByModerator={
          setPollAnalyticsBeingCurrentlyViewedByModerator
        }
        setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
        setPollToBeDeleted={setPollToBeDeleted}
        setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
      />
      <AnimatePresence>
        {(queuedPolls.length || closedPolls.length) && (
          <Buttons colors={colors}>
            <AnimatePresence>
              {queuedPolls.length && (!selectedPoll || (selectedPoll && selectedPoll.isQueued)) && (
                <Button
                  onClick={() =>
                    selectedPoll?.pid === currentlyOpenPoll?.pid
                      ? handlePoll('closePoll')
                      : handlePoll('openPoll')
                  }
                  style={{
                    color:
                      selectedPoll &&
                      (selectedPoll.pid === currentlyOpenPoll?.pid ||
                        selectedPoll.pid !== currentlyOpenPoll?.pid)
                        ? '#fff'
                        : '#c4c4c4',
                    backgroundColor:
                      selectedPoll && selectedPoll.pid === currentlyOpenPoll?.pid
                        ? colors.secondary
                        : selectedPoll && selectedPoll.pid !== currentlyOpenPoll?.pid
                        ? colors.tertiary
                        : '#e5e5e5',
                    gridColumn: '1/2'
                  }}
                  whileHover={{
                    boxShadow:
                      selectedPoll &&
                      (!currentlyOpenPoll || selectedPoll.pid === currentlyOpenPoll.pid) &&
                      !pollAnalyticsBeingCurrentlySharedWithUsers &&
                      `0 0 0.5rem 0.025rem ${hexToRGB({
                        color:
                          selectedPoll.pid === currentlyOpenPoll?.pid
                            ? colors.secondary
                            : colors.tertiary,
                        alpha: 0.25
                      })}`
                  }}
                  whileTap={{
                    scale:
                      selectedPoll &&
                      (!currentlyOpenPoll || selectedPoll.pid === currentlyOpenPoll.pid) &&
                      !pollAnalyticsBeingCurrentlySharedWithUsers &&
                      0.95
                  }}
                  disabled={
                    !selectedPoll ||
                    (selectedPoll &&
                      ((currentlyOpenPoll && selectedPoll.pid !== currentlyOpenPoll.pid) ||
                        pollAnalyticsBeingCurrentlySharedWithUsers))
                  }
                  variants={fadeInAndOutVariants()}
                  initial="initial"
                  animate="animate"
                  exit="exit">
                  {selectedPoll && selectedPoll.pid === currentlyOpenPoll?.pid
                    ? 'Close Poll'
                    : 'Open Poll'}
                </Button>
              )}
            </AnimatePresence>
            <Button
              onClick={() =>
                selectedPoll?.pid === pollAnalyticsBeingCurrentlySharedWithUsers?.pid
                  ? handlePoll('stopSharingPollAnalytics')
                  : handlePoll('sharePollAnalytics')
              }
              style={{
                color: selectedPoll && !selectedPoll.isQueued ? '#fff' : '#c4c4c4',
                backgroundColor:
                  selectedPoll && !selectedPoll.isQueued ? colors.tertiary : '#e5e5e5',
                gridColumn: '2'
              }}
              whileHover={{
                boxShadow:
                  selectedPoll &&
                  !selectedPoll.isQueued &&
                  `0 0 0.5rem 0.025rem ${hexToRGB({
                    color: colors.tertiary,
                    alpha: 0.25
                  })}`
              }}
              whileTap={{
                scale: selectedPoll && !selectedPoll.isQueued && 0.95
              }}
              disabled={!selectedPoll || selectedPoll.isQueued}>
              {selectedPoll?.shareResults ? 'Stop Sharing' : 'Share Analytics'}
            </Button>
          </Buttons>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isCreateOrEditPollModalOpen && (
          <CreateOrEditPollModal
            colors={colors}
            setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
            pollBeingCurrentlyEditedByModerator={pollBeingCurrentlyEditedByModerator}
            setPollBeingCurrentlyEditedByModerator={setPollBeingCurrentlyEditedByModerator}
            newPollType={newPollType}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlyViewedByModerator?.type === 'general poll' && (
            <GeneralAnalyticsModal
              poll={pollAnalyticsBeingCurrentlyViewedByModerator}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlyViewedByModerator?.type === 'feedback poll' && (
            <FeedbackAnalyticsModal
              poll={pollAnalyticsBeingCurrentlyViewedByModerator}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlyViewedByModerator?.type === 'word cloud' && (
            <WordCloudAnalyticsModal
              poll={pollAnalyticsBeingCurrentlyViewedByModerator}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
      <AnimatePresence>
        <Dialog
          ref={confirmDeletePollOrQuestionDialogRef}
          isVisible={showConfirmDeleteDialog}
          onDismiss={handleDismissDeletePoll}
          contentStyle={{
            maxWidth: '23.75rem'
          }}>
          <ConfirmDelete
            handleDelete={handleDeletePoll}
            handleDismiss={handleDismissDeletePoll}
            type="poll"
          />
        </Dialog>
      </AnimatePresence>
    </>
  );
};

const CreatePoll = styled(motion.div)`
  align-items: center;
  border-radius: 0.125em;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  height: 2.5em;
  justify-content: center;
  margin: 0 auto;
  padding: 1em;
  position: relative;
  text-transform: uppercase;
  width: 11.875em;
`;

const PollDropdown = styled(motion.div)`
  bottom: -5rem;
  position: absolute;
  transform-origin: top center;
  width: 100%;
  z-index: 1;
  ul {
    align-items: center;
    background-color: #fff;
    border-radius: 0.125rem;
    box-shadow: 0.063rem 0.063rem 0.75rem 0.125rem rgba(196, 196, 196, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 8.75rem;

    li {
      font-size: 0.75rem;
      line-height: 2em;
      list-style: none;
      padding: 0.667em;
      text-align: center;
      text-decoration: none;

      button {
        background: transparent;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

const Buttons = styled.section`
  align-items: center;
  border-top: 0.063rem solid #bdbdbd;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, minmax(auto, 11.875rem));
  height: 4.1rem;
  justify-content: space-between;
  padding: 0.75rem 0;

  @media screen and (min-width: 600px) {
    justify-content: end;
  }

  @media screen and (min-width: 1150px) {
    justify-content: space-between;
  }

  button {
    border-radius: 0.125rem;
    height: 2.5rem;
    max-width: 11.875rem;
  }
`;

export default ControlPanel;
