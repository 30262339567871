import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Markup } from 'interweave';
import { useEventData } from 'hooks';
import { LocalContext } from 'context';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { H4, P, breakpoints } from 'styles';
import { Arrow } from 'assets/svgs';
import 'pure-react-carousel/dist/react-carousel.es.css';

export default () => {
  const { edges: events } = useEventData();
  const { theme, isMobile } = useContext(LocalContext);
  const pastEvents = events
    .filter(({ node }) => node.frontmatter.status === 'past' && !node.frontmatter.hidden)
    .sort((a, b) => b.node.frontmatter.id - a.node.frontmatter.id);
  return (
    <PastEvents>
      {pastEvents.length >= 3 ? (
        <CarouselProvider
          naturalSlideWidth={374}
          naturalSlideHeight={240}
          totalSlides={5}
          visibleSlides={isMobile ? 1 : 2}
          isIntrinsicHeight
          isPlaying
          touchEnabled
          dragEnabled
          infinite
          interval={6000}>
          <CarouselWrapper>
            <StyledButtonBack $theme={theme}>
              <Arrow />
            </StyledButtonBack>
            <StyledSlider>
              {pastEvents.map((event, i) => {
                const eventImage = getImage(event.node.frontmatter.thumb);
                return (
                  <StyledSlide key={event.node.frontmatter.name} index={i} $theme={theme}>
                    <Link to={`${event.node.frontmatter.slug}/livestream`}>
                      <GatsbyImage image={eventImage} alt={event.node.frontmatter.name} />
                      <H4
                        style={{
                          color: theme.primary
                        }}>{`${event.node.frontmatter.name}`}</H4>
                      <H4 style={{ color: theme.primary }}>{`${
                        event.node.frontmatter.date.split(' ')[1]
                      } ${event.node.frontmatter.date.split(' ')[2]} ${event.node.frontmatter.date
                        .split(' ')[3]
                        .replace(/,/g, '')}`}</H4>
                      <P>Virtual Event</P>
                    </Link>
                  </StyledSlide>
                );
              })}
            </StyledSlider>
            <StyledButtonNext $theme={theme}>
              <Arrow />
            </StyledButtonNext>
          </CarouselWrapper>
        </CarouselProvider>
      ) : (
        <EventWrapper>
          {pastEvents.map((event, i) => {
            const eventImage = getImage(event.node.frontmatter.thumb);
            return (
              <Event key={event.node.frontmatter.name} index={i} $theme={theme}>
                <Link to={`events/${event.node.frontmatter.slug}`}>
                  <GatsbyImage image={eventImage} alt={event.node.frontmatter.name} />
                  <H4 style={{ color: theme.primary }}>{event.node.frontmatter.name}</H4>
                  <H4 style={{ color: theme.primary }}>{`${
                    event.node.frontmatter.date.split(' ')[1]
                  } ${event.node.frontmatter.date.split(' ')[2]} ${event.node.frontmatter.date
                    .split(' ')[3]
                    .replace(/,/g, '')}`}</H4>
                  <P>Virtual Event</P>
                </Link>
              </Event>
            );
          })}
        </EventWrapper>
      )}
    </PastEvents>
  );
};

const EventWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-items: center;
  margin: 0 auto;
  margin-top: 3rem;
  max-width: 800px;
  width: 100%;
  ${breakpoints.md} {
    flex-direction: row;
  }
`;

const Event = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto;
  max-width: 380px;
  width: 100%;
  ${H4} {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.25rem;
    margin-top: 1rem;
  }
  ${H4}:nth-of-type(2) {
    margin-top: 0rem;
  }
  ${P} {
    color: #3c3c3c;
    margin-bottom: 2rem;
    text-align: left;
  }
`;

const CarouselWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  margin-top: 3rem;
  max-width: 90rem;
  width: 100%;
`;

const StyledSlider = styled(Slider)`
  grid-column: 3/11;
  grid-row: 1;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 520px) {
    grid-column: 2/12;
  }
`;

const StyledSlide = styled(Slide)`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: -3px;
  width: 100%;
  img {
    margin: 0 0.625rem !important;
  }
  ${H4} {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0 0.625rem;
    margin-bottom: 0.25rem;
    margin-top: 1rem;
  }
  ${H4}:nth-of-type(2) {
    margin-top: 0rem;
  }
  ${P} {
    color: #3c3c3c;
    margin: 0 0.625rem;
    text-align: left;
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  background-color: ${({ $theme }) => $theme.tertiary};
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
  grid-column: 1/2;
  grid-row: 1;
  height: 3rem;
  justify-self: end;
  width: 3rem;
  svg {
    transform: rotate(90deg);
    transform-origin: center;
    width: 1.5rem;
    path {
      stroke: #f3f3f3;
    }
  }
  @media screen and (min-width: 520px) {
    grid-column: 2/3;
  }
  @media screen and (min-width: 696px) {
    grid-column: 2/3;
  }
  @media screen and (min-width: 768px) {
    grid-column: 2/3;
  }
  @media screen and (min-width: 1050px) {
    grid-column: 2/3;
  }
  @media screen and (min-width: 1200px) {
    grid-column: 2/3;
  }
  @media screen and (min-width: 1440px) {
    position: relative;
  }
`;

const StyledButtonNext = styled(ButtonNext)`
  background-color: ${({ $theme }) => $theme.tertiary};
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  cursor: pointer;
  grid-column: 12/13;
  grid-row: 1;
  height: 3rem;
  justify-self: end;
  width: 3rem;
  svg {
    transform: rotate(-90deg);
    transform-origin: center;
    width: 1.5rem;
    path {
      stroke: #f3f3f3;
    }
  }
  @media screen and (min-width: 520px) {
    grid-column: 11/12;
  }
  @media screen and (min-width: 696px) {
    grid-column: 11/12;
    justify-self: start;
  }
  @media screen and (min-width: 1440px) {
    position: relative;
  }
`;

const PastEvents = styled.div`
  background: white;
  height: auto;
  margin: 0 auto;
  width: 100%;
`;
