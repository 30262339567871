import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { hexToRGB } from 'utils';
import { LocalContext } from 'context';
import { Button } from 'components';
import { fadeInAndOutVariants } from 'styles';

function ConfirmDeleteModal({ handleDelete, handleCancel, type }) {
  const { selectedEvent } = useContext(LocalContext);

  return (
    <Underlay
      data-underlay
      colors={selectedEvent.colors}
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true') {
          handleCancel(e);
        }
      }}>
      <Modal colors={selectedEvent.colors}>
        <Question colors={selectedEvent.colors}>
          <p>Are you sure you want to delete this {type}?</p>
        </Question>
        <h4>The {type} will be permanently deleted and you wont be able to recover it!</h4>
        <Button
          colors={selectedEvent.colors}
          type="button"
          onClick={handleDelete}
          whileHover={{
            backgroundColor: selectedEvent.colors.primary
          }}
          whileTap={{
            scale: 0.95
          }}
          style={{
            alignSelf: 'flex-end',
            backgroundColor: selectedEvent.colors.tertiary,
            color: '#FFFFFF',
            borderRadius: '0.125rem',
            height: '2.5rem',
            marginTop: '0.45rem',
            width: '12rem'
          }}>
          DELETE
        </Button>
        <motion.button
          whileHover={{
            color: selectedEvent.colors.tertiary
          }}
          whileTap={{
            scale: 0.95
          }}
          type="button"
          onClick={handleCancel}>
          Cancel
        </motion.button>
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem auto 0;
  max-width: 23.75rem;
  position: relative;
  h4 {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 500;
    padding: 1.75rem 1.5rem 1.25rem 1.5rem;
    text-align: center;
  }
  button {
    cursor: pointer;
    margin: 0 auto 1rem auto;
    &:nth-of-type(2) {
      background: transparent;
      color: #c4c4c4;
      font-size: 0.875rem;
      font-weight: 300;
      text-decoration: underline;
    }
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.tertiary, alpha: 0.2 })};
  color: ${({ colors }) => colors.tertiary};
  font-family: 'museo-slab', serif;
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;
  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

export default ConfirmDeleteModal;
