import * as React from 'react';

function SvgCommentArrow(props) {
  return (
    <svg viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 11.012L9.76 3.214l7.758 7.798M9.759 20V3" stroke="#C4C4C4" strokeWidth={4} />
    </svg>
  );
}

export default SvgCommentArrow;
