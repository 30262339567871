import React, { useState, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { PollsAnswer as Answer } from 'components';
import { arrayMoveImmutable } from 'array-move';
import { clamp, distance } from '@popmotion/popcorn';

const Answers = ({
  answers,
  handleAnswerTextChange,
  isMarkCorrectAnswersEnabled,
  selectedEvent,
  setAnswers
}) => {
  const [currentlyHoveredAnswer, setCurrentlyHoveredAnswer] = useState(null);

  const answersRef = useRef(null);

  const positions = useRef([]).current;
  // eslint-disable-next-line no-return-assign
  const setPositions = (i, offset) => (positions[i] = offset);

  // This controls how much you have to drag an 'Answer' before it swaps places with another.
  // The higher the number, the more you have to drag it. 18 seems like a good sweet spot.
  const dragBuffer = 18;

  const findIndex = (i, yOffset, _positions) => {
    let target = i;
    const { top, height } = _positions[i];
    const bottom = top + height;

    // If moving down
    if (yOffset > 0) {
      const nextItem = _positions[i + 1];
      if (nextItem === undefined) return i;
      const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + dragBuffer;
      if (yOffset > swapOffset) target = i + 1;

      // If moving up
    } else if (yOffset < 0) {
      const prevItem = _positions[i - 1];
      if (prevItem === undefined) return i;
      const prevBottom = prevItem.top + prevItem.height;
      const swapOffset = distance(top, prevBottom - prevItem.height / 2) + dragBuffer;
      if (yOffset < -swapOffset) target = i - 1;
    }

    return clamp(0, _positions.length, target);
  };

  const reorderAnswer = (i, dragOffset) => {
    const targetIndex = findIndex(i, dragOffset, positions);
    if (targetIndex !== i) {
      setAnswers(arrayMoveImmutable(answers, i, targetIndex));
    }
  };

  const handleAnswerCheckboxChange = (e) =>
    setAnswers((currentState) =>
      currentState.map((answer) => {
        if (answer.id === e.target.parentElement.id) {
          answer.isCorrect = !answer.isCorrect;
        }
        return answer;
      })
    );

  const handleDeleteAnswer = ({ target }) =>
    setAnswers((currentState) =>
      currentState.filter((answer) => answer.id !== target.parentElement.id)
    );

  return (
    <div ref={answersRef}>
      <AnimatePresence initial={false}>
        {answers.map((answer, i, arr) => (
          <Answer
            answer={answer}
            answersRef={answersRef}
            arr={arr}
            currentlyHoveredAnswer={currentlyHoveredAnswer}
            handleAnswerCheckboxChange={handleAnswerCheckboxChange}
            handleAnswerTextChange={handleAnswerTextChange}
            handleDeleteAnswer={handleDeleteAnswer}
            i={i}
            isMarkCorrectAnswersEnabled={isMarkCorrectAnswersEnabled}
            key={answer.id}
            reorderAnswer={reorderAnswer}
            selectedEvent={selectedEvent}
            setCurrentlyHoveredAnswer={setCurrentlyHoveredAnswer}
            setPositions={setPositions}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default Answers;
