import * as React from 'react';

const SadEmoji = (props) => (
  <svg
    viewBox="0 0 26 26"
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
      stroke={props.stroke}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M7.35352 18.1177C7.85739 17.1215 10.1248 16.353 12.8241 16.353C15.4874 16.353 17.7548 17.0931 18.2947 18.0893"
      stroke={props.innerStroke}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M16.706 11.0587C17.3882 11.0587 17.9413 10.5057 17.9413 9.82342C17.9413 9.14119 17.3882 8.58813 16.706 8.58813C16.0238 8.58813 15.4707 9.14119 15.4707 9.82342C15.4707 10.5057 16.0238 11.0587 16.706 11.0587Z"
      fill={props.innerStroke}
      stroke={props.innerStroke}
    />
    <path
      d="M9.29389 11.059C9.97612 11.059 10.5292 10.5059 10.5292 9.82367C10.5292 9.14143 9.97612 8.58838 9.29389 8.58838C8.61166 8.58838 8.05859 9.14143 8.05859 9.82367C8.05859 10.5059 8.61166 11.059 9.29389 11.059Z"
      fill={props.innerStroke}
      stroke={props.innerStroke}
    />
  </svg>
);

export default SadEmoji;
