import React, { useContext, useEffect, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { FirebaseContext, LocalContext } from 'context';
import styled from 'styled-components';
import { hexToRGB } from 'utils';
/* import data from './words'; */

const options = {
  colors: ['#025F9F', '#2ca02c', '#8c564b'],
  enableTooltip: false,
  fontFamily: 'sans-serif',
  fontSizes: [12, 42],
  padding: 2,
  rotations: 3,
  rotationAngles: [0, 45],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 1000
};

const WordCloudStream = ({ currentlyOpenWordCloudId }) => {
  const { selectedEvent } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [pollAnalytics, setPollAnalytics] = useState({});
  const [arePollAnalyticsLoading, setArePollAnalyticsLoading] = useState(true);
  useEffect(() => {
    let unsubscribeFromPollAnalytics;
    let wordsList = [];
    let uniqueWords = [];
    if (firebase) {
      unsubscribeFromPollAnalytics = firebase.interaction.polls.subscribeToPollAnalytics({
        eid: selectedEvent?.eid,
        pid: currentlyOpenWordCloudId,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const { words, question, newPollType } = snapshot.val();

            const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
            if (words) {
              wordsList = Object.entries(words).map((entry) => entry[1].word);
              uniqueWords = wordsList.filter((word, index) => wordsList.indexOf(word) === index);
            }
            const wordCloudData = [];
            uniqueWords.map((word) =>
              wordCloudData.push({
                text: word,
                value: countOccurrences(wordsList, word)
              })
            );

            setPollAnalytics({
              question,
              wordCloudData,
              newPollType
            });
            setArePollAnalyticsLoading(false);
          } else if (!snapshot.exists()) {
            // TODO: No analytics. Do we need to do anything here?
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPollAnalytics) {
        unsubscribeFromPollAnalytics();
      }
    };
  }, [firebase, currentlyOpenWordCloudId]);

  return (
    <>
      {currentlyOpenWordCloudId &&
        pollAnalytics?.newPollType === 'word cloud' &&
        !arePollAnalyticsLoading && (
          <Wrapper>
            <Question colors={selectedEvent.colors}>
              <p>{pollAnalytics?.question}</p>
            </Question>
            <ReactWordcloud
              words={pollAnalytics?.wordCloudData}
              options={options}
              style={{ margin: '1rem 1rem 2rem 1rem', height: '100%' }}
            />
          </Wrapper>
        )}
    </>
  );
};

const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 2px;
  bottom: 3rem;
  left: 8rem;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 8rem;
  top: 3rem;
  @media only screen and (max-width: 1000px) {
    right: 1rem;
    left: 1rem;
  }
  @media only screen and (max-width: 500px) {
    top: 1rem;
    bottom: 1rem;
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;

  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

export default WordCloudStream;
