import * as React from 'react';

function SvgBlock(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
      <g>
        <g>
          <path
            d="M500,10c66.6,0,130.1,12.9,190.5,38.8c60.4,25.9,112.5,60.7,156.3,104.4c43.7,43.7,78.5,95.8,104.4,156.3S990,433.4,990,500c0,66.6-12.9,130.1-38.8,190.5c-25.9,60.4-60.7,112.5-104.4,156.3S751,925.3,690.5,951.2C630.1,977.1,566.6,990,500,990c-66.6,0-130.1-12.9-190.5-38.8c-60.4-25.9-112.5-60.7-156.3-104.4S74.7,751,48.8,690.5C22.9,630.1,10,566.6,10,500c0-66.6,12.9-130.1,38.8-190.5c25.9-60.4,60.7-112.5,104.4-156.3c43.7-43.7,95.8-78.5,156.3-104.4C369.9,22.9,433.4,10,500,10z M750.2,813.2L186.8,249.8c-27.8,35-49.4,73.9-64.7,116.6c-15.3,42.7-23,87.2-23,133.6c0,54.3,10.6,106.2,31.8,155.7c21.2,49.5,49.7,92.2,85.4,127.9c35.7,35.7,78.4,64.2,127.9,85.4s101.4,31.8,155.7,31.8c46.6,0,91.3-7.6,134-22.8C676.7,862.9,715.4,841.3,750.2,813.2L750.2,813.2z M500,99.1c-46.6,0-91.3,7.6-134,22.8s-81.4,36.8-116.2,64.9l563.4,563.4c28.1-34.8,49.7-73.5,64.9-116.2s22.8-87.3,22.8-134c0-54.3-10.6-106.2-31.8-155.7c-21.2-49.5-49.7-92.2-85.4-127.9c-35.7-35.7-78.4-64.2-127.9-85.4C606.2,109.7,554.3,99.1,500,99.1L500,99.1z"
            fill={props.fill || '#626262'}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgBlock;
