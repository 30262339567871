import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { LocalContext } from 'context';
import { TextInput } from 'components/Events/Livestream/Interaction/Polls';

const WordCloudOptions = ({
  allowMultipleInputs,
  numberOfMultipleInputsAllowed,
  setAllowMultipleInputs,
  setNumberOfMultipleInputsAllowed,
  allowPollDescription,
  setAllowPollDescription,
  characterLimit,
  setCharacterLimit,
  numberOfCharactersAllowed,
  setNumberOfCharactersAllowed
}) => {
  const { selectedEvent } = useContext(LocalContext);
  const [isMultipleInputsDropdownMenuOpen, setIsMultipleInputsDropdownMenuOpen] = useState(false);
  return (
    <Wrapper>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={allowMultipleInputs}
          onClick={() => {
            if (isMultipleInputsDropdownMenuOpen) {
              setIsMultipleInputsDropdownMenuOpen(false);
            }
            setAllowMultipleInputs((currentState) => !currentState);
          }}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Allow multiple inputs
        <AnimatePresence>
          {allowMultipleInputs && (
            <MultipleOptionsSelector
              colors={selectedEvent.colors}
              onBlur={() => setIsMultipleInputsDropdownMenuOpen(false)}
              onClick={() => setIsMultipleInputsDropdownMenuOpen(true)}
              tabIndex={0}>
              {numberOfMultipleInputsAllowed}
              <div
                style={{
                  borderLeft: '0.25em solid transparent',
                  borderRight: '0.25em solid transparent',
                  height: 0,
                  marginLeft: '0.417em',
                  width: 0
                }}
              />
              <AnimatePresence>
                {isMultipleInputsDropdownMenuOpen && (
                  <MultipleOptionsDropdownMenu
                    colors={selectedEvent.colors}
                    onClick={(e) => {
                      e.stopPropagation();
                      setNumberOfMultipleInputsAllowed(
                        e.target.innerHTML === 2
                          ? e.target.innerHTML
                          : parseInt(e.target.innerHTML, 10)
                      );
                      setIsMultipleInputsDropdownMenuOpen(false);
                    }}>
                    <div>2</div>
                    <div>3</div>
                  </MultipleOptionsDropdownMenu>
                )}
              </AnimatePresence>
            </MultipleOptionsSelector>
          )}
        </AnimatePresence>
      </Option>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={allowPollDescription}
          onClick={() => {
            setAllowPollDescription((currentState) => !currentState);
          }}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Include poll description
      </Option>
      <Option>
        <OptionCheckbox
          colors={selectedEvent.colors}
          isEnabled={characterLimit}
          onClick={() => {
            setCharacterLimit((currentState) => !currentState);
          }}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Set character limit&nbsp;&nbsp;
        <AnimatePresence>
          {characterLimit && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <CharacterLimitInput
                type="number"
                min={12}
                max={68}
                value={numberOfCharactersAllowed}
                onChange={(e) => setNumberOfCharactersAllowed(parseInt(e.target.value, 10))}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Option>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  left: -6px;
  margin-top: 1.1rem;
  position: relative;
`;

const Option = styled(motion.div)`
  align-items: center;
  color: #4f4f4f;
  display: flex;
  font-size: 0.875rem;
`;

const OptionCheckbox = styled.div`
  cursor: pointer;
  margin-right: 8px;
  padding: 6px;

  > div {
    align-items: center;
    display: flex;
    height: 1rem;
    justify-content: center;
    transition: border 200ms ease-in-out;
    width: 1rem;

    > div {
      height: 8px;
      transition: background-color 200ms ease-in-out;
      width: 8px;
    }

    &,
    > div {
      border-radius: 2px;
    }

    ${({ isEnabled }) =>
      isEnabled
        ? css`
            border: 1px solid ${({ colors }) => colors.tertiary};
            > div {
              background-color: ${({ colors }) => colors.tertiary};
            }
          `
        : css`
            border: 1px solid #c4c4c4;
            > div {
              background-color: transparent;
            }
          `}
  }
`;

const MultipleOptionsSelector = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  border-radius: 14px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 16px;
  justify-content: center;
  margin-left: 10px;
  padding-left: 1px;
  transition: color 2000ms solid ease-in-out;
  outline: none;
  position: relative;
  width: 41px;

  > div:first-of-type {
    border-top: 0.188rem solid #4f4f4f;
    transition: border-top 200ms solid ease-in-out;
  }

  :focus {
    border: 1px solid ${({ colors }) => colors.tertiary};
    color: ${({ colors }) => colors.tertiary};

    > div:first-of-type {
      border-top: 0.188rem solid ${({ colors }) => colors.tertiary};
    }
  }
`;

const MultipleOptionsDropdownMenu = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 12px 2px rgba(196, 196, 196, 0.6);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: 8px;
  padding: 4px 0;
  position: absolute;
  top: 5px;
  width: 35px;

  > div {
    align-items: center;
    color: #4f4f4f;
    display: flex;
    font-size: 12px;
    height: 20.5px;
    justify-content: center;
    width: 100%;

    &:hover {
      color: ${({ colors }) => colors.tertiary};
    }
  }
`;

const CharacterLimitInput = styled(TextInput)`
  margin-top: 1px;
  width: 40px;
`;

export default WordCloudOptions;
