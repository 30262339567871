const constructQueryString = ({ formValues, exclude }) => {
  // We don't want to include the email address in the query string, coz that would be a security
  // risk. Instead, we'll store the user's email address in their localStorage.
  const filteredFormValues = Object.fromEntries(
    Object.entries(formValues)
      .filter(([key]) => (exclude?.length ? !exclude.includes(key) : true))
      .map(([key, value]) => (typeof value === 'string' ? [key, value.trim()] : [key, value]))
  );

  const queryString = new URLSearchParams(filteredFormValues).toString();

  return queryString;
};

export default constructQueryString;
