import { arrayUnion, doc, setDoc, updateDoc, increment } from 'firebase/firestore';

function LivestreamFactory(firebase) {
  const updateVideoSessionData = async ({ eid, uid, timeRange }) =>
    setDoc(
      doc(firebase.fsdb, 'users', uid, 'videoSessionData', eid),
      {
        eid,
        timeRanges: arrayUnion(...timeRange)
      },
      { merge: true }
    );

  const updateTimeWatched = async ({ uid, eid }) => {
    setDoc(
      doc(firebase.fsdb, 'users', uid, 'timeWatched', eid),
      {
        minutes: increment(1)
      },
      { merge: true }
    );
  };

  return {
    updateVideoSessionData,
    updateTimeWatched
  };
}
export default LivestreamFactory;
