import * as React from 'react';

function SvgStopwatch(props) {
  return (
    <svg viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.008 16.383l-.87-5.807c-.042-.295-.472-.295-.513 0l-.87 5.807a2.128 2.128 0 00-1.025 1.82c0 1.18.963 2.136 2.151 2.136 1.188 0 2.15-.956 2.15-2.136 0-.773-.409-1.444-1.023-1.82z"
        fill={props.color}
      />
      <path
        d="M11.88 6.407C5.325 6.407 0 11.695 0 18.203 0 24.712 5.325 30 11.88 30c6.554 0 11.88-5.288 11.88-11.797 0-6.508-5.326-11.796-11.88-11.796zm0 20.949c-5.08 0-9.217-4.109-9.217-9.153 0-5.043 4.137-9.152 9.217-9.152 5.08 0 9.217 4.108 9.217 9.152s-4.138 9.153-9.217 9.153zM8.703 4.271h6.35a.505.505 0 00.512-.508V.508A.505.505 0 0015.053 0h-6.35a.505.505 0 00-.512.508v3.255c0 .284.226.508.512.508zM24.855 6.763L23.41 5.329a.51.51 0 00-.727 0L21.24 6.763a.5.5 0 000 .722l1.444 1.434a.51.51 0 00.727 0l1.444-1.434a.52.52 0 000-.722z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgStopwatch;
