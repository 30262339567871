import React, { useContext } from 'react';
import styled from 'styled-components';
import { P, breakpoints } from 'styles';
import { LocalContext } from 'context';

const Terms = () => {
  const { theme } = useContext(LocalContext);
  return (
    <SectionWrapper $theme={theme}>
      <P>
        MentorsWork LIVE events are hosted by Get Broadcasting on behalf of MentorsWork. Your name
        and email address are required for you to register and join the event. Once you provide your
        name and email address, Get Broadcasting will provide you with the event link so you can
        access the online event. Please see the Get Broadcasting{' '}
        <a href="https://www.getbroadcasting.com/privacy-policy/" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>{' '}
        for more details on how Get Broadcasting manages your personal data. Get Broadcasting works
        with MentorsWork to engage with SMEs on MentorsWork&apos;s behalf. Your registration details
        are collected by Get Broadcasting. This data will not be used for additional purposes
        without your consent. Please see the MentorsWork{' '}
        <a href="https://mentorswork.ie/privacy-policy/" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>{' '}
        for more details on how MentorsWork manages personal data. By accepting this invitation from
        MentorsWork for attendance at this hybrid event, you agree to the terms & conditions - click{' '}
        <a href="https://mentorswork.ie/privacy-policy/" target="_blank" rel="noreferrer">
          here
        </a>{' '}
        for more details.
      </P>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background-color: rgba(234, 233, 232, 0.5);
  background-size: cover;
  padding: 3rem 1.75rem;
  width: 100%;
  p {
    color: ${({ $theme }) => $theme.primary};
    font-family: 'Uni Neue', serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    margin: 0;
    a {
      color: ${({ $theme }) => $theme.primary};
      text-decoration: underline;
      text-decoration-thickness: from-font;
      text-underline-position: under;
    }
  }
  ${breakpoints.lg} {
    padding: 3rem 8rem;
  }
`;

export default Terms;
