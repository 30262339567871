import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';
import { fadeInAndOutVariants, defaultColors } from 'styles';

function ErrorMessage({
  errorInitialState = { code: null, message: null },
  errorMessage,
  setError,
  style,
  timeout,
  variants = fadeInAndOutVariants()
}) {
  useEffect(() => {
    if (timeout) {
      let timer;
      if (errorMessage) {
        timer = setTimeout(() => setError(errorInitialState), timeout);
      }
      return () => timer && clearTimeout(timer);
    }
  }, [errorMessage]);

  return (
    <AnimatePresence>
      {errorMessage && (
        <motion.div
          initial="initial"
          animate="animate"
          exit="exit"
          style={{
            fontFamily: `'Uni Neue', sans-serif`,
            color: defaultColors.tertiary,
            textAlign: 'center',
            ...style
          }}
          variants={variants}>
          <Markup content={errorMessage} noWrap />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ErrorMessage;
