import React, { useState, useRef, useEffect } from 'react';
import { ErrorMessage } from 'components';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { TextInput } from 'components/Events/Livestream/Interaction/Polls';
import { Tick } from 'assets/animated-svgs';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';

const Answer = ({
  answer,
  answersRef,
  arr,
  currentlyHoveredAnswer,
  handleAnswerCheckboxChange,
  handleAnswerTextChange,
  handleDeleteAnswer,
  i,
  isMarkCorrectAnswersEnabled,
  reorderAnswer,
  selectedEvent,
  setCurrentlyHoveredAnswer,
  setPositions
}) => {
  const [isAnswerBeingDragged, setIsAnswerBeingDragged] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setPositions(i, {
      height: ref.current.offsetHeight,
      top: ref.current.offsetTop
    });
  });

  const showMarkCorrectAnswerIcon =
    isMarkCorrectAnswersEnabled &&
    arr.filter((_answer) => _answer.text && _answer.isCorrect).length < 1 &&
    answer.text &&
    !answer.isCorrect;

  const showReorderAnswerIcon =
    arr.length > 1 && currentlyHoveredAnswer === answer.id && answer.text;

  const showDeleteAnswerIcon = arr.length > 2 && currentlyHoveredAnswer === answer.id;

  return (
    <Wrapper
      layout
      key={answer.id}
      id={answer.id}
      ref={ref}
      drag="y"
      dragConstraints={answersRef}
      dragElastic={0.0625}
      custom={isAnswerBeingDragged}
      onDragStart={() => setIsAnswerBeingDragged(true)}
      onDragEnd={() => setIsAnswerBeingDragged(false)}
      onViewportBoxUpdate={(_viewportBox, delta) => {
        if (isAnswerBeingDragged) {
          reorderAnswer(i, delta.y.translate);
        }
      }}
      onMouseEnter={() => setCurrentlyHoveredAnswer(answer.id)}
      onMouseLeave={() => setCurrentlyHoveredAnswer(null)}>
      <AnimatePresence>
        {showReorderAnswerIcon && !showMarkCorrectAnswerIcon && (
          <ReorderAnswer role="button" tabIndex="0">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="1" cy="1" r="1" fill="#4F4F4F" />
              <circle cx="1" cy="5" r="1" fill="#4F4F4F" />
              <circle cx="1" cy="9" r="1" fill="#4F4F4F" />
              <circle cx="5" cy="1" r="1" fill="#4F4F4F" />
              <circle cx="5" cy="5" r="1" fill="#4F4F4F" />
              <circle cx="5" cy="9" r="1" fill="#4F4F4F" />
            </svg>
          </ReorderAnswer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showMarkCorrectAnswerIcon && (
          <RedArrow viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.701 1l3.211 3.195-3.21 3.194M1 4l9 .195" stroke="#F55151" />
          </RedArrow>
        )}
      </AnimatePresence>
      <AnswerCheckboxInput
        onClick={(e) => isMarkCorrectAnswersEnabled && answer.text && handleAnswerCheckboxChange(e)}
        isMarkCorrectAnswersEnabled={isMarkCorrectAnswersEnabled}
        isCorrect={answer.isCorrect}
        answerText={answer.text}
        selectedEvent={selectedEvent}>
        <div>
          <AnimatePresence>
            {answer.isCorrect && <Tick color={selectedEvent?.colors.tertiary} />}
          </AnimatePresence>
        </div>
      </AnswerCheckboxInput>
      <div style={{ position: 'relative' }}>
        <AnswerTextInput
          onChange={(e) => handleAnswerTextChange(e, answer.id)}
          placeholder="Add answer"
          selectedEvent={selectedEvent}
          type="input"
          value={answer.text}
          error={
            answer.error?.code === 'missing-answer' &&
            ((arr.length <= 2 && !answer.text) ||
              (arr.length > 2 && !answer.text && i !== arr.length - 1))
          }
        />
        <ErrorMessage
          errorMessage={answer.error?.code === 'missing-answer' && answer.error.message}
          style={{
            color: '#F55151',
            fontSize: '0.75rem',
            position: 'absolute',
            top: '1.8rem',
            left: '0.4rem'
          }}
          variants={fadeInAndOutAndAnimateHeightVariants()}
        />
      </div>
      <AnimatePresence>
        {showDeleteAnswerIcon && (
          <DeleteAnswerWrapper onClick={handleDeleteAnswer} role="button" tabIndex="0">
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M7 1L0.999999 7" stroke="#4F4F4F" strokeWidth="2" />
              <path d="M7 7L0.999999 0.999998" stroke="#4F4F4F" strokeWidth="2" />
            </svg>
          </DeleteAnswerWrapper>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div).attrs({
  variants: {
    initial: {
      scale: 0,
      opacity: 0
    },
    animate: (isAnswerBeingDragged) => ({
      scale: 1,
      opacity: 1,
      zIndex: isAnswerBeingDragged ? 2 : 1,
      transition: {
        duration: 0.9,
        type: 'spring'
      }
    }),
    exit: {
      scale: 0,
      opacity: 0,
      transition: {
        duration: 0.6,
        type: 'tween'
      }
    }
  },
  initial: 'initial',
  animate: 'animate',
  exit: 'exit'
})`
  left: -6px;
  padding-bottom: 18px;
  padding-left: 1.85rem;
  position: relative;
`;

const RedArrow = styled(motion.svg).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1, x: [0, 4, 0] },
  transition: {
    x: {
      repeat: Infinity,
      repeatType: 'loop',
      duration: 0.8
    }
  },
  exit: { opacity: 0 }
})`
  left: -14px;
  position: absolute;
  top: 9px;
  width: 10px;
`;

const AnswerCheckboxInput = styled.div`
  cursor: ${({ isMarkCorrectAnswersEnabled, answerText }) =>
    isMarkCorrectAnswersEnabled && answerText
      ? 'pointer'
      : isMarkCorrectAnswersEnabled && !answerText
      ? 'not-allowed'
      : 'default'};
  left: 0;
  padding: 6px;
  position: absolute;
  top: -0.1rem;

  > div {
    border-color: ${({ isCorrect, selectedEvent }) =>
      isCorrect ? selectedEvent.colors.tertiary : '#bdbdbd'};
    border-radius: 50%;
    border-style: solid;
    border-width: 0.063rem;
    height: 1rem;
    pointer-events: none;
    position: relative;
    transition: border-color 100ms ease-in-out;
    width: 1rem;

    svg {
      bottom: 2px;
      left: 2px;
      pointer-events: none;
      position: absolute;
      width: 11px;
    }
  }
`;

const AnswerTextInput = styled(TextInput)`
  background-color: transparent;
  height: 26px;
  margin-left: 6px;
`;

const ReorderAnswer = styled(motion.div).attrs({
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  exit: { opacity: 0 }
})`
  cursor: ns-resize;
  display: flex;
  padding: 6px;
  position: absolute;
  left: -0.9rem;
  top: 0.1rem;

  > * {
    pointer-events: none;
  }
`;

const DeleteAnswerWrapper = styled(motion.div).attrs({
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  whileHover: { scale: 1.2 },
  whileTap: { scale: 0.9 }
})`
  cursor: pointer;
  display: flex;
  padding: 6px;
  position: absolute;
  right: -26px;
  top: 0.2rem;

  > * {
    pointer-events: none;
  }
`;

export default Answer;
