import { signOut } from 'firebase/auth';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { ref, update, serverTimestamp as rtdbServerTimestamp } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';

function UserProfileFactory(firebase) {
  const uploadAvatar = async (avatarFile) => {
    const uploadAvatarCallable = httpsCallable(firebase.functions, 'user-avatar-upload');
    return uploadAvatarCallable({ avatarFile });
  };

  const updateName = async ({ uid, name }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      name
    });

  const updateEmail = async ({ uid, email }) => {
    const usersRef = doc(firebase.fsdb, 'users', uid);
    const userEmailsRef = doc(firebase.fsdb, 'userEmails', uid);
    const batch = writeBatch(firebase.fsdb);

    batch.update(usersRef, {
      email: email.toLowerCase().trim()
    });

    batch.update(userEmailsRef, {
      email: email.toLowerCase().trim()
    });

    return batch.commit();
  };

  const updateProfession = async ({ uid, profession }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      profession
    });

  const updateCompany = async ({ uid, company }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      company
    });

  const updateSocials = async ({ uid, socials }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      socials: {
        facebook: socials.facebook || '',
        linkedIn: socials.linkedIn || '',
        twitter: socials.twitter || '',
        instagram: socials.instagram || ''
      }
    });

  const generateUsersReport = async ({ selectedEvent }) => {
    const generateUsersReportCallable = httpsCallable(firebase.functions, 'admin-reports-users');
    return generateUsersReportCallable({ selectedEvent });
  };

  const generateLoggedInOnTheDayReport = async ({ users, eventName }) => {
    const generateLoggedInOnTheDayReportCallable = httpsCallable(
      firebase.functions,
      'admin-reports-loggedInOnTheDay'
    );
    return generateLoggedInOnTheDayReportCallable({ users, eventName });
  };

  const generateEventCommentsReport = async ({ eid, eventName }) => {
    const generateEventCommentsReportCallable = httpsCallable(
      firebase.functions,
      'admin-reports-eventComments'
    );
    return generateEventCommentsReportCallable({ eid, eventName });
  };

  const logout = async (user) =>
    update(ref(firebase.rtdb, `/presence/${user.uid}`), {
      selectedEventId: '',
      lastChanged: rtdbServerTimestamp(),
      lastRefreshAt: user.lastRefreshAt
    }).then(() => signOut(firebase.auth));

  return {
    uploadAvatar,
    updateName,
    updateEmail,
    updateProfession,
    updateCompany,
    updateSocials,
    generateUsersReport,
    generateLoggedInOnTheDayReport,
    generateEventCommentsReport,
    logout
  };
}

export default UserProfileFactory;
