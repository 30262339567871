import React, { useState, useContext, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import '../../styles/fonts.css';
import {
  Navbar,
  Modal,
  AccountDeleted,
  CookieBanner,
  ChangeCookieSettings,
  Dialog,
  LoginOrSignUp
} from 'components';
import { LocalContext } from 'context';
import { FirebaseContext, useAuth } from 'firebase';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { Markup } from 'interweave';
import { defaultColors } from 'styles';

const GlobalStyles = createGlobalStyle`
:root {
  --primary-color: #002a3a;
  --secondary-color: #002a3a;
  --tertiary-color: #64a70b;
}
  * {
      margin:0;
      padding:0;
      box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html{
    font-size: ${({ font }) => font};
  }

  body {
    background: 'white';
    overflow-x: hidden;
    font-family: "droid-sans", serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  div [class*="PageWrapper"] {
    background: 'white';
  }

  a{
    text-decoration: none;
  }

  button{
    border:none;
    outline:none;
    &:focus{
        outline:none;
        border:none;
    }
  }
  .scroll-lock {
    overflow: hidden;
  }

  /* Address autocomplete dropdown style overrides */
  .pac-container {
    background: ${({ selectedEvent }) =>
      selectedEvent ? selectedEvent.colors.secondary : 'var(--secondary-color)'};
    border: 0.063rem solid #fff;
    border-top-width: 0;
    border-radius: 0;
    box-shadow: none;

    @media screen and (min-width: 1439px) {
      max-width: 460px !important;
      width: 100% !important;
    }
  }

.pac-target-input {
  outline: none;
  width: 100%;
}

.pac-target-input:focus {
  border: 0.188rem solid var(--secondary-color) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.pac-item {
  color: #fff;
  background-color: transparent;
  font-family: 'droid-sans', sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.031rem;
  padding: 0.188rem 1.25rem 0;
  border: none;
  height: 2.3rem;
}

.pac-item:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

.pac-logo {
  box-sizing: content-box;
  border: 0.188rem solid var(--secondary-color);
  border-top: none;

  @media screen and (min-width: 1440px) {
    box-sizing: border-box;
  }
}

.pac-logo:after {
  padding: 0;
  height: 0;
}

.hdpi.pac-logo:after {
  padding: 0;
  height: 0;
}

.pac-icon,
.hdpi .pac-icon {
  display: none;
}

.pac-item-query {
  color: #fff;
  font-size: 1rem;
}

.pac-item-query:after {
  content: ','
}

.pac-selected {
  background-color: ${({ selectedEvent }) =>
    selectedEvent ? selectedEvent.colors.tertiary : 'var(--secondary-color)'};
}
`;

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const Layout = ({ children }) => {
  const {
    theme,
    fontSizing,
    selectedEvent,
    showUserAccountDeletedModal,
    setShowUserAccountDeletedModal,
    returningVisitorsUnverifiedEmailAddress,
    registrationFormSubmissionStatus,
    showCookieBarModal,
    setShowCookieBarModal,
    magicLinkEmailStatus,
    loginOrSignUpDialogRef,
    showLoginOrSignUpDialog,
    setShowLoginOrSignUpDialog,
    handleDialog,
    browserTabIsActive,
    newUserEmail
  } = useContext(LocalContext);
  const [showChangeSettingsModal, setShowChangeSettingsModal] = useState(false);
  const { user, firebase, loading } = useAuth();
  const colors = selectedEvent ? selectedEvent.colors : defaultColors;

  useEffect(() => {
    if (window.localStorage.getItem('googleAnalyticsAccepted') === null) {
      setShowCookieBarModal(true);
    }
  }, [loading, firebase]);

  useEffect(() => {
    let unsubscribeFromRTDBServer;

    // Sometimes the user object can initialise briefly with the user data from Firebase Auth but
    // not yet the user data from Firestore, so to prevent any errors here we check for the uid
    // property on the user object, to make sure it has the Firestore data. If we don't do this then
    // the code may try to update 'presence' on a document that doesn't exist yet, resulting in an
    // error.
    if (!loading && firebase && user?.uid && browserTabIsActive) {
      unsubscribeFromRTDBServer = firebase.interaction.participants.subscribeToRTDBServer({
        snapshot: (snapshot) => {
          if (snapshot.val()) {
            return firebase.interaction.participants.updateUserPresence({
              user,
              eid: selectedEvent ? selectedEvent.eid : ''
            });
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromRTDBServer) {
        unsubscribeFromRTDBServer();
      }
    };
    // We're using user?.uid in the dependency array here - instead of just user - to avoid getting
    // into an infinite loop each time presence is updated on the user object.
  }, [loading, firebase, user?.uid, selectedEvent?.eid, browserTabIsActive]);

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <Wrapper $theme={theme}>
        <GlobalStyles $theme={theme} font={fontSizing} selectedEvent={selectedEvent} />
        <Navbar colors={colors} />
        {children}
        {!firebase?.auth?.currentUser &&
          (registrationFormSubmissionStatus.submitted ||
            returningVisitorsUnverifiedEmailAddress) && (
            <EmailAddressNotVerifiedNotification colors={colors} theme={theme}>
              <div>
                <Markup
                  content={`${
                    newUserEmail || returningVisitorsUnverifiedEmailAddress || 'Email address'
                  } not verified yet. Please click the link in the email we just sent you to verify and complete your registration application. Can't find our email? Be sure to check your junk!`}
                />
              </div>
            </EmailAddressNotVerifiedNotification>
          )}
        <Modal
          hideCloseButton
          openWithoutTrigger={showUserAccountDeletedModal}
          modalContent={
            <AccountDeleted
              setShowUserAccountDeletedModal={setShowUserAccountDeletedModal}
              onClickOutside={() => setShowUserAccountDeletedModal(false)}
            />
          }
        />
        <Modal
          hideCloseButton
          disableCloseOnClickOutside
          openWithoutTrigger={showCookieBarModal}
          modalContent={
            <CookieBanner
              setShowCookieBarModal={setShowCookieBarModal}
              setShowChangeSettingsModal={setShowChangeSettingsModal}
            />
          }
        />
        <Modal
          hideCloseButton
          disableCloseOnClickOutside
          openWithoutTrigger={showChangeSettingsModal}
          modalContent={
            <ChangeCookieSettings
              setShowChangeSettingsModal={setShowChangeSettingsModal}
              setShowCookieBarModal={setShowCookieBarModal}
            />
          }
        />
        <Dialog
          ref={loginOrSignUpDialogRef}
          isVisible={showLoginOrSignUpDialog}
          onDismiss={() => {
            handleDialog({
              dialogRef: loginOrSignUpDialogRef,
              animation: magicLinkEmailStatus.sent ? 'close' : 'dismiss',
              stateHandler: () => setShowLoginOrSignUpDialog(false)
            });
          }}
          contentStyle={{
            maxWidth: '31.25rem'
          }}>
          <LoginOrSignUp colors={colors} isDialog hideCloseIcon />
        </Dialog>
      </Wrapper>
    </FirebaseContext.Provider>
  );
};

const EmailAddressNotVerifiedNotification = styled.div`
  align-items: center;
  animation: slideIn 750ms forwards;
  background-color: ${({ theme, colors }) =>
    theme.className === 'contrast' ? theme.primary : '#4e0ba7'};
  bottom: -4.188rem;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : '#fff')};
  display: flex;
  font-weight: bold;
  height: auto;
  justify-content: center;
  left: 0;
  letter-spacing: 0.05rem;
  line-height: 1.325rem;
  opacity: 0;
  padding: 0.75rem 0.75rem 0.813rem;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 11;

  > div {
    max-width: 840px;
  }

  @keyframes slideIn {
    0% {
      bottom: -4.188rem;
      opacity: 0;
    }

    100% {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export default Layout;
