import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { increment, onValue, push, ref, set, update } from 'firebase/database';

function PollFactory(firebase) {
  const openPoll = async ({ eid, poll, currentlyOpenPoll }) => {
    if (currentlyOpenPoll?.pid) {
      const pollRef = ref(firebase.rtdb, `polls/${eid}`);

      if (poll.type === 'general poll' && poll.timer?.enabled) {
        return update(pollRef, {
          [`${currentlyOpenPoll?.pid}/isOpen`]: false,
          [`${poll.pid}/isOpen`]: true,
          [`${poll.pid}/timer`]: {
            ...poll.timer,
            startedAt: new Date().getTime()
          }
        });
      }

      const promises = [];

      if (currentlyOpenPoll.type === 'word cloud') {
        if (poll.type === 'word cloud') {
          promises.push(
            updateDoc(doc(firebase.fsdb, 'events', eid), {
              currentlyOpenWordCloudPollId: poll.pid
            })
          );
        } else if (poll.type !== 'word cloud') {
          promises.push(
            updateDoc(doc(firebase.fsdb, 'events', eid), {
              currentlyOpenWordCloudPollId: null
            })
          );
        }
      }

      promises.push(
        update(pollRef, {
          [`${currentlyOpenPoll?.pid}/isOpen`]: false,
          [`${poll.pid}/isOpen`]: true
        })
      );

      return Promise.all(promises);
    }

    if (poll.type === 'general poll' && poll.timer?.enabled) {
      return update(ref(firebase.rtdb, `polls/${eid}/${poll.pid}`), {
        isOpen: true,
        timer: {
          ...poll.timer,
          startedAt: new Date().getTime()
        }
      });
    }

    const promises = [];
    if (poll.type === 'word cloud') {
      promises.push(
        updateDoc(doc(firebase.fsdb, 'events', eid), {
          currentlyOpenWordCloudPollId: poll.pid
        })
      );
    }

    promises.push(
      update(ref(firebase.rtdb, `polls/${eid}/${poll.pid}`), {
        isOpen: true
      })
    );

    return Promise.all(promises);
  };

  const closePoll = async ({ eid, poll }) => {
    const promises = [];

    if (poll.type === 'word cloud') {
      promises.push(
        updateDoc(doc(firebase.fsdb, 'events', eid), {
          currentlyOpenWordCloudPollId: null
        })
      );
    }

    promises.push(
      update(ref(firebase.rtdb, `polls/${eid}/${poll.pid}`), { isOpen: false, isQueued: false })
    );

    return Promise.all(promises);
  };

  const sharePollAnalytics = async ({ eid, poll }) =>
    update(ref(firebase.rtdb, `polls/${eid}/${poll.pid}`), {
      shareResults: true
    });

  const stopSharingPollAnalytics = async ({ eid, poll }) =>
    update(ref(firebase.rtdb, `polls/${eid}/${poll.pid}`), {
      shareResults: false
    });

  const saveNewPoll = async ({ eid, poll }) => {
    const pid = push(ref(firebase.rtdb, `polls/${eid}`)).key;

    if (poll.type === 'general poll') {
      const answers = {};
      poll.answers.forEach((answer) => {
        answers[`${answer.id}`] = 0;
      });

      return update(ref(firebase.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          correctAnswers: poll.answers.some((answer) => answer.isCorrect)
            ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
            : [],
          answers,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'word cloud') {
      return update(ref(firebase.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'feedback poll') {
      const answers = {};
      poll.ratingOption.ratingOptions.forEach((answer) => {
        answers[`${answer.rid}`] = 0;
      });
      return update(ref(firebase.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          answers,
          totalParticipants: 0
        }
      });
    }
  };

  const editPoll = async ({ eid, poll, pid }) => {
    if (poll.type === 'general poll') {
      const answers = {};

      poll.answers.forEach((answer) => {
        answers[`${answer.id}`] = 0;
      });

      return update(ref(firebase.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          correctAnswers: poll.answers.some((answer) => answer.isCorrect)
            ? poll.answers.filter((answer) => answer.isCorrect).map((answer) => answer.id)
            : [],
          answers,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'word cloud') {
      return update(ref(firebase.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0
        }
      });
    }

    if (poll.type === 'feedback poll') {
      const answers = {};
      poll.ratingOption.ratingOptions.forEach((answer) => {
        answers[`${answer.rid}`] = 0;
      });
      return update(ref(firebase.rtdb), {
        [`/polls/${eid}/${pid}`]: poll,
        [`/pollAnalytics/${eid}/${pid}`]: {
          newPollType: poll.type,
          question: poll.question.text,
          totalParticipants: 0,
          answers
        }
      });
    }
  };

  const deleteWordCloudEntry = async ({ eid, pid, entries }) =>
    set(ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}/entries`), entries);

  const deletePoll = async ({ eid, poll }) => {
    const { pid, type } = poll;

    const promises = [];

    if (type === 'word cloud') {
      promises.push(
        updateDoc(doc(firebase.fsdb, 'events', eid), {
          currentlyOpenWordCloudPollId: null
        })
      );
    }

    promises.push(
      update(ref(firebase.rtdb), {
        [`/polls/${eid}/${pid}`]: null,
        [`/pollAnalytics/${eid}/${pid}`]: null
      })
    );

    return Promise.all(promises);
  };

  const submitPollAnswer = async ({ eid, poll, uid, selectedAnswerIds }) => {
    const { pid } = poll;

    const answers = {};

    selectedAnswerIds.forEach((id) => {
      answers[`${id}`] = increment(1);
    });

    const totalParticipants = increment(1);

    /* TODO: Finish the fsdb query */
    /* Make each answer an object, with answer.id and answer.correct on it */
    return Promise.all([
      update(ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}/answers`), answers),
      update(ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}`), {
        totalParticipants
      }),
      setDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`), {
        uid,
        answers: selectedAnswerIds
      })
    ]);
  };

  const submitFeedbackAnswer = async ({
    eid,
    poll,
    uid,
    rating,
    personalMessage,
    contactAgreement
  }) => {
    const { pid } = poll;

    const pollRef = ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}`);
    const answersRef = ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}/answers`);
    return Promise.all([
      update(pollRef, {
        totalParticipants: increment(1)
      }),
      update(answersRef, {
        [rating.id]: increment(1)
      }),
      setDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`), {
        uid,
        rating: {
          position: rating.position + 1,
          id: rating.id
        },
        message: personalMessage,
        contactAgreement
      })
    ]);
  };

  const answerWordCloudPoll = async ({ eid, poll, uid, entries }) => {
    const { pid } = poll;
    const totalParticipants = increment(1);

    const _entries = {};

    entries.forEach((entry) => {
      _entries[entry] = increment(1);
    });

    return Promise.all([
      update(ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}`), {
        totalParticipants
      }),
      update(ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}/entries`), _entries),
      setDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`), {
        uid,
        entries
      })
    ]);
  };

  const checkIfUserHasAlreadyAnsweredThisPoll = async ({ uid, eid, pid }) =>
    getDoc(doc(firebase.fsdb, 'users', uid, 'pollAnalytics', `${eid}_${pid}`));

  const subscribeToServerTimeOffset = ({ snapshot }) => {
    const serverTimeOffsetRef = ref(firebase.rtdb, '.info/serverTimeOffset');
    return onValue(serverTimeOffsetRef, snapshot);
  };

  const subscribeToPolls = ({ eid, snapshot }) => {
    const pollsRef = ref(firebase.rtdb, `polls/${eid}`);
    return onValue(pollsRef, snapshot);
  };

  const subscribeToPollAnalytics = ({ eid, pid, snapshot }) => {
    const pollAnalyticsRef = ref(firebase.rtdb, `/pollAnalytics/${eid}/${pid}`);
    return onValue(pollAnalyticsRef, snapshot);
  };
  return {
    openPoll,
    closePoll,
    sharePollAnalytics,
    stopSharingPollAnalytics,
    saveNewPoll,
    editPoll,
    deleteWordCloudEntry,
    deletePoll,
    submitPollAnswer,
    submitFeedbackAnswer,
    answerWordCloudPoll,
    checkIfUserHasAlreadyAnsweredThisPoll,
    subscribeToServerTimeOffset,
    subscribeToPolls,
    subscribeToPollAnalytics
  };
}

export default PollFactory;
