import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { LocalContext } from 'context';
import { Markup } from 'interweave';
import { useEventData } from 'hooks';
import { breakpoints } from 'styles';

const PreviousEvents = () => {
  const { theme } = useContext(LocalContext);
  const { edges: events } = useEventData();
  const previousEvents = events
    .filter(({ node }) => node.frontmatter.status === 'previous')
    .sort((a, b) => a.node.frontmatter.eid - b.node.frontmatter.eid);

  return (
    <Events>
      {previousEvents
        .map((event, i) => (
          <Event
            key={i}
            to={`events/${event.node.frontmatter.slug}`}
            $theme={theme}
            $colors={event.node.frontmatter.colors}>
            <GatsbyImage
              image={getImage(event.node.frontmatter.thumb)}
              alt={event.node.frontmatter.name}
            />
            <Details>
              <h3>{event.node.frontmatter.name}</h3>
              <h4>
                <Markup content={event.node.frontmatter.dates} />
              </h4>
            </Details>
          </Event>
        ))
        .slice(0, 3)}
    </Events>
  );
};

const Details = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5em;
  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
`;

const Event = styled(Link)`
  background: transparent;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
  padding: 1rem;
  transition: 0.375s;
  width: 100%;
  img {
    max-width: 100%;
    object-position: top left !important;
  }
  &:hover {
    background: rgba(200, 200, 200, 0.25);
  }
  h3 {
    color: ${({ $colors }) => $colors.primary};
  }
  h4 {
    color: ${({ $colors }) => $colors.secondary};
  }
`;

const Events = styled.div`
  display: grid;
  grid-column: 1/7;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  height: auto;
  width: 100%;
  ${breakpoints.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${breakpoints.lg} {
    grid-column: 2/12;
  }
`;

export default PreviousEvents;
