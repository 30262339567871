import * as React from 'react';

function SvgAdd(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={12} cy={12} r={12} transform="rotate(90 12 12)" fill="#36A9E1" />
      <path d="M12 18.364V5.636M5.636 12h12.728" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}

export default SvgAdd;
