import React from 'react';
import styled, { css } from 'styled-components';
import { QueuedOrClosedPoll } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';

const QueuedAndClosedPolls = ({
  eid,
  colors,
  queuedPolls,
  closedPolls,
  selectedPoll,
  setSelectedPoll,
  currentlyOpenPoll,
  setCurrentlyOpenPoll,
  setPollBeingCurrentlyEditedByModerator,
  setIsCreateOrEditPollModalOpen,
  setPollAnalyticsBeingCurrentlyViewedByModerator,
  setIsAnalyticsModalOpen,
  setPollToBeDeleted,
  setShowConfirmDeleteDialog
}) => (
  <AnimatePresence>
    {(queuedPolls.length || closedPolls.length) && (
      <Wrapper
        colors={colors}
        tabIndex={0}
        queuedPolls={queuedPolls}
        closedPolls={closedPolls}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={fadeInAndOutAndAnimateHeightVariants()}>
        <AnimatePresence>
          {queuedPolls.length && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeInAndOutAndAnimateHeightVariants()}>
              <h4>Queued Polls</h4>
              <AnimatePresence initial={false}>
                {queuedPolls.map((queuedPoll) => (
                  <QueuedOrClosedPoll
                    key={queuedPoll.pid}
                    eid={eid}
                    poll={queuedPoll}
                    selectedPoll={selectedPoll}
                    setSelectedPoll={setSelectedPoll}
                    currentlyOpenPoll={currentlyOpenPoll}
                    setCurrentlyOpenPoll={setCurrentlyOpenPoll}
                    setPollBeingCurrentlyEditedByModerator={setPollBeingCurrentlyEditedByModerator}
                    setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
                    setPollAnalyticsBeingCurrentlyViewedByModerator={
                      setPollAnalyticsBeingCurrentlyViewedByModerator
                    }
                    setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
                    setPollToBeDeleted={setPollToBeDeleted}
                    setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
                  />
                ))}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {closedPolls.length && (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeInAndOutAndAnimateHeightVariants()}>
              <h4>Closed Polls</h4>
              <AnimatePresence initial={false}>
                {closedPolls.map((closedPoll) => (
                  <QueuedOrClosedPoll
                    key={closedPoll.pid}
                    eid={eid}
                    poll={closedPoll}
                    selectedPoll={selectedPoll}
                    setSelectedPoll={setSelectedPoll}
                    setPollAnalyticsBeingCurrentlyViewedByModerator={
                      setPollAnalyticsBeingCurrentlyViewedByModerator
                    }
                    setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
                    setPollToBeDeleted={setPollToBeDeleted}
                    setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
                  />
                ))}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    )}
  </AnimatePresence>
);

const Wrapper = styled(motion.section).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  border-top: 0.063rem solid #bdbdbd;
  flex-grow: 1;
  margin-top: 1.25rem;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }

  ${({ queuedPolls }) => css`
    > div:nth-child(2) {
      ${queuedPolls.length && 'border-top: 0.063rem solid #bdbdbd'};
    }
  `}

  h4 {
    color: #c4c4d4;
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 0.9em;
    margin-left: 2.2em;
  }
`;

export default QueuedAndClosedPolls;
