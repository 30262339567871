import React from 'react';
import { Button } from 'components';
import styled from 'styled-components';
import { Markup } from 'interweave';
import { FormContainer } from '../FormComponents';

// const handleButtonClick = () => {
//   if (user) {
//     if (event && user?.eventsUserCanAccess?.includes(event.eid)) {
//       navigate(`/events/${event.slug}/livestream`);
//     }
//     clearQueryParams();
//     setShowNewUserEmailVerificationModal(false);
//   }
// };

function NewUserEmailVerified({ user, closeNewUserEmailVerifiedDialog, theme }) {
  return (
    <FormContainer style={{ margin: '0 auto' }} $theme={theme}>
      <H3>
        <Markup
          content={`Thanks, ${
            user?.name?.split(' ')[0]
          }.<br />Your account has been successfully verified.<br /><br />`}
          noWrap
        />
      </H3>
      {user?.name && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={closeNewUserEmailVerifiedDialog}
            whileTap={{ scale: 0.95 }}
            type="button"
            width="15.5rem"
            style={{ alignSelf: 'center', marginTop: '0.5rem' }}>
            Continue To Event
          </Button>
        </div>
      )}
    </FormContainer>
  );
}

const H3 = styled.h3`
  font-size: 1.75rem;
  line-height: 1.275em;
  text-align: center;
`;

export default NewUserEmailVerified;
