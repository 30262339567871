import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import { H2, H3, breakpoints } from 'styles';

export default (props) => {
  const { headerRef, theme, isMobile } = useContext(LocalContext);
  const { colors } = props;
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "assets/images/event-1/banner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  const backgroundImage = getImage(data.background);
  return (
    <Header
      ref={headerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}>
      <Background>
        <BackgroundImage image={backgroundImage} alt="Background" />
      </Background>
      <Container>
        <Content>
          <SlideCaptionSubtitle>Morbi blandit cursus risus at ultrices</SlideCaptionSubtitle>
          <SlideTitle $theme={theme}>
            Laoreet suspendisse interdum consectetur libero id faucibus nisl.
          </SlideTitle>
          <Description
            colors={colors}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}>
            Venenatis a condimentum vitae sapien pellentesque habitant morbi tristique senectus.
            Eget dolor morbi non arcu risus quis varius. Id diam maecenas ultricies mi eget mauris.
            Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat.
          </Description>
        </Content>
      </Container>
    </Header>
  );
};

const Description = styled(motion.div)`
  color: white;
  font-family: 'noto-sans', sans-serif;
  font-size: 1.25rem;
  line-height: 1.3em;
  margin-bottom: 1.5rem;
  @media (min-width: 1150px) {
    margin-bottom: 4rem;
  }
`;

const SlideTitle = styled(H2)`
  color: ${({ $theme }) => $theme.tertiary};
  margin-bottom: 1rem;
  ${breakpoints.lg} {
    font-size: 4rem;
    line-height: 4.25rem;
    margin-bottom: 2rem;
  }
`;

const SlideCaptionSubtitle = styled(H3)`
  color: #ffffff;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;
  position: relative;
  :first-child {
    margin-bottom: 0.75rem;
  }
  ${breakpoints.lg} {
    font-size: 2rem;
    margin: 1rem 0 1.25rem 0;
  }
`;

const AnimatedTitle = styled(motion.div)`
  color: white;
  font-size: 3rem;
  font-weight: bold;
  height: auto;
  transform: rotateX(-90deg);
  transform-style: preserve-3d;
  width: auto;
  @media (min-width: 1150px) {
    font-size: 6rem;
  }
  svg {
    margin-bottom: 2rem;
    width: 15rem;
  }
  @media (min-width: 1150px) {
    svg {
      width: 30rem;
    }
  }
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  justify-content: flex-start;
  padding: 2rem 0.5rem;
  position: relative;
  width: 100%;
  z-index: 2;
  @media (min-width: 768px) {
    padding: 4rem;
  }
  @media (min-width: 1150px) {
    grid-column: 2/8;
    padding: 0;
    padding-right: 5rem;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Container = styled.div`
  color: var(--black);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 0 auto;
  margin-bottom: 1rem;
  max-width: 1440px;
  padding: 0.625rem;
  padding-bottom: 2.5rem;
  padding-top: 5rem;
  position: relative;
  width: 100%;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 4rem 0;
    padding-top: 6rem;
    padding-bottom: 12.5rem;
  }
  @media (min-width: 1150px) {
    padding: 5rem 0;
    padding-top: 9.5rem;
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Header = styled.header`
  background: white;
  height: auto;
  position: relative;
  width: 100%;
  @media (min-width: 1150px) {
    min-height: 100vh;
  }
`;
