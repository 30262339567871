import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Link } from 'gatsby';
import { fullGridContainer, breakpoints, H6 } from 'styles';
import {
  MentorsWorkLiveLogo,
  SkillnetTransparentLogo,
  EuropeanFlag,
  SfaLogoWhite
} from 'assets/svgs';
import Harp from '../../../assets/images/department-harp.png';

const EventFooter = () => {
  const { theme, contactRef } = useContext(LocalContext);
  return (
    <>
      <GridContainer $theme={theme}>
        <Subscribe $theme={theme}>
          <MentorsWorkLiveLogo width={280} />
          <P>
            MentorsWork Live broadcast
            <br /> is delivered by Get Broadcasting
          </P>
        </Subscribe>
        <Contact id="contact" ref={contactRef} $theme={theme}>
          <H6 style={{ marginBottom: '0.625rem' }}>Support</H6>
          <ContactDetail $theme={theme} href="mailto:support@mentorsworklive.ie">
            support@mentorsworklive.ie
          </ContactDetail>
          <H6 style={{ marginBottom: '0.625rem' }}>Contact</H6>
          <ContactDetail $theme={theme} href="mailto:info@mentorswork.ie">
            info@mentorswork.ie
          </ContactDetail>
        </Contact>
      </GridContainer>
      <FooterSponsorInfo $theme={theme}>
        <div>
          <SfaLogoWhite />
          <span>
            MentorsWork is an enterprise initiative of SFA., funded by Skillnet Ireland. Skillnet
            Ireland is funded from the National Training Fund through the Department of Further and
            Higher Education, Research, Innovation and Science.
          </span>
        </div>
        <div>
          <SkillnetTransparentLogo />
        </div>
        <div>
          <img src={Harp} alt="harp" />
          <Department>
            <strong>
              An Roinn Breisoideachais agus Ardoideachais, Taighde, Nuálaíochta agus Eolaíochta
            </strong>
            <br />
            Department of Further and Higher Education, Research, Innovation and Science.
          </Department>
        </div>
        <div>
          <EuropeanFlag />
          <p>
            Co-funded by
            <br /> the European Union
          </p>
        </div>
      </FooterSponsorInfo>
      <FooterInfo $theme={theme}>
        <FooterCopyright $theme={theme}>
          Copyright © 2023 - Designed and Developed by Agency X
        </FooterCopyright>
        <FooterTerms $theme={theme}>
          <a href="https://mentorswork.ie/privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </FooterTerms>
      </FooterInfo>
    </>
  );
};

const GridContainer = styled.footer`
  align-items: center;
  ${fullGridContainer}
  grid-row-gap: 0.75rem;
  margin-bottom: 0.25rem;
  margin-top: -1px;
  padding: 2.25rem 1.25rem;
  position: relative;
  ${breakpoints.sm} {
    padding: 40px;
  }
  ${breakpoints.lg} {
    grid-gap: auto;
    padding: 2.25rem 0;
  }
`;

const P = styled.p`
  font-family: 'museo-slab', serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 0px;
  ${breakpoints.lg} {
    margin-bottom: 0;
  }
`;

const Subscribe = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  svg {
    margin-bottom: 1.875rem;
    max-width: 226px;
  }
  ${P} {
    font-weight: 700;
  }
  ${breakpoints.sm} {
    grid-column: 1/5;
  }
  ${breakpoints.lg} {
    grid-column: 2/6;
  }
`;

const Contact = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  ${H6} {
    color: ${({ $theme }) => $theme.tertiary};
  }
  ${breakpoints.sm} {
    grid-column: 5/7;
    justify-self: flex-end;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

const ContactDetail = styled.a`
  align-items: center;
  color: ${({ $theme }) => $theme.primary};
  cursor: pointer;
  display: flex;
  font-family: 'museo-slab', serif;
  font-weight: 500;
  justify-content: flex-start;
  text-decoration: none;
  transition: 0.5s;
  :visited {
    color: ${({ $theme }) => $theme.primary};
  }
  &:first-of-type {
    margin-bottom: 2.25rem;
  }
  svg {
    height: 1.75rem;
    margin-right: 0.75rem;
    min-width: 1.75rem;
    width: 1.75rem;
    circle {
      fill: ${({ $theme }) => $theme.tertiary};
      transition: 0.5s;
    }
    path {
      stroke: 'white';
      transition: 0.5s;
    }
  }
  &:hover {
    color: ${({ $theme }) => $theme.tertiary};
    svg {
      circle {
        fill: 'white';
      }
      path {
        stroke: ${({ $theme }) => $theme.tertiary};
      }
    }
  }
`;

const FooterSponsorInfo = styled.div`
  ${fullGridContainer}
  align-items: center;
  background: ${({ $theme }) => $theme.primary};
  color: #ffffff;
  font-family: 'Uni Neue', sans-serif;
  font-weight: 700;
  gap: 3rem;
  padding: 6rem 1.25rem;
  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    svg {
      height: auto;
      max-width: 150px;
    }
    &:nth-of-type(1) {
      gap: 3rem;
      grid-column: 1 / 7;
      span {
        font-size: 0.875rem;
        line-height: 1.4rem;
        position: relative;
        text-align: center;
      }
    }
    :nth-of-type(2) {
      grid-column: 1 / 7;
    }
    :nth-of-type(3) {
      display: grid;
      grid-column: 1 / 7;
      grid-template-columns: 60px 1fr;
      width: 100%;
      img {
        height: auto;
        padding-left: 1rem;
        width: 100%;
      }
    }
    :nth-of-type(4) {
      flex-direction: row;
      grid-column: 1 / 7;
      svg {
        height: auto;
        width: 25%;
      }
      p {
        font-family: 'Arial', sans-serif;
        font-size: 1.575rem;
      }
    }
  }
  ${breakpoints.sm} {
    padding: 3rem 2rem;
    div {
      :nth-of-type(1) {
        flex-direction: row;
        span {
          text-align: left;
          /* &:after {
            background-color: white;
            content: '';
            height: 125%;
            left: -1.5rem;
            position: absolute;
            top: -10%;
            width: 1px;
          } */
        }
      }
      :nth-of-type(2) {
        grid-column: 1 / 3;
        svg {
          width: 100%;
        }
      }
      :nth-of-type(3) {
        grid-column: 3 / 7;
        grid-template-columns: 80px 1fr;
      }
      :nth-of-type(4) {
        svg {
          height: auto;
          width: 15%;
        }
        p {
          font-size: 2rem;
        }
      }
    }
  }
  ${breakpoints.lg} {
    grid-column: 2 / 13;
    padding: 0.875rem 0;
    div {
      svg {
        height: auto;
        max-width: 100px;
      }
      &:nth-of-type(1) {
        grid-column: 2 / 5;
        span {
          font-size: 0.5rem;
          line-height: 0.85rem;
        }
      }
      &:nth-of-type(2) {
        align-self: center;
        grid-column: 5 / 6;
        svg {
          width: 100%;
        }
      }
      :nth-of-type(3) {
        display: grid;
        grid-column: 6 / 9;
        grid-template-columns: 60px 1fr;
        width: 100%;
      }
      :nth-of-type(4) {
        grid-column: 9 / 12;
        svg {
          width: 50%;
          max-width: 78px;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
`;

const Department = styled.span`
  font-family: 'Times New Roman', serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  ${breakpoints.lg} {
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
`;

const FooterInfo = styled.footer`
  ${fullGridContainer}
  align-items: start;
  background: rgba(189, 189, 189, 0.3);
  font-family: 'Uni Neue', sans-serif;
  font-weight: 300;
  padding: 1rem 1.25rem;
  ${breakpoints.sm} {
    grid-column: 1/7;
  }
  ${breakpoints.lg} {
    grid-column: 2/13;
    padding: 12px 0px;
  }
`;

const FooterCopyright = styled.p`
  color: ${({ $theme }) => $theme.primary};
  font-size: 0.75rem;
  font-weight: 300;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
  ${breakpoints.sm} {
    grid-column: 1/4;
  }
  ${breakpoints.lg} {
    grid-column: 2/7;
    left: 1rem;
  }
`;

const FooterTerms = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: flex-start;
  a {
    color: ${({ $theme }) => $theme.primary};
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-right: 1.25rem;
  }
  ${breakpoints.sm} {
    grid-column: 4/7;
    justify-content: flex-end;
  }
  ${breakpoints.lg} {
    grid-column: 9/12;
  }
`;

export default EventFooter;
