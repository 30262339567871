import { Matcher } from 'interweave';
import React from 'react';

export const Mentions_Pattern = /\B@(\w+)/;

export function Mention({colors, ...props }) {
  return <q style={{color: colors.tertiary}}>{props.children}</q>;
}

export class MentionsMatcher extends Matcher {
  match(string) {
    return this.doMatch(string, Mentions_Pattern, (matches) => {
      return {
        string: matches[0]
      };
    });
  }

  replaceWith(match, props) {
    return React.createElement(Mention, props, match);
  }

  asTag() {
    return 'q';
  }
}
