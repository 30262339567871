import * as React from 'react';

function SvgPin(props) {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.255 8.328l1.518 1.518a3.262 3.262 0 00.707-3.572l1.557-1.558c.733.288 1.492.21 1.963-.261L9.545 1c-.47.471-.55 1.23-.261 1.963L7.726 4.52a3.262 3.262 0 00-3.572.707l1.518 1.518L1 13l6.255-4.672z"
        stroke={props.stroke}
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgPin;
