import * as React from 'react';
import { useState, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { DownArrow } from 'assets/svgs';
import { hexToRGB } from 'utils';
import Answer from './Answer';

const faqs = [
  {
    question: 'How do I register for MentorsWork Live event?',
    answer:
      'Simply fill out the registration form and you will be sent a verification email to complete the registration.'
  },
  {
    question: 'How do I view the live broadcast on the day of the event?',
    answer:
      'You will be sent a reminder email on how to access the event. Alternatively you can simple visit <a href="https://mentorsworklive.ie/" target="_blank" rel="noreferrer">www.mentorsworklive.ie</a> and you will be directed to the event page.'
  },
  {
    question: 'Help! I can’t find my verification email.',
    answer:
      'Please check your junk mail or contact your IT department if the email is not in your inbox or junk. Alternatively, you can drop us an email at <a href="mailto:support@mentorsworklive.ie" target="_blank" rel="noreferrer">support@mentorsworklive.ie</a> and we’ll be able to assist you.'
  },
  {
    question: 'Am I able to interact with registered guests and panellists?',
    answer:
      'Yes, you can participate in the public chat or send in your questions to our presenters via our Q&A tab on the platform. You can also react via emojis during the live event.'
  }
];

function Accordion({ i, expanded, setExpanded, question, answer }) {
  const isOpen = i === expanded;
  const { theme } = useContext(LocalContext);

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>
      <Question theme={theme} onClick={() => setExpanded(isOpen ? false : i)} isOpen={isOpen}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {question}
          <DownArrow />
        </div>
      </Question>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <AnswerText
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            theme={theme}
            isOpen={isOpen}
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                marginBottom: '2rem',
                transition: {
                  when: 'beforeChildren',
                  staggerChildren: 0.3
                }
              },
              collapsed: {
                opacity: 0,
                height: 0,
                marginBottom: '0',
                transition: {
                  delay: 0.5,
                  when: 'afterChildren'
                }
              }
            }}
            transition={{ duration: 0.375, ease: [0.04, 0.62, 0.23, 0.98] }}>
            <Answer answer={answer} />
          </AnswerText>
        )}
      </AnimatePresence>
    </>
  );
}

const Faq = () => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(false);

  return faqs.map((faq, i) => (
    <Accordion
      i={i}
      key={i}
      expanded={expanded}
      setExpanded={setExpanded}
      question={faq.question}
      answer={faq.answer}
    />
  ));
};

const Question = styled(motion.div)`
  background: rgba(234, 233, 232, 0.5);
  cursor: pointer;
  font-family: 'museo-slab', serif;
  font-size: 1.25rem;
  font-weight: 700;
  grid-column: 1/7;
  line-height: 1.75rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  div {
    svg {
      height: 24px;
      min-width: 24px;
      transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
      transition: transform 0.375s ease-in-out;
      path {
        stroke: ${({ theme }) => theme.primary};
      }
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/10;
  }
`;

const AnswerText = styled(motion.section)`
  background: ${({ theme }) => hexToRGB({ color: theme.tertiary, alpha: 0.15 })};
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  grid-column: 1/7;
  line-height: 1.5rem;
  margin-top: -1rem;
  overflow: hidden;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/10;
  }
`;

export default Faq;
