import React, { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from 'components';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import { hexToRGB, capitaliseFirstLetterOfText } from 'utils';
import { fadeInAndOutVariants } from 'styles';

function WordCloudModal({ poll, setIsAnalyticsModalOpen }) {
  const { selectedEvent } = useContext(LocalContext);
  const { eid } = selectedEvent;
  const { pid } = poll;
  const { firebase, user } = useContext(FirebaseContext);
  const [pollAnalytics, setPollAnalytics] = useState(null);
  const [arePollAnalyticsLoading, setArePollAnalyticsLoading] = useState(true);

  useEffect(() => {
    let unsubscribeFromPollAnalytics;

    if (firebase) {
      unsubscribeFromPollAnalytics = firebase.interaction.polls.subscribeToPollAnalytics({
        eid,
        pid,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const { entries, totalParticipants } = snapshot.val();

            setPollAnalytics({
              entries,
              uniqueAnswers: entries ? Object.keys(entries).length : 0,
              numberOfVotes: entries ? Object.values(entries).reduce((a, b) => a + b) : 0,
              totalParticipants
            });

            setArePollAnalyticsLoading(false);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPollAnalytics) {
        unsubscribeFromPollAnalytics();
      }
    };
  }, [firebase, eid, pid]);

  const handleDeleteWordCloudEntry = (entry) => {
    const entries = {
      ...pollAnalytics.entries
    };
    delete entries[entry];
    firebase.interaction.polls.deleteWordCloudEntry({ eid, pid, entries });
  };

  return (
    <Underlay
      data-underlay
      colors={selectedEvent.colors}
      onClick={(e) => {
        if (e.target.dataset.underlay === 'true' && user?.isModerator?.includes(eid)) {
          setIsAnalyticsModalOpen(false);
        }
      }}>
      <Modal colors={selectedEvent.colors}>
        {user && !user?.isModerator?.includes(eid) && (
          <Title colors={selectedEvent.colors}>Poll Results</Title>
        )}
        <Question colors={selectedEvent.colors}>
          <p>{capitaliseFirstLetterOfText(poll.question.text)}</p>
        </Question>
        <StatsAndAnswers>
          <Stat>
            Number of votes: <span>{pollAnalytics?.numberOfVotes}</span>
          </Stat>
          <Stat>
            Number of unique answers: <span>{pollAnalytics?.uniqueAnswers}</span>
          </Stat>
          <AnimatePresence>
            {pollAnalytics?.entries && (
              <Answers colors={selectedEvent.colors}>
                {Object.entries(pollAnalytics.entries).map(([entry, count]) => (
                  <div key={entry}>
                    <Word style={{ padding: '0' }}>
                      <p>{entry}</p>
                      {user?.isModerator?.includes(eid) && (
                        <button type="button" onClick={() => handleDeleteWordCloudEntry(entry)}>
                          Delete
                        </button>
                      )}
                    </Word>
                    <span>{`${count} vote${count > 1 ? 's' : ''}`}</span>
                  </div>
                ))}
              </Answers>
            )}
          </AnimatePresence>
        </StatsAndAnswers>
        {arePollAnalyticsLoading && (
          <LoadingSpinnerWrapper
            variants={fadeInAndOutVariants()}
            initial="initial"
            animate="animate"
            exit="exit">
            <LoadingSpinner style={{ width: '6rem', color: selectedEvent.colors.secondary }} />
          </LoadingSpinnerWrapper>
        )}
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 1.125rem auto 0;
  max-width: 23.75rem;
  position: relative;

  @media screen and (min-width: 1600px) {
    margin: 3rem auto 0;
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  width: 100%;

  p:nth-of-type(1) {
    font-weight: 700;
  }

  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

const Title = styled.div`
  background-color: ${({ colors }) => colors.secondary};
  color: #fff;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;
`;

const StatsAndAnswers = styled(motion.div).attrs(fadeInAndOutVariants())`
  display: flex;
  flex-direction: column;
  padding: 1.1rem;
`;

const Stat = styled.p`
  color: #c4c4c4;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0.425rem;

  span {
    color: #000;
  }
`;

const Answers = styled(motion.div).attrs(fadeInAndOutVariants())`
  display: flex;
  flex-direction: column;
  div {
    padding: 0.5rem;
  }
  span {
    color: #c4c4c4;
    font-size: 0.75rem;
  }
`;

const LoadingSpinnerWrapper = styled(motion.div)`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 1.15rem;
  width: 100%;

  > svg {
    margin-bottom: 0.9rem;

    @media screen and (min-width: 410px) {
      margin-bottom: 1.125rem;
    }

    @media screen and (min-width: 1150px) and (max-width: 1365px) {
      margin-bottom: 0.9rem;
    }

    @media screen and (min-width: 1366px) {
      margin-bottom: 1.125rem;
    }
  }
`;

const Word = styled.div`
  color: #000;
  display: flex;
  justify-content: space-between;
  button {
    background: transparent;
    font-size: 12px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export default WordCloudModal;
