import { httpsCallable } from 'firebase/functions';

function RegistrationFactory(firebase) {
  const checkIfAccountAlreadyExists = async ({ email }) => {
    const checkIfAccountAlreadyExistsCallable = httpsCallable(
      firebase.functions,
      'user-account-checkIfAccountAlreadyExists'
    );
    return checkIfAccountAlreadyExistsCallable({ email: email.toLowerCase().trim() });
  };

  const createAccount = async ({
    email,
    name,
    uid,
    referringUrl,
    company,
    companyAddress,
    phoneNumber,
    numberOfEmployees,
    businessType,
    eid
  }) => {
    const createAccountCallable = httpsCallable(firebase.functions, 'user-account-create');
    return createAccountCallable({
      email: email.toLowerCase().trim(),
      name,
      uid,
      referringUrl,
      company,
      companyAddress,
      numberOfEmployees,
      businessType,
      phoneNumber,
      eid
    });
  };

  const registerForEventIfAccountAlreadyExists = async ({ eid, uid }) => {
    const registerForEventIfAccountAlreadyExistsCallable = httpsCallable(
      firebase.functions,
      'user-account-registerForEventIfAccountAlreadyExists'
    );
    return registerForEventIfAccountAlreadyExistsCallable({
      eid,
      uid
    });
  };

  return {
    checkIfAccountAlreadyExists,
    createAccount,
    registerForEventIfAccountAlreadyExists
  };
}

export default RegistrationFactory;
