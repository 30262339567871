import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { breakpoints, P } from 'styles';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

const CookieBanner = ({ setShowCookieBarModal, setShowChangeSettingsModal }) => {
  const location = useLocation();
  initializeAndTrack(location);
  const { theme } = useContext(LocalContext);

  const handleSaveSettings = (event) => {
    event.preventDefault();
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    window.localStorage.setItem('googleAnalyticsAccepted', true);
    setShowCookieBarModal(false);
  };

  return (
    <Container $theme={theme}>
      <P>
        We use cookies to collect information about how you use mentorsworklive.ie. This helps us to
        improve your experience. You can find out more about the cookies we use in our Cookie page.
        You can also read our Privacy policy. You can accept all cookies or you can choose which
        cookies to accept or reject. You can change your cookie preferences at any time by using the
        cookie preferences link at the bottom of each page.
      </P>
      <ActionContainer>
        <Button type="button" onClick={handleSaveSettings}>
          <P>Accept all</P>
        </Button>
        <a href="https://mentorswork.ie/cookies-policy/">
          <P>Read more</P>
        </a>
        <Button type="button" onClick={() => setShowChangeSettingsModal(true)}>
          <P>Change settings</P>
        </Button>
      </ActionContainer>
    </Container>
  );
};

const Container = styled.div`
  background: ${({ $theme }) => $theme.primary};
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  height: 100%;
  max-width: 1000px;
  padding: 3rem 1.5rem;
  ${P} {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    margin-bottom: 3rem;
    text-align: justify;
  }
  ${breakpoints.md} {
    ${P} {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    padding: 6rem;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  a {
    background: transparent;
    border: 1px solid #64a70b;
    border-radius: 30px;
    color: white;
    flex-grow: 1;
    padding: 0.75rem 0;
    ${P} {
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.25rem;
      margin-bottom: 0;
      padding: 0;
      text-align: center;
    }
  }
  a:hover {
    opacity: 75%;
  }
  ${breakpoints.md} {
    flex-direction: row;
    gap: 30px;
  }
`;

const Button = styled.button`
  background: transparent;
  border: 1px solid #64a70b;
  border-radius: 30px;
  color: white;
  flex-grow: 1;
  padding: 0.75rem 0;
  &:hover {
    opacity: 75%;
    transition: opacity 200ms ease-in-out;
  }
  &:active {
    opacity: 100%;
    border: 1px solid #64a70b;
    transition: opacity 200ms ease-in-out;
  }
  ${P} {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.25rem;
    margin-bottom: 0;
    text-align: center;
  }
`;

export default CookieBanner;
