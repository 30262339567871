import {
  onChildAdded,
  onValue,
  orderByChild,
  push,
  query as rtQuery,
  ref,
  set,
  startAfter as rtStartAfter,
  serverTimestamp as rtdbServerTimestamp
} from 'firebase/database';

function EmojiFactory(firebase) {
  const sendEmoji = async ({ eid, emojiType }) =>
    set(push(ref(firebase.rtdb, `/emojis/${eid}`)), {
      emojiType,
      timestamp: rtdbServerTimestamp()
    });

  const subscribeToEmojis = ({ eid, snapshot }) => {
    const emojisRef = ref(firebase.rtdb, `/emojis/${eid}`);
    return onChildAdded(
      rtQuery(emojisRef, orderByChild('timestamp'), rtStartAfter(Date.now())),
      snapshot
    );
  };

  const subscribeToEventEmojis = ({ eid, snapshot }) =>
    onValue(ref(firebase.rtdb, `/emojis/${eid}`), snapshot);

  return {
    sendEmoji,
    subscribeToEmojis,
    subscribeToEventEmojis
  };
}

export default EmojiFactory;
