import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { Add } from 'assets/svgs';
import ICalendarLink from 'react-icalendar-link';

const DateButton = ({
  eventName,
  eventSlug,
  eventStartTime,
  eventEndTime,
  eventLocation,
  colors,
  transparent
}) => {
  const { theme } = useContext(LocalContext);
  const eventDetails = {
    title: `${eventName}`,
    description: `Visit https://www.mentorsworklive.ie/event/${eventSlug} to watch the event.`,
    location: `${eventLocation}`,
    startTime: `${eventStartTime[0]}`,
    endTime: `${eventEndTime[0]}`
  };
  return (
    <StyledDateButton $theme={theme} colors={colors} width="250px" transparent={transparent}>
      <Add />
      <ICalendarLink
        event={eventDetails}
        style={{
          height: '3rem',
          background: 'transparent',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '-30px',
          position: 'relative',
          color: '#fff'
        }}>
        Add To Calendar
      </ICalendarLink>
    </StyledDateButton>
  );
};

const StyledDateButton = styled.div`
  align-items: center;
  background: transparent;
  border-radius: 30px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  position: relative;
  width: 280px;
  z-index: 2;
  a {
    align-items: center;
    background-color: transparent;
    color: ${({ colors }) => colors.tertiary} !important;
    cursor: pointer;
    display: flex;
    font-family: 'museo-slab', serif;
    font-size: 1rem;
    font-weight: 500;
    justify-content: flex-start;
    list-style: none;
    padding-left: 1rem;
    text-transform: uppercase;
    transition: 0.5s;
    width: ${(props) => (props.width ? props.width : '100%')};
  }
  svg {
    max-width: 1.5rem !important;
    circle {
      fill: ${({ colors }) => colors.tertiary};
    }
    path {
      stroke: #f3f3f3;
    }
  }
`;

export default DateButton;
