import { checkActionCode, applyActionCode } from 'firebase/auth';

function EmailHandlerFactory(firebase) {
  return {
    applyActionCode: async (actionCode) => applyActionCode(firebase.auth, actionCode),
    checkActionCode: async (actionCode) => checkActionCode(firebase.auth, actionCode)
  };
}

export default EmailHandlerFactory;
