import React from 'react';
import { Speaker } from 'components';
import styled from 'styled-components';
import { CardDeck } from 'styles';

const Speakers = ({ speakers, colors }) => (
  <>
    <Container id="speakers">
      {speakers.map((speaker) => (
        <Speaker
          key={speaker.id}
          data={speaker}
          colors={colors}
          buttonText="Read Bio"
          popupType="speaker"
          speakers={speakers}
        />
      ))}
    </Container>
  </>
);

const Container = styled.div`
  align-items: stretch;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  gap: 67px;
  justify-content: center;
  max-width: 1440px;
`;

export default Speakers;
