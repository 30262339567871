import React, { useContext, forwardRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { hexToRGB } from 'utils';

const Button = forwardRef(
  (
    {
      props,
      className,
      children,
      colors,
      disabled,
      onClick,
      style,
      type = 'button',
      whileFocus,
      whileHover,
      whileTap,
      width,
      variants,
      shine,
      onMouseEnter,
      onMouseLeave
    },
    ref
  ) => {
    const { theme } = useContext(LocalContext);
    return (
      <StyledButton
        {...props}
        ref={ref}
        className={className}
        $colors={colors}
        $theme={theme}
        $disable={disabled}
        animate="animate"
        disabled={disabled}
        exit="exit"
        initial="initial"
        onClick={onClick}
        style={style}
        shine={shine}
        type={type}
        variants={variants}
        whileFocus={whileFocus}
        whileHover={whileHover}
        whileTap={whileTap}
        width={width}>
        {children}
        {shine && <Shine />}
      </StyledButton>
    );
  }
);

const StyledButton = styled(motion.button)`
  align-items: center;
  background-color: ${({ $theme, $disable }) =>
    $disable ? hexToRGB({ color: $theme.tertiary, alpha: 0.5 }) : $theme.tertiary};
  border-radius: 3rem;
  color: 'white';
  cursor: pointer;
  display: flex;
  font-family: 'museo-slab', serif;
  font-size: 1rem;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  text-transform: uppercase;
  width: ${(props) => (props.width ? props.width : '100%')};
  ${({ style }) => style}

  :disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const Shine = styled.div`
  animation: slide 1s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  );
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  width: 100%;
  z-index: 1;

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export default Button;
