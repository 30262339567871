import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';

const Notification = ({
  notification,
  callback,
  color = '#fff',
  timeout,
  animationDuration = '0.2',
  style
}) => {
  useEffect(() => {
    if (timeout) {
      let timer;
      if (notification) {
        timer = setTimeout(() => callback(), timeout);
      }
      return () => timer && clearTimeout(timer);
    }
  }, [notification]);
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}>
      <AnimatePresence>
        {notification && (
          <motion.p
            style={{
              color,
              textAlign: 'center',
              textTransform: 'capitalize',
              position: 'absolute',
              top: '-1.1rem',
              ...style
            }}
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto',
              transition: {
                ease: 'easeInOut',
                duration: animationDuration,
                opacity: {
                  delay: animationDuration / 2
                }
              }
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                ease: 'easeInOut',
                animationDuration,
                height: {
                  delay: animationDuration / 2
                }
              }
            }}>
            <Markup content={notification} style={{ color: 'white !important' }} />
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Notification;
