import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';

const AltButton = (props) => {
  const { theme } = useContext(LocalContext);
  return (
    <StyledAltButton
      $theme={theme}
      onClick={props.onClick ? props.onClick : null}
      width={props.width}
      disabled={props.disabled}
      hover={props.hover}>
      {props.children}
    </StyledAltButton>
  );
};

const StyledAltButton = styled.button`
  align-items: center;
  background-color: transparent;
  color: ${({ $theme, hover }) => (hover ? 'rgba(196, 196, 196, 0.2)' : $theme.tertiary)};
  cursor: pointer;
  display: flex;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  height: 3rem;
  justify-content: flex-start;
  text-transform: uppercase;
  transition: 0.5s;
  width: ${(props) => (props.width ? props.width : '100%')};

  :disabled {
    color: #ccc;
  }

  :hover {
    color: ${({ $theme }) => $theme.tertiary};
  }
`;

export default AltButton;
