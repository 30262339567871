import * as React from 'react';

function SvgCross(props) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5261 0.659658L9.5628 5.64007L4.58239 0.676817C3.97125 0.0677835 2.98172 0.069491 2.37268 0.68063L1.26974 1.78739C0.660703 2.39853 0.662411 3.38806 1.27355 3.99709L6.25397 8.96035L1.29071 13.9408C0.681674 14.5519 0.683382 15.5414 1.29452 16.1505L2.40128 17.2534C3.01242 17.8625 4.00195 17.8607 4.61099 17.2496L9.57424 12.2692L14.5547 17.2324C15.1658 17.8415 16.1553 17.8398 16.7644 17.2286L17.8673 16.1219C18.4763 15.5107 18.4746 14.5212 17.8635 13.9122L12.8831 8.94891L17.8463 3.9685C18.4554 3.35736 18.4537 2.36782 17.8425 1.75879L16.7358 0.655846C16.1246 0.0468124 15.1351 0.0485199 14.5261 0.659658Z"
        fill="#ffc18b"
      />
    </svg>
  );
}

export default SvgCross;
