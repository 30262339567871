import React from 'react';
import { default as SadEmoji } from 'assets/svgs/SadEmoji';
import { default as UnhappyEmoji } from 'assets/svgs/UnhappyEmoji';
import { default as NeutralEmoji } from 'assets/svgs/NeutralEmoji';
import { default as HappyEmoji } from 'assets/svgs/HappyEmoji';
import { default as VeryHappyEmoji } from 'assets/svgs/VeryHappyEmoji';

const BarEmojis = ({ poll, pollAnalytics, answer }) => {
  const thereIsAtLeastOneParticipant = pollAnalytics.totalParticipants > 0;
  const isMostPopularRating = pollAnalytics.mostPopularRating === answer.rid;
  const isHighlighted = thereIsAtLeastOneParticipant && isMostPopularRating;
  return (
    <>
      {poll.ratingOption.amount === '5' && answer.option === 4 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <VeryHappyEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Excellent</p>
        </div>
      )}
      {poll.ratingOption.amount === '5' && answer.option === 3 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <HappyEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Good</p>
        </div>
      )}
      {(poll.ratingOption.amount === '5' && answer?.option) === 2 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NeutralEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Neutral</p>
        </div>
      )}
      {poll.ratingOption.amount === '5' && answer.option === 1 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UnhappyEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Fair</p>
        </div>
      )}
      {poll.ratingOption.amount === '5' && answer.option === 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SadEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Bad</p>
        </div>
      )}
      {poll.ratingOption.amount === '3' && poll.answer.option === 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <VeryHappyEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Good</p>
        </div>
      )}
      {poll.ratingOption.amount === '3' && answer.option === 1 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NeutralEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Fair</p>
        </div>
      )}
      {poll.ratingOption.amount === '3' && answer.option === 2 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SadEmoji
            key={answer.rid}
            height="24px"
            width="24px"
            style={{ marginRight: '0.5rem' }}
            stroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            innerStroke={isHighlighted ? '#4F4F4F' : '#C4C4C4'}
            fill={isHighlighted ? '#FED132' : 'none'}
          />
          <p>Bad</p>
        </div>
      )}
    </>
  );
};

export default BarEmojis;
