import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { PinComment } from 'components/Events/Livestream/Interaction/Chat';
import { hexToRGB } from 'utils';
import { useIntersection } from 'hooks';
import { format } from 'date-fns';
import { Star, Bin } from 'assets/animated-svgs';

function Question({
  eid,
  colors,
  submittedQuestion,
  selectedQuestion,
  setSelectedQuestion,
  submittedQuestionsRef,
  questionToBeDeleted,
  setQuestionToBeDeleted,
  index,
  arr
}) {
  const { showConfirmDeleteDialog, setShowConfirmDeleteDialog } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);

  const [ref, observerEntry] = useIntersection({
    root: submittedQuestionsRef.current,
    threshold: 1
  });

  useEffect(() => {
    if (
      user?.isModerator?.includes(eid) &&
      !submittedQuestion.seen.status &&
      observerEntry.isIntersecting
    ) {
      firebase.interaction.qAndA.markQuestionSeen({
        uid: submittedQuestion.uid,
        qid: submittedQuestion.qid
      });
    }
  }, [user?.isModerator, observerEntry.isIntersecting]);

  const handleQuestionSelection = () => {
    if (user?.isModerator?.includes(eid)) {
      if (selectedQuestion?.qid === submittedQuestion.qid) {
        setSelectedQuestion(null);
      } else {
        setSelectedQuestion(submittedQuestion);
      }
    }
  };

  const handleStarQuestion = ({ e, uid, qid, isStarred }) => {
    e.stopPropagation();
    if (isStarred) {
      firebase.interaction.qAndA.unstarQuestion({ uid, qid });
    } else {
      firebase.interaction.qAndA.starQuestion({ uid, qid });
    }
  };
  return (
    <Wrapper
      layout="position"
      layoutDependency={index}
      ref={ref}
      colors={colors}
      isStarred={submittedQuestion?.starred?.status}
      isModerator={user?.isModerator?.includes(eid)}
      isSelected={selectedQuestion?.qid === submittedQuestion.qid}
      onClick={handleQuestionSelection}>
      <SingleQuestion>
        {user?.isModerator?.includes(eid) && (
          <SelectQuestionCheckbox
            checked={selectedQuestion?.qid === submittedQuestion.qid}
            id="selectQuestionCheckbox"
            name="selectQuestionCheckbox"
            colors={colors}
            type="checkbox"
            readOnly
          />
        )}
        <p>
          <span>
            {user?.uid === submittedQuestion.uid
              ? `My Question${arr.length > 1 ? ` (${arr.length - index})` : ''}`
              : submittedQuestion.name
              ? `${submittedQuestion.name}`
              : 'Anonymous User'}
          </span>
          {submittedQuestion.timestamp && (
            <span>{format(submittedQuestion.timestamp.toDate(), 'HH:mmaaa')}</span>
          )}
        </p>
        <p>{submittedQuestion.text}</p>
        {user?.isModerator?.includes(eid) && (
          <StarQuestion
            isStarred={submittedQuestion.starred.status}
            onClick={(e) =>
              handleStarQuestion({
                e,
                uid: submittedQuestion.uid,
                qid: submittedQuestion.qid,
                isStarred: submittedQuestion.starred.status
              })
            }>
            <Star
              stroke={submittedQuestion.starred.status ? colors.tertiary : '#000'}
              fill={submittedQuestion.starred.status ? colors.tertiary : 'transparent'}
            />
            {submittedQuestion.starred.status ? 'Unprioritise' : 'Prioritise'}
          </StarQuestion>
        )}
        {(user?.isModerator?.includes(eid) || user.uid === submittedQuestion.uid) && (
          <DeleteQuestion
            colors={colors}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              setQuestionToBeDeleted({
                uid: submittedQuestion.uid,
                qid: submittedQuestion.qid
              });
              setShowConfirmDeleteDialog(true);
            }}>
            <Bin
              deleteDialogOpen={
                showConfirmDeleteDialog && questionToBeDeleted.qid === submittedQuestion.qid
              }
            />
          </DeleteQuestion>
        )}
      </SingleQuestion>
      <AnimatePresence initial={false}>
        {submittedQuestion.answer && (
          <ModeratorsAnswer
            colors={colors}
            isModerator={user?.isModerator?.includes(eid)}
            isSelected={selectedQuestion?.qid === submittedQuestion.qid}>
            <p>
              <span>Moderator&apos;s Answer:</span>
              {submittedQuestion.answer.timestamp && (
                <span>{format(submittedQuestion.answer.timestamp.toDate(), 'HH:mmaaa')}</span>
              )}
            </p>
            <p>{submittedQuestion.answer.text}</p>
          </ModeratorsAnswer>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.65,
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4,
        delay: 0.55
      },
      opacity: {
        duration: 0.65
      }
    }
  }
})`
  background-color: ${({ isSelected, colors }) =>
    isSelected ? hexToRGB({ color: colors.tertiary, alpha: 0.2 }) : 'transparent'};
  color: ${({ isSelected, colors }) => (isSelected ? colors.tertiary : '#000')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  font-size: 1rem;
  margin-bottom: 0.688em;
  padding: 0.125em 0.25em 0.25em;
  position: relative;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  p {
    padding-left: ${({ isModerator }) => (isModerator ? '1.375rem' : 0)};
  }

  p:first-of-type {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.313em;

    span:first-of-type {
      font-weight: 700;
    }

    span:last-of-type {
      color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
      transition: color 200ms ease-in-out;
      font-size: 0.75em;
    }
  }

  small {
    color: ${({ isStarred, colors }) => (isStarred ? colors.tertiary : '#000')};
    display: inline-block;
    font-size: 0.65rem;
    left: 0.95em;
    position: relative;
  }
`;

const SingleQuestion = styled.div`
  position: relative;
`;

export const SelectQuestionCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  height: 1rem;
  margin-top: 2px;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.tertiary};
  }

  &:checked {
    border: 1px solid ${({ colors }) => colors.tertiary};
  }

  &:checked:before {
    color: ${({ colors }) => colors.tertiary};
    content: '✓';
    font-size: 0.8rem;
    height: 0.8rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37%, -60%);
    width: 0.8rem;
  }
`;

const ModeratorsAnswer = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.65,
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4,
        delay: 0.55
      },
      opacity: {
        duration: 0.65
      }
    }
  }
})`
  color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  margin-top: 0.625rem;
  padding: 0.125rem 0 0.25rem 1rem;
  transition: color 200ms ease-in-out;
`;

const DeleteQuestion = styled(motion.button)`
  > svg {
    height: 12px;
    width: 12px;
    fill: #000000;
  }
  align-self: flex-end;
  background: none;
  bottom: 0.125em;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  right: 0.125em;
  &:hover {
    > svg {
      fill: ${({ colors }) => colors.secondary};
    }
  }
`;

const StarQuestion = styled(PinComment)`
  > svg {
    bottom: unset;
  }
`;

export default Question;
