import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';

function ModerationFactory(firebase) {
  const grantRegistrantAccessToTheseEvents = async ({ uid, eids }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      eventsUserWantsToAccess: arrayRemove(...eids),
      eventsUserCanAccess: arrayUnion(...eids),
      eventsUserHasBeenDeniedAccessTo: arrayRemove(...eids),
      pendingSiteRegistrant: false
    });

  const denyRegistrantAccessToTheseEvents = async ({ uid, eids }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      eventsUserHasBeenDeniedAccessTo: arrayUnion(...eids),
      eventsUserCanAccess: arrayRemove(...eids),
      eventsUserWantsToAccess: arrayRemove(...eids)
    });

  const denyRegistrantAccessToSite = async ({ uid }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid), {
      pendingSiteRegistrant: false
    });

  return {
    grantRegistrantAccessToTheseEvents,
    denyRegistrantAccessToTheseEvents,
    denyRegistrantAccessToSite
  };
}

export default ModerationFactory;
