import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';

const PrivacyButton = ({ setShowChangeSettingsModal }) => (
  <StyledButton type="button" width="150px" onClick={() => setShowChangeSettingsModal(true)}>
    Privacy
  </StyledButton>
);

const StyledButton = styled(Button)`
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10;
`;

export default PrivacyButton;
