import {
  collectionGroup,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';

function QAndAFactory(firebase) {
  const submitNewQuestion = ({ uid, eid, text, name }) =>
    setDoc(
      doc(firebase.fsdb, 'users', uid, 'questions', `${uid}_${Date.now()}`),
      name
        ? {
            eid,
            text,
            timestamp: serverTimestamp(),
            uid,
            name,
            starred: {
              status: false,
              timestamp: 0
            },
            seen: {
              status: false,
              timestamp: 0
            }
          }
        : {
            eid,
            text,
            timestamp: serverTimestamp(),
            uid,
            starred: {
              status: false,
              timestamp: 0
            },
            seen: {
              status: false,
              timestamp: 0
            }
          }
    );

  const answerThisQuestionLive = async ({ eid, text }) =>
    updateDoc(doc(firebase.fsdb, 'events', eid), {
      questionCurrentlyBeingAnsweredLive: text
    });

  const stopShowingAnswerLiveOverlay = async ({ eid }) =>
    updateDoc(doc(firebase.fsdb, 'events', eid), {
      questionCurrentlyBeingAnsweredLive: null
    });

  const submitAnswer = async ({ text, qid, uid }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', qid), {
      answer: {
        text,
        timestamp: serverTimestamp()
      }
    });

  const deleteUserQuestion = async ({ uid, qid }) =>
    deleteDoc(doc(firebase.fsdb, 'users', uid, 'questions', qid));

  const editQuestion = async ({ uid, qid, text }) =>
    Promise.all([
      updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', qid), {
        text,
        timestamp: serverTimestamp()
      })
    ]);

  const markQuestionSeen = async ({ uid, qid }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', qid), {
      seen: {
        status: true,
        timestamp: serverTimestamp()
      }
    });

  const starQuestion = async ({ uid, qid }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', qid), {
      starred: {
        status: true,
        timestamp: serverTimestamp()
      }
    });

  const unstarQuestion = async ({ uid, qid }) =>
    updateDoc(doc(firebase.fsdb, 'users', uid, 'questions', qid), {
      starred: {
        status: false,
        timestamp: 0
      }
    });

  const subscribeToEventQuestions = ({ eid, snapshot }) =>
    onSnapshot(
      query(collectionGroup(firebase.fsdb, 'questions'), where('eid', '==', eid)),
      snapshot
    );

  const subscribeModeratorToSubmittedQuestions = ({ snapshot, eid }) =>
    onSnapshot(
      query(
        collectionGroup(firebase.fsdb, 'questions'),
        where('eid', '==', eid),
        orderBy('starred.status', 'desc'),
        orderBy('starred.timestamp', 'asc'),
        orderBy('timestamp', 'desc')
      ),
      snapshot
    );

  const subscribeUserToTheirSubmittedQuestions = ({ snapshot, eid, uid }) =>
    onSnapshot(
      query(
        collectionGroup(firebase.fsdb, 'questions'),
        where('eid', '==', eid),
        where('uid', '==', uid),
        orderBy('timestamp', 'desc')
      ),
      snapshot
    );

  const subscribeToSubmittedQuestions = ({ snapshot, eid, uid, isModerator }) => {
    let q;
    if (isModerator) {
      q = query(
        collectionGroup(firebase.fsdb, 'questions'),
        where('eid', '==', eid),
        orderBy('starred.status', 'desc'),
        orderBy('starred.timestamp', 'asc'),
        orderBy('timestamp', 'desc')
      );
    } else {
      q = query(
        collectionGroup(firebase.fsdb, 'questions'),
        where('eid', '==', eid),
        where('uid', '==', uid),
        orderBy('timestamp', 'desc')
      );
    }
    return onSnapshot(q, snapshot);
  };

  return {
    submitNewQuestion,
    answerThisQuestionLive,
    stopShowingAnswerLiveOverlay,
    submitAnswer,
    deleteUserQuestion,
    editQuestion,
    markQuestionSeen,
    starQuestion,
    unstarQuestion,
    subscribeToEventQuestions,
    subscribeModeratorToSubmittedQuestions,
    subscribeUserToTheirSubmittedQuestions,
    subscribeToSubmittedQuestions
  };
}

export default QAndAFactory;
