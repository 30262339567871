import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const EventHeaderBackground = ({ colors, eventBanner }) => {
  const { theme } = useContext(LocalContext);

  return (
    <Background $theme={theme} colors={colors}>
      <BackgroundImage image={getImage(eventBanner)} alt="Background" />
    </Background>
  );
};

const Background = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
`;

const BackgroundImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: relative;
  width: 100%;
`;

export default EventHeaderBackground;
